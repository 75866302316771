@import "common_values.scss";

@font-face {
    font-family: 'Teko';
    src: url('../font/Teko-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Archivo';
    src: url('../font/Archivo-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../font/Rajdhani-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'ThirdRailDemo';
    src: url('../font/Third Rail - Demo.ttf') format('truetype');
}

html,
body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

body {
    background-color: $jcorp-main-black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: $jcorp-main-red !important;
    text-decoration: none !important;
}

a:hover {
    color: rgba($jcorp-main-red, 0.6) !important;
    text-decoration: none !important;
}

#accessWalletBtn {
    color: #fff !important;
}

img {
    image-rendering: -webkit-optimize-contrast;
}

#img-mapper {
    width: var(--mapWidth) !important;
    height: var(--mapHeight) !important;

    .img-mapper-img {
        width: var(--mapWidth) !important;
    }

    .img-mapper-canvas {
        width: var(--mapWidth) !important;
        height: var(--mapHeight) !important;
    }
}

.alternativeConnectBtn {
    position: relative;
    display: inline-block;
    margin: auto;
}

.blankBox {
    position: relative;
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: auto;
    width: 100%;

    background: rgba($jcorp-main-white, 0.2);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.labelConnect {
    position: relative;
    display: block;
    text-align: left;
    padding-left: 30px;
    padding-bottom: 20px;
}

.modal-content {
    background: rgba($jcorp-main-black, 0.95);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 2em) 0, 100% 2em, 100% 100%, 0 100%, 2em 100%, 0% calc(100% - 2em), 0 0%);
}

.modalContainer {
    position: relative;
    display: inline-block;
    overflow-x: hidden;

    color: $jcorp-main-white;
}

.boxLoadingContent {
    padding-top: calc(20% + 50px);
    padding-bottom: 70px;
}

#navbarResponsive a {
    color: $jcorp-main-white !important;
    font-family: Teko;
    font-style: normal !important;
    font-weight: 300;
    font-size: 24px;
    text-transform: uppercase;
    transition-duration: 0.4s;
}

#navbarResponsive a svg path {
    fill: $jcorp-main-white !important;
    transition-duration: 0.4s;
}

#navbarResponsive a:hover {
    color: $jcorp-main-black !important;
}

#navbarResponsive a:hover svg path {
    fill: $jcorp-main-black !important;
}

#navbarResponsive .navbarDropdownContainer .show {
    background-color: $jcorp-main-red;
    border: none;
}

#navbarResponsive .navbarDropdown a:hover {
    background-color: $jcorp-main-red;
}

#navbarResponsive .navbarDropdownDivider {
    background-color: $jcorp-main-black;
}

#footerNavBar a {
    color: $jcorp-main-white !important;
    font-family: Teko;
    font-style: normal !important;
    font-weight: 300;
    font-size: 24px;
    text-transform: uppercase;
    transition-duration: 0.4s;
}

#footerNavBar a svg path {
    fill: $jcorp-main-white !important;
    transition-duration: 0.4s;
}

#footerNavBar a:hover {
    color: $jcorp-main-black !important;
}

#footerNavBar a:hover svg path {
    fill: $jcorp-main-black !important;
}

.connectBtnText {
    position: relative;
    display: block;
    text-align: left;
    padding-left: 65px;

    font-family: Rajdhani;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 170%;
    /* or 20px */

    /* Main/Light */
    color: $jcorp-main-white;
}

.iconsLeftBlack {
    position: absolute;
    top: 5px;
    left: 10px;
}

.btnStandard {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;

    margin-top: 10px;
    margin-right: 10px;

    /* Main/Light */
    color: $jcorp-main-white !important;

    width: 150px;
    height: 58px;
    background: $jcorp-main-black !important;
    border: 1px solid $jcorp-main-red !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.btnStandard:active {
    background: rgba($jcorp-main-red, 0.6) !important;
    border: 1px solid $jcorp-main-red !important;
    transition-duration: 0s;
}

.btnStandardReverse {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;

    margin-top: 10px;
    margin-right: 10px;

    /* Main/Light */
    color: $jcorp-main-white !important;

    width: 150px;
    height: 58px;
    background: $jcorp-main-red !important;
    border: 1px solid $jcorp-main-black !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.btnStandardReverse:active {
    background: rgba($jcorp-main-red, 0.6) !important;
    border: 1px solid $jcorp-main-red !important;
    transition-duration: 0s;
}

.btnStandardSmall {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;

    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;

    /* Main/Light */
    color: $jcorp-main-white !important;

    width: 110px;
    height: 48px;
    background: $jcorp-main-black !important;
    border: 1px solid $jcorp-main-red !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.btnStandardSmall:active {
    background: rgba($jcorp-main-red, 0.6) !important;
    border: 1px solid $jcorp-main-red !important;
    transition-duration: 0s;
}

.btnStandardSmallDisable {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;

    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;

    /* Main/Light */
    color: rgba($jcorp-main-white, 0.6) !important;

    width: 110px;
    height: 48px;
    background: rgba($jcorp-main-black, 0.6) !important;
    border: 1px solid rgba($jcorp-main-red, 0.6) !important;
    border-radius: 0;
    box-shadow: none !important;

    cursor: auto !important;

    transition-duration: 0.4s;
}

.btnStandardSmallReverse {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;

    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;

    /* Main/Light */
    color: $jcorp-main-white !important;

    width: 110px;
    height: 48px;
    background: $jcorp-main-red !important;
    border: 1px solid $jcorp-main-black !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.btnStandardSmallReverse:active {
    background: rgba($jcorp-main-red, 0.6) !important;
    border: 1px solid $jcorp-main-red !important;
    transition-duration: 0s;
}

.btnStandardTiny {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 90%;
    text-transform: uppercase;

    padding-left: 0px;
    padding-right: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 3px;
    margin-right: 3px;

    /* Main/Light */
    color: $jcorp-main-white !important;

    width: 48px;
    height: 24px;
    background: $jcorp-main-black !important;
    border: 1px solid $jcorp-main-red !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.btnStandardTiny:active {
    background: rgba($jcorp-main-red, 0.6) !important;
    border: 1px solid $jcorp-main-red !important;
    transition-duration: 0s;
}

.btnStandardTinyReverse {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 90%;
    text-transform: uppercase;

    padding-left: 0px;
    padding-right: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 3px;
    margin-right: 3px;

    /* Main/Light */
    color: $jcorp-main-white !important;

    width: 48px;
    height: 24px;
    background: $jcorp-main-red !important;
    border: 1px solid $jcorp-main-black !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.btnStandardTinyReverse:active {
    background: rgba($jcorp-main-red, 0.6) !important;
    border: 1px solid $jcorp-main-red !important;
    transition-duration: 0s;
}

.navStandardSmall {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 100%;
    text-transform: uppercase;

    margin: 4px;

    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;

    /* Main/Light */
    color: $jcorp-main-white !important;

    height: 40px;
    background: $jcorp-main-black !important;
    border: 1px solid $jcorp-main-red !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.navStandardSmall:active {
    background: rgba($jcorp-main-red, 0.6) !important;
    border: 1px solid $jcorp-main-red !important;
    transition-duration: 0s;
}

.navStandardSmallReverse {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 100%;
    text-transform: uppercase;

    margin: 4px;

    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;

    /* Main/Light */
    color: $jcorp-main-white !important;

    height: 40px;
    background: $jcorp-main-red !important;
    border: 1px solid $jcorp-main-black !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.navStandardSmallReverse:active {
    background: rgba($jcorp-main-red, 0.6) !important;
    border: 1px solid $jcorp-main-red !important;
    transition-duration: 0s;
}

.navStandardTiny {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 100%;
    text-transform: uppercase;

    /* Main/Light */
    color: $jcorp-main-white !important;

    height: 30px;
    background: $jcorp-main-black !important;
    border: 1px solid $jcorp-main-red !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.navStandardTiny:active {
    background: rgba($jcorp-main-red, 0.6) !important;
    border: 1px solid $jcorp-main-red !important;
    transition-duration: 0s;
}

.actionStandardSmall {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 100%;
    text-transform: uppercase;

    margin: 4px;

    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;

    /* Main/Light */
    color: $jcorp-main-white !important;

    height: 40px;
    background: $jcorp-main-black !important;
    border: 1px solid $jcorp-main-red !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.actionStandardSmall:active {
    background: rgba($jcorp-main-red, 0.6) !important;
    border: 1px solid $jcorp-main-red !important;
    transition-duration: 0s;
}

.actionStandardSmallDisable {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 100%;
    text-transform: uppercase;

    margin: 4px;

    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;

    /* Main/Light */
    color: rgba($jcorp-main-white, 0.6) !important;

    height: 40px;
    background: rgba($jcorp-main-black, 0.6) !important;
    border: 1px solid rgba($jcorp-main-red, 0.6) !important;
    border-radius: 0;
    box-shadow: none !important;

    cursor: auto !important;

    transition-duration: 0.4s;
}

.actionStandardSmallReverse {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 100%;
    text-transform: uppercase;

    margin: 4px;

    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;

    /* Main/Light */
    color: $jcorp-main-white !important;

    height: 40px;
    background: $jcorp-main-red !important;
    border: 1px solid $jcorp-main-black !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.actionStandardSmallReverse:active {
    background: rgba($jcorp-main-red, 0.6) !important;
    border: 1px solid $jcorp-main-red !important;
    transition-duration: 0s;
}

.btnRed {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;

    margin-top: 10px;
    margin-right: 10px;

    /* Main/Light */
    color: $jcorp-main-white !important;

    width: 150px;
    height: 58px;
    background: $jcorp-main-black !important;
    border: 1px solid $jcorp-main-red !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.btnRed:active {
    background: rgba($jcorp-main-black, 0.6) !important;
    border: 1px solid $jcorp-main-black !important;
    transition-duration: 0s;
}

.btnRedReverse {
    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;

    margin-top: 10px;
    margin-right: 10px;

    /* Main/Light */
    color: $jcorp-main-white !important;

    width: 150px;
    height: 58px;
    background: $jcorp-main-red !important;
    border: 1px solid $jcorp-main-black !important;
    border-radius: 0;
    box-shadow: none !important;

    transition-duration: 0.4s;
}

.btnRedReverse:active {
    background: rgba($jcorp-main-black, 0.6) !important;
    border: 1px solid $jcorp-main-black !important;
    transition-duration: 0s;
}

.cardFactionBox {
    position: relative;
    display: block;

    width: 275px;
    height: 275px;

    background: $jcorp-main-red;
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
}

.cardFactionBoxIn {
    position: absolute;
    top: 1px;
    left: 1px;

    width: 273px;
    height: 273px;

    background: #18101B;
    border-radius: 3px;

    clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
}

.cardTeamBox {
    position: relative;
    display: block;

    width: 275px;
    height: 335px;

    background: $jcorp-main-red;
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
}

.cardTeamBoxIn {
    position: absolute;
    top: 1px;
    left: 1px;

    width: 273px;
    height: 333px;

    background: #18101B;
    border-radius: 3px;

    clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
}

.cardTeamInfos {
    position: absolute;
    display: block;

    bottom: 35px;
    left: 35px;
}

.cardTeamSocial {
    position: absolute;
    display: block;

    bottom: 50px;
    right: 25px;
}

.cardTeamSocial a svg path {
    fill: $jcorp-main-white !important;
    transition-duration: 0.4s;
}

.cardTeamSocial a:hover svg path {
    fill: $jcorp-main-red !important;
}

.cardTeamName {
    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-red;
}

.cardTeamFunction {
    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 100%;
    /* or 20px */

    margin-top: 8px;
    text-transform: uppercase;

    /* Main/Light */
    color: $jcorp-main-white;
}

.cardFactionText {
    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 100%;
    /* or 20px */

    position: absolute;
    bottom: 16px;
    width: 100%;
    text-transform: uppercase;

    /* Main/Light */
    color: #FAF7E2;
}

.cardPictureBox {
    position: absolute;
    top: -35px;
    left: 15px;

    width: 245px;
    height: 245px;

    clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
}

.cardPicture {
    width: 245px;
    border-radius: 3px;
}

.partnersBox svg path {
    fill: $jcorp-main-white !important;
}

.legendContainer {
    position: relative;
    display: block;
}

.legendBox {
    position: relative;
    display: block;
    margin-top: 10px;
}

.legendBox:first-child {
    margin-top: 0px;
}

.legendBoxIcon {
    position: relative;
    display: inline-block;
}

.legendMapIcon {
    width: 32px;
    padding: 1px;

    color: $jcorp-main-white;
    background: rgba($jcorp-main-red, 0.8);
    border-radius: 4px !important;
    border: none;
}

.legendEmpIcon {
    width: 32px;
    padding: 3px;

    color: $jcorp-main-white;
    background: rgba($jcorp-main-black, 0.8);
    border-radius: 4px !important;
    border: 1px solid $jcorp-main-white;
}

.legendBoxText {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;

    font-family: Teko;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 100%;
    /* or 20px */

    /* Main/Light */
    color: $jcorp-main-white;
}

.container2,
.container2-fluid,
.container2-sm,
.container2-md,
.container2-lg,
.container2-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.boxContainer {
    position: relative;
    display: block;
    color: $jcorp-main-white;
    margin-top: 30px;
}

.boxContainer:first-child {
    margin-top: 50px;
}

.boxContainerShadow {
    position: relative;
    display: block;
    color: $jcorp-main-white;
    margin-top: 30px;
    background-color: rgba($jcorp-main-black, 0.5);
    border-radius: 5px;
}

.boxContainerShadow:first-child {
    margin-top: 50px;
}

.standardBlock {
    position: relative;
    display: block;
    text-align: center;
}

.skillBlock {
    position: relative;
    display: block;
    text-align: center;
    width: 108%;
    left: -4%;
}

.cardSkillBlock {
    position: relative;
    display: inline-block;
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 4px;
}

.cardSkillContainer {
    position: relative;
    display: inline-block;
    width: 270px;
    height: 120px;

    .cardSkillBox {
        position: relative;
        display: block;

        width: 270px;
        height: 120px;

        background: #6c757d;
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .cardSkillBoxIn {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 268px;
        height: 118px;

        background: #18101B;
        border-radius: 3px;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }
}

.cardSkillContainerActive {
    position: relative;
    display: inline-block;
    width: 270px;
    height: 120px;
    filter: drop-shadow(0 0 12px rgba($jcorp-main-red, 0.64));

    .cardSkillBox {
        position: relative;
        display: block;

        width: 270px;
        height: 120px;

        background: $jcorp-main-red;
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .cardSkillBoxIn {
        position: absolute;
        top: 2px;
        left: 2px;

        width: 266px;
        height: 116px;

        background: #18101B;
        border-radius: 3px;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }
}

.cardSkillInfos {
    position: absolute;
    display: block;
    text-align: center;
    width: calc(100% - 120px);
    top: 0;
    right: 0;
}

.cardSkillTitle {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-red;
}

.cardSkillText {
    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    /* or 24px */

    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-white;
}

.cardSkillPictureBox {
    position: absolute;
    display: block;
    top: 9px;
    left: 9px;

    width: 102px;
    height: 102px;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.cardSkillPictureBox .overlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 1em;
    height: 100%;
    border-top-left-radius: 3px;
    background-color: $jcorp-main-red;
}

.cardSkillPictureBox .overlayText {
    margin-top: 70px;
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-white;
    transform: rotate(-90deg);
}

.cardSkillPictureBox .overlayHover {
    display: none;
}

.cardSkillPictureBox:hover .overlayHover {
    position: absolute;
    display: inline-block;
    top: 0;
    left: -2px;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    border-radius: 3px;
    background-color: rgba($jcorp-main-white, 0.7);
    cursor: pointer;
}

.cardSkillPictureBox:hover .overlayHoverText {
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-red;
}

.cardSkillPicture {
    width: 102px;
    border-radius: 3px;
}

.nftBlock {
    position: relative;
    display: block;
    text-align: center;
    width: 108%;
    left: -4%;
}

.cardNFTBlock {
    position: relative;
    display: inline-block;
    margin-top: 25px;
    margin-left: 4px;
    margin-right: 4px;
}

.cardNFTContainer {
    position: relative;
    display: block;
    width: 160px;
    height: 160px;
}

.cardNFTBox {
    position: relative;
    display: block;

    width: 160px;
    height: 160px;

    background: var(--rarityColor);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.cardNFTBoxIn {
    position: absolute;
    top: 1px;
    left: 1px;

    width: 158px;
    height: 158px;

    background: #18101B;
    border-radius: 3px;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.cardNFTInfos {
    position: absolute;
    display: block;
    text-align: left;

    bottom: -5px;
    left: 16px;
}

.cardNFTHierarchy {
    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    /* or 24px */

    text-transform: uppercase;

    /* Main/Red */
    color: var(--rarityColor);
}

.cardNFTPower {
    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    /* or 20px */

    margin-top: -8px;
    text-transform: uppercase;

    /* Main/Light */
    color: $jcorp-main-white;
}

.cardNFTInfosBis {
    position: absolute;
    display: block;
    text-align: center;

    bottom: 8px;
    right: 13px;
}

.cardNFTNumber {
    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-red;
}

.cardNFTName {
    position: relative;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    top: -3px;

    /* Main/Red */
    color: $jcorp-main-red;
}

.cardNFTTitle {
    position: relative;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    top: -13px;

    /* Main/Red */
    color: $jcorp-main-red;
}

.cardNFTPictureBox {
    position: absolute;
    display: block;
    top: -20px;
    left: 9px;

    width: 142px;
    height: 142px;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.cardNFTPictureBox .overlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 1em;
    height: 100%;
    border-top-left-radius: 3px;
    background-color: $jcorp-main-red;
}

.cardNFTPictureBox .overlayText {
    margin-top: 90px;
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-white;
    transform: rotate(-90deg);
}

.cardNFTPictureBox .overlayArenaText {
    margin-top: 100px;
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-white;
    transform: rotate(-90deg);
}

.cardNFTPictureBox .overlayHoverA {
    display: none;
}

.cardNFTPictureBox:hover .overlayHoverA {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 57px;
    border-radius: 3px;
    background-color: rgba($jcorp-main-white, 0.7);
    cursor: pointer;
}

.cardNFTPictureBox:hover .overlayHoverTextA {
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-red;
}

.cardNFTPictureBox .overlayHoverB {
    display: none;
}

.cardNFTPictureBox:hover .overlayHoverB {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 57px;
    border-radius: 3px;
    background-color: rgba($jcorp-main-red, 0.7);
    cursor: pointer;
}

.cardNFTPictureBox:hover .overlayHoverTextB {
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-white;
}

.cardNFTPictureBox .overlayHoverSmallB {
    display: none;
}

.cardNFTPictureBox:hover .overlayHoverSmallB {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 59px;
    border-radius: 3px;
    background-color: rgba($jcorp-main-red, 0.7);
    cursor: pointer;
}

.cardNFTPictureBox:hover .overlayHoverTextSmallB {
    position: relative;
    display: block;
    margin-left: -4px;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-white;
}

.cardNFTPictureBox .overlaySquadIcon {
    position: absolute;
    top: 5px;
    right: 10px;
}

.cardNFTPictureBox .overlayImgSquadIcon {
    width: 24px;
    padding: 2px;
    border-radius: 2px;
    background-color: var(--squadColor);
}

.cardNFTPicture {
    width: 142px;
    border-radius: 3px;
}

.cardDynNFTBlock {
    position: relative;
    display: inline-block;
    margin-top: 25px;
    margin-left: 4px;
    margin-right: 4px;
}

.cardDynNFTContainer {
    position: relative;
    display: block;
}

.cardDynNFTBox {
    position: relative;
    display: block;

    width: 160px;

    background: var(--rarityColor);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.cardDynNFTBoxIn {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    background: #18101B;
    border-radius: 3px;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.cardDynNFTBoxIn2Empty {
    position: relative;
    display: block;
    margin-top: -8px;
}

.cardDynNFTBoxIn2 {
    position: relative;
    display: inline-block;
    margin-top: 5px;

    width: 100%;

    background: linear-gradient(0deg, rgba(#18101B, 0) 0%, rgba(var(--skillColor), 0.5) 100%);

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
}

.cardDynNFTBoxIn2 .textClass {
    position: relative;
    display: block;
    text-align: left;
    padding: 5px;
    padding-left: 35px;

    width: 100%;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 100%;
    /* or 20px */

    letter-spacing: 0.07em;
    text-transform: uppercase;

    /* Main/Red */
    color: rgba(var(--skillColor), 1);
}

.cardDynNFTBoxIn2 .textLvl {
    position: absolute;
    top: 8px;
    right: 15px;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    /* or 20px */

    /* Main/Red */
    color: $jcorp-main-white;
}

.cardDynNFTBoxIn3 {
    position: relative;
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 15px;

    width: 100%;

    background: #18101B;
    border-bottom-right-radius: 3px;

    clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.cardDynNFTInfosLine {
    position: relative;
    display: block;
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;
    border-bottom: 1px solid rgba($jcorp-main-red, 0.3);
}

.cardDynNFTInfosLine .lineTitle {
    display: inline-block;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    /* or 20px */

    text-transform: uppercase;

    /* Main/Light */
    color: $jcorp-main-white;
}

.cardDynNFTInfosLine .lineAttribut {
    position: absolute;
    right: 0;
    bottom: 0;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    /* or 20px */

    /* Main/Light */
    color: $jcorp-main-white;
}

.cardDynNFTInfosLine .lineAttributBonus {
    position: absolute;
    right: 0;
    bottom: 0;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    /* or 20px */

    /* Main/Light */
    color: $jcorp-main-green;
}

.cardDynNFTInfosLine .lineAttributBonus2 {
    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 100%;
    /* or 20px */

    /* Main/Light */
    color: $jcorp-main-green;
}

.cardDynNFTInfos {
    position: relative;
    display: block;
    text-align: left;
    margin-top: 125px;
    margin-left: 16px;
}

.cardDynNFTHierarchy {
    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    /* or 24px */

    text-transform: uppercase;

    /* Main/Red */
    color: var(--rarityColor);
}

.cardDynNFTPower {
    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    /* or 20px */

    margin-top: -8px;
    text-transform: uppercase;

    /* Main/Light */
    color: $jcorp-main-white;
}

.cardDynNFTInfosBis {
    position: absolute;
    display: block;
    text-align: center;

    top: 110px;
    right: 13px;
}

.cardDynNFTNumber {
    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-red;
}

.cardDynNFTName {
    position: relative;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    top: -3px;

    /* Main/Red */
    color: $jcorp-main-red;
}

.cardDynNFTTitle {
    position: relative;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    top: 19px;

    /* Main/Red */
    color: $jcorp-main-red;
}

.cardDynNFTPictureBox {
    position: absolute;
    display: block;
    top: -20px;
    left: 9px;

    width: 142px;
    height: 142px;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.cardDynNFTPictureBox .overlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 1em;
    height: 100%;
    border-top-left-radius: 3px;
    background-color: $jcorp-main-red;
}

.cardDynNFTPictureBox .overlayText {
    margin-top: 90px;
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-white;
    transform: rotate(-90deg);
}

.cardDynNFTPictureBox .overlayArenaText {
    margin-top: 100px;
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-white;
    transform: rotate(-90deg);
}

.cardDynNFTPictureBox .overlayHoverA {
    display: none;
}

.cardDynNFTPictureBox:hover .overlayHoverA {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 57px;
    border-radius: 3px;
    background-color: rgba($jcorp-main-white, 0.7);
    cursor: pointer;
}

.cardDynNFTPictureBox:hover .overlayHoverTextA {
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-red;
}

.cardDynNFTPictureBox .overlayHoverB {
    display: none;
}

.cardDynNFTPictureBox:hover .overlayHoverB {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 57px;
    border-radius: 3px;
    background-color: rgba($jcorp-main-red, 0.7);
    cursor: pointer;
}

.cardDynNFTPictureBox:hover .overlayHoverTextB {
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-white;
}

.cardDynNFTPictureBox .overlayHoverSmallB {
    display: none;
}

.cardDynNFTPictureBox:hover .overlayHoverSmallB {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 59px;
    border-radius: 3px;
    background-color: rgba($jcorp-main-red, 0.7);
    cursor: pointer;
}

.cardDynNFTPictureBox:hover .overlayHoverTextSmallB {
    position: relative;
    display: block;
    margin-left: -4px;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-white;
}

.cardDynNFTPicture {
    width: 142px;
    border-radius: 3px;
}

.arenaRegistrationContainer {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-top: 25px;
    margin-left: 4px;
    margin-right: 4px;
}

.arenaRegistrationBox {
    position: relative;
    display: block;

    width: 160px;
    height: 303px;

    background: $jcorp-main-red;
    border-radius: 5px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.arenaRegistrationBoxIn {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    background: linear-gradient(284.72deg, #9F0836 13.28%, #620C31 96.53%);
    border-radius: 5px;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.arenaRegistrationBoxIn:hover {
    background: linear-gradient(284.72deg, rgba(#9F0836, 0.8) 13.28%, rgba(#620C31, 0.9) 96.53%);
    cursor: pointer;
}

.arenaRegistrationBoxInText {
    position: absolute;
    display: block;
    top: 15px;
    left: 20px;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 100%;
    /* or 20px */

    letter-spacing: 0.07em;
    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-white;
}

.arenaRegistrationBoxInVector {
    position: absolute;
    top: 135px;
    left: 64px;
}

.arenaRegistrationBoxInVector .vectorAdd {
    width: 32px;
    height: 32px;
}

.arenaRegistrationBoxInNumber {
    position: absolute;
    display: block;
    bottom: 15px;
    right: 20px;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 100%;
    /* or 20px */

    letter-spacing: 0.07em;
    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-white;
}

.itemBlock {
    position: relative;
    display: block;
    text-align: center;
    width: 108%;
    left: -4%;
}

.cardItemBlock {
    position: relative;
    display: inline-block;
    margin-top: 25px;
    margin-left: 4px;
    margin-right: 4px;
}

.cardItemBlockShop {
    position: relative;
    display: inline-block;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
    padding: 6px;
    padding-top: 30px;

    background: rgba($jcorp-main-white, 0.2);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.cardItemContainer {
    position: relative;
    display: block;
    width: 160px;
    height: 160px;

    .cardItemBox {
        position: relative;
        display: block;

        width: 160px;
        height: 160px;

        background: var(--rarityColor);
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .cardItemBoxIn {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 158px;
        height: 158px;

        background: #18101B;
        border-radius: 3px;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }
}

.cardItemContainerActive {
    position: relative;
    display: block;
    width: 160px;
    height: 160px;
    filter: drop-shadow(0 0 12px rgba($jcorp-main-red, 0.64));

    .cardItemBox {
        position: relative;
        display: block;

        width: 160px;
        height: 160px;

        background: var(--rarityColor);
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .cardItemBoxIn {
        position: absolute;
        top: 2px;
        left: 2px;

        width: 156px;
        height: 156px;

        background: #18101B;
        border-radius: 3px;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }
}

.cardItemInfos {
    position: absolute;
    display: block;
    text-align: left;

    bottom: -5px;
    left: 16px;
}

.cardArenaItemName {
    position: relative;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    top: -5px;

    /* Main/Red */
    color: $jcorp-main-red;
}

.cardItemHierarchy {
    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    /* or 24px */

    text-transform: uppercase;

    /* Main/Red */
    color: var(--rarityColor);
}

.cardItemPower {
    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    /* or 20px */

    margin-top: -8px;
    text-transform: uppercase;

    /* Main/Light */
    color: $jcorp-main-white;
}

.cardItemUsage {
    position: absolute;
    display: block;
    text-align: right;

    bottom: 0;
    right: 5px;
}

.cardUsageAttack {
    position: relative;
    display: inline-block;
    margin: 3px;
    color: rgb(179, 24, 24);
    font-size: 18px;
}

.cardUsageDef {
    position: relative;
    display: inline-block;
    margin: 3px;
    color: rgb(20, 82, 163);
    font-size: 18px;
}

.cardItemInfosBis {
    position: absolute;
    display: block;
    text-align: center;

    bottom: 18px;
    right: 10px;
}

.cardItemInfosLite {
    position: absolute;
    display: block;
    text-align: center;

    bottom: 1px;
    right: 8px;
}

.cardItemNumber {
    position: relative;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-red;
}

.cardItemName {
    position: relative;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    top: -3px;

    /* Main/Red */
    color: $jcorp-main-red;
}

.cardItemTitle {
    position: relative;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    top: -18px;

    /* Main/Red */
    color: $jcorp-main-red;
}

.cardItemPictureBox {
    position: absolute;
    display: block;
    top: -20px;
    left: 9px;

    width: 142px;
    height: 142px;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.cardItemPictureBox .overlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 1em;
    height: 100%;
    border-top-left-radius: 3px;
    background-color: $jcorp-main-red;
}

.cardItemPictureBox .overlayText {
    margin-top: 90px;
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-white;
    transform: rotate(-90deg);
}

.cardItemPictureBox .overlayHoverA {
    display: none;
}

.cardItemPictureBox:hover .overlayHoverA {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 57px;
    border-radius: 3px;
    background-color: rgba($jcorp-main-white, 0.7);
    cursor: pointer;
}

.cardItemPictureBox:hover .overlayHoverTextA {
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-red;
}

.cardItemPictureBox .overlayHoverB {
    display: none;
}

.cardItemPictureBox:hover .overlayHoverB {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 57px;
    border-radius: 3px;
    background-color: rgba($jcorp-main-red, 0.7);
    cursor: pointer;
}

.cardItemPictureBox:hover .overlayHoverTextB {
    position: relative;
    display: block;

    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-white;
}

.cardItemPictureBox .overlayNumber {
    position: absolute;
    text-align: center;
    top: 5px;
    left: 5px;
    min-width: 30px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    border: 1px solid $jcorp-main-white;
    background-color: rgba(0, 0, 0, 0.5);
}

.cardItemPictureBox .overlayNumberText {
    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* or 24px */

    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $jcorp-main-white;
}

.cardItemPictureBox .overlayNumberTextNumber {
    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* or 24px */

    text-transform: uppercase;
    color: $jcorp-main-white;
}

.cardItemPicture {
    width: 142px;
    border-radius: 3px;
}

.topContainer {
    margin-top: 40px;
}

.topBigContainer {
    margin-top: 80px;
}

.arenaFightContainer {
    position: relative;
    display: block;
    margin-top: 30px;
}

.arenaFighterLeftBox {
    position: absolute;
    display: block;
    top: -22px;
    left: 40px;
}

.arenaFighterRightBox {
    position: absolute;
    display: block;
    top: -22px;
    right: 40px;
}

.arenaFighterOut {
    position: relative;
    display: block;
    margin-left: 10px;
    margin-right: 10px;

    min-width: 150px;
    height: 50px;

    background: $jcorp-main-white;
    border-radius: 3px;
    border: none;

    clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
}

.arenaFighterIn {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 3px;

    background: linear-gradient(-284.72deg, #e9e7d5 13.28%, #afaea6 96.53%);

    clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
}

.arenaFighterText {
    margin: 3px;
    text-align: center;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 100%;
    /* or 20px */

    text-transform: uppercase;

    color: $jcorp-main-black;
}

.arenaFighterImg {
    width: 32px;
    border-radius: 3px;
    clip-path: polygon(0 0, calc(100% - 0.5em) 0, 100% 0.5em, 100% 100%, 0 100%, 0.5em 100%, 0% calc(100% - 0.5em), 0 0%);
}

.arenaFightBox {
    position: relative;
    display: block;
    margin-bottom: 10px;

    background: $jcorp-main-red;
    border-radius: 5px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.arenaFightBoxIn {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    background: #18101B;
    border-radius: 5px;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.arenaFightBoxIn2 {
    position: relative;
    display: inline-block;

    width: 100%;

    background: linear-gradient(0deg, rgba(#18101B, 0) 0%, rgba($jcorp-main-red, 0.2448) 100%);

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.arenaFightBoxInText2 {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 100%;
    /* or 20px */

    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-white;
}

.arenaFightBoxInfos {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px;
}

.imgFighterArena {
    width: 80px;
    height: 80px;
    border-radius: 3px;
    clip-path: polygon(0 0, calc(100% - 0.5em) 0, 100% 0.5em, 100% 100%, 0 100%, 0.5em 100%, 0% calc(100% - 0.5em), 0 0%);
}

.roundCardNFT {
    width: 80px;
    border-radius: 3px;
    clip-path: polygon(0 0, calc(100% - 0.5em) 0, 100% 0.5em, 100% 100%, 0 100%, 0.5em 100%, 0% calc(100% - 0.5em), 0 0%);
}

.roundCardSkill {
    width: 55px;
    border-radius: 3px;
    clip-path: polygon(0 0, calc(100% - 0.5em) 0, 100% 0.5em, 100% 100%, 0 100%, 0.5em 100%, 0% calc(100% - 0.5em), 0 0%);
}

.roundCardLife {
    position: relative;
    display: block;
    width: 160px;
    margin-top: 5px;
}

.roundCardLifeTextBox {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    top: 0;
    left: 0;
}

.roundContainerLeft {
    position: relative;
    display: block;
    text-align: left;
}

.roundCardLeft {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100px;
}

.roundSkillLeft {
    position: absolute;
    top: 0;
    right: 0;
}

.roundCardLeftLifeBox {
    position: relative;
    display: inline-block;
    text-align: left;

    width: 100%;
    height: 20px;

    background: $jcorp-main-red;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 0.6em) 0, 100% 0.6em, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
}

.roundCardLeftLifeOut {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    background: #18101B;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    clip-path: polygon(0 0, calc(100% - 0.6em) 0, 100% 0.6em, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
}

.roundCardLeftLifeIn {
    position: relative;
    display: inline-block;

    width: var(--lifeAPercent);
    height: 100%;

    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    background: linear-gradient(284.72deg, #9F0836 13.28%, #620C31 96.53%);
}

.roundInfosLeft {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.roundInfosName {
    position: relative;
    display: inline-block;
    text-align: center;
}

.roundContainerRight {
    position: relative;
    display: block;
    text-align: right;
}

.roundCardRight {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100px;
}

.roundSkillRight {
    position: absolute;
    top: 0;
    left: 0;
}

.roundCardRightLifeBox {
    position: relative;
    display: inline-block;

    width: 100%;
    height: 20px;

    background: $jcorp-main-red;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border: none;

    clip-path: polygon(0 0.6em, 0.6em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
}

.roundCardRightLifeOut {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    background: #18101B;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    clip-path: polygon(0 0.6em, 0.6em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
}

.roundCardRightLifeIn {
    position: relative;
    display: inline-block;

    width: var(--lifeBPercent);
    height: 100%;

    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    background: linear-gradient(-284.72deg, #9F0836 13.28%, #620C31 96.53%);
}

.roundInfosRight {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.whiteBox {
    position: relative;
    display: block;
    padding: 3px;

    background: rgba($jcorp-main-white, 0.2);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.whiteBoxArena {
    position: relative;
    display: block;
    padding: 3px;
    margin-bottom: 20px;

    background: rgba($jcorp-main-white, 0.2);
    border-radius: 5px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.whiteBoxArena:hover {
    background: rgba($jcorp-main-white, 0.6);
    cursor: pointer;
}

.deployBox {
    position: relative;
    display: block;
    padding: 3px;

    background: rgba($jcorp-main-red, 1);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.defenseBox {
    position: relative;
    display: block;
    padding: 3px;

    background: rgba(rgb(12, 49, 99), 1);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.attackBox {
    position: relative;
    display: block;
    padding: 3px;

    background: rgba(rgb(99, 12, 12), 1);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.paginBoxTop {
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 15px;
}

.paginBoxBottom {
    position: relative;
    display: block;
    text-align: center;
    margin-top: 15px;
}

.paginContainer {
    position: relative;
    display: inline-block;
}

.profileLine {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba($jcorp-main-red, 0.3);
}

.separtorLine {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba($jcorp-main-red, 0.3);
}

.textTitle {
    font-family: Archivo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    /* or 20px */

    text-transform: uppercase;

    /* Main/Light */
    color: $jcorp-main-white;
}

.textError {
    font-family: Archivo;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 100%;

    /* Main/Light */
    color: $jcorp-main-red;
}

.textAttribut {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
    /* or 20px */

    /* Main/Light */
    color: $jcorp-main-white;
}

.textTooltip {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    /* or 20px */

    /* Main/Light */
    color: $jcorp-main-white;
}

.textRed {
    color: $jcorp-main-red;
}

.textImportant {
    font-family: Teko;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 120%;
    /* or 20px */

    /* Main/Light */
    color: $jcorp-main-white;
}

.textImportantRed {
    font-family: Teko;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 120%;
    /* or 20px */

    /* Main/Light */
    color: $jcorp-main-red;
}

.textImportantYellow {
    font-family: Teko;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 120%;
    /* or 20px */

    /* Main/Light */
    color: #ffc107;
}

.textImportant a {
    /* Main/Light */
    color: $jcorp-main-red;
}

.textImportant a:hover {
    /* Main/Light */
    color: rgba($jcorp-main-red, 0.8);
}

.textTimerRed {
    font-family: Teko;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    /* or 20px */

    /* Main/Light */
    color: $jcorp-main-red;
}

.textTimerRest {
    font-family: Teko;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    /* or 20px */

    /* Main/Light */
    color: $jcorp-main-red;
}

.textInfos {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    /* or 20px */

    text-align: justify;

    /* Main/Light */
    color: $jcorp-main-white;
}

.textInfos a {
    /* Main/Light */
    color: $jcorp-main-red;
    font-weight: 900;
}

.textInfos a:hover {
    /* Main/Light */
    color: rgba($jcorp-main-red, 0.6);
}

.rulesTitle {
    font-family: Archivo;
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 85%;
    /* or 41px */

    letter-spacing: -0.07em;
    text-transform: uppercase;

    /* Main/Light */

    color: $jcorp-main-white;
}

.rulesNumber {
    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 89%;
    /* or 71px */

    /* Main/Red */
    color: $jcorp-main-red;
}


.marginBot20 {
    margin-bottom: 20px;
}


.form-control {
    color: $jcorp-main-black !important;
    background-color: $jcorp-main-white !important;
}

.custom-select {
    color: $jcorp-main-black !important;
    background-color: $jcorp-main-white !important;
}




.Mui-selected {
    background-color: $jcorp-main-red !important;
}

.MuiButtonBase-root {
    color: $jcorp-main-white !important;
    font-family: CustomFont !important;
}

.MuiButtonBase-root:hover {
    background-color: $jcorp-main-red !important;
}

.MuiPaginationItem-root {
    color: $jcorp-main-white !important;
    font-family: CustomFont !important;
    border: 1px solid #adb5bd !important;
}

.MuiPaginationItem-ellipsis {
    border: none !important;
}

.MuiButton-root {
    color: $jcorp-main-white !important;
    font-family: CustomFont !important;
    border: 1px solid #adb5bd !important;
}

.MuiRadio-root {
    color: $jcorp-main-white !important;
    font-family: CustomFont !important;
}

.radioGroupLabel {
    margin: 0;
}

.btnDisabled {
    opacity: 0.4;
}

.btnNoBorder {
    font-size: 24px !important;
    border: none !important;
}

.switchBtn .btn-primary {
    background-color: $jcorp-main-red !important;
}

.switchBtn .btn-light {
    background-color: $jcorp-main-black !important;
}

.switchBtn .btn {
    border: none !important;
}

.switchBtn .switch-on {
    color: $jcorp-main-white !important;
    border: 1px solid $jcorp-main-red !important;
}

.switchBtn .switch-off {
    color: $jcorp-main-white !important;
    border: 1px solid $jcorp-main-red !important;
}

.switchBtn .switch-handle {
    background-color: $jcorp-main-white !important;
}

.MuiSelect-select {
    width: 150px !important;
    height: 30px !important;
    color: $jcorp-main-black !important;
    background-color: $jcorp-main-white !important;
    padding-left: 12px !important;
    border-radius: 3px !important;

    font-family: Rajdhani !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 140% !important;
}

.MuiSelect-select:hover {
    box-shadow: 0px 0px 0px 5px rgba($jcorp-main-red, 0.16) !important;
}

.MuiInput-underline:before {
    border: none !important;
}

.MuiInput-underline:after {
    border: none !important;
}


.MuiPaper-root {
    background-color: $jcorp-main-white !important;
}

.MuiPaper-root .Mui-focusVisible {
    box-shadow: none !important;
}

.MuiMenuItem-root {
    color: $jcorp-main-black !important;
    background-color: $jcorp-main-white !important;
    font-family: Rajdhani !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 140% !important;
}

.MuiMenuItem-root:hover {
    color: $jcorp-main-white !important;
    background-color: $jcorp-main-red !important;
}

.Mui-selected {
    color: $jcorp-main-white !important;
    background-color: $jcorp-main-red !important;
}



.MuiSlider-thumb {
    background-color: $jcorp-main-red !important;
}

.MuiSlider-thumb:hover,
.Mui-focusVisible {
    box-shadow: 0px 0px 0px 8px rgba($jcorp-main-red, 0.16) !important;
}

.Mui-active,
.Mui-active:hover {
    box-shadow: 0px 0px 0px 14px rgba($jcorp-main-red, 0.16) !important;
}

.MuiSlider-rail {
    background-color: $jcorp-main-red !important;
}

.MuiSlider-track {
    background-color: $jcorp-main-red !important;
    border: none !important;
}

.MuiSlider-mark {
    background-color: $jcorp-main-red !important;
}

.MuiSlider-markActive {
    background-color: $jcorp-main-white !important;
}

.MuiSlider-markLabel {
    font-family: Rajdhani !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 100% !important;
    /* or 20px */

    /* Main/Light */
    color: $jcorp-main-white !important;
    margin-left: 6px !important;
}

.headTable {
    font-family: Archivo;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 85%;
    /* or 88px */

    letter-spacing: -0.08em;
    text-transform: uppercase;

    /* Main/Light */
    color: $jcorp-main-white;
    background-color: rgba($jcorp-main-red, 0.8);
}

.rowTableResult {
    /* Main/Light */
    color: $jcorp-main-white;
}

.rowTableResult:hover {
    background-color: rgba($jcorp-main-red, 0.4);
    cursor: pointer;
}

.rowTableSelfResult {
    /* Main/Light */
    color: $jcorp-main-white;
    background-color: rgba(rgb(228, 228, 77), 0.5);
}

.rowTableSelfResult:hover {
    background-color: rgba($jcorp-main-red, 0.4);
    cursor: pointer;
}

.rowTableSelected {
    /* Main/Light */
    color: $jcorp-main-white;
    background-color: rgb(10, 112, 10);
}

.rowTableSelected:hover {
    background-color: rgba(rgb(10, 112, 10), 0.6);
    cursor: pointer;
}

.rowTableNoSelect {
    /* Main/Light */
    color: $jcorp-main-white;
    background-color: rgba(#ffc107, 0.6);
}

.rowTableNoSelect:hover {
    background-color: rgba(#ffc107, 0.4);
}

.rowTableContent {
    /* Main/Light */
    color: $jcorp-main-white;
}

.rowTableContent:hover {
    background-color: rgba($jcorp-main-red, 0.4);
}

.textResult {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    /* or 20px */

    color: $jcorp-main-white;
}



.textToken {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 120%;
    /* or 20px */

    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    color: $jcorp-main-black;
}

.textBold {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 120%;

    color: $jcorp-main-white;
}

.textBoldBig {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 120%;

    color: $jcorp-main-white;
}

.iconToken {
    font-size: 14px;
}

.rarityShadow {
    margin: 8px !important;
    box-shadow: 2px 2px 5px var(--rarityColor),
        -2px -2px 5px var(--rarityColor),
        -2px 2px 5px var(--rarityColor),
        2px -2px 5px var(--rarityColor) !important;
}

.containerTokenEmpty {
    position: relative;
    display: inline-block;
    width: 160px;
    height: 160px;
    margin-top: 25px;
    margin-left: 4px;
    margin-right: 4px;
    color: $jcorp-main-white;

    background: rgba($jcorp-main-red, 0.8);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.containerTokenEmpty:hover {
    background-color: rgba($jcorp-main-red, 1);
    cursor: pointer;
}

.containerTokenAdd {
    position: absolute;
    width: 160px;
    height: 160px;
    top: 0;
    left: 0;
}

.containerTokenAddText {
    position: relative;
    display: block;
    padding-top: 40px;
    font-size: 60px;
    line-height: 100%;
}

.containerToken {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 130px;
    border-radius: 5px;
    margin: 5px;
    background-color: #f8f9fa;
    box-shadow: 0px 1px 5px #000;
}

.containerToken .rarityColor {
    text-transform: uppercase;
    color: var(--rarityColor);
}

.containerToken .overlayNumber {
    position: absolute;
    display: inline-block;
    text-align: center;
    top: 5px;
    right: 5px;
    min-width: 30px;
    padding-left: 5px;
    padding-right: 5px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #ced4da;
    background-color: rgba(0, 0, 0, 0.5);
}

.containerToken .overlay {
    position: absolute;
    display: inline-block;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: rgb(7, 90, 7);
}

.containerToken .overlayHover {
    display: none;
}

.containerToken:hover .overlayHover {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .5);
    cursor: pointer;
}

.containerToken:hover .border {
    border: 1px solid #ced4da;
}

.containerColorSelected {
    border-radius: 5px;
    border: 2px solid rgb(7, 90, 7);
}

.imgToken {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.imgSquadIcon {
    width: 40px;
    margin-left: 8px;
    padding: 3px;
    border-radius: 3px;
    background-color: var(--squadColor);
}

.rowImgSquadIcon {
    width: 24px;
    margin-right: 8px;
    padding: 2px;
    border-radius: 2px;
    background-color: var(--squadColor);
}

.rowActionIcon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    margin-left: 15px;
    font-size: 20px;
    color: $jcorp-main-white;
}

.rowActionIcon:hover {
    color: $jcorp-main-red;
    cursor: pointer;
}

.wheelColorBox {
    margin: auto;
}

.wheelColorBox:hover {
    cursor: pointer;
}

.radioFormRow {
    position: relative;
    display: block;
    left: 15px;
    text-align: left;
}

.radioFormButton {
    position: relative;
    display: inline-block;
    margin: 0;
}

.radioFormImg {
    position: relative;
    display: inline-block;
    left: -20px;
}

.radioFormIcon {
    position: relative;
    display: inline-block;
    left: -20px;
}

.radioFormIcon:hover {
    cursor: pointer;
}

.radioFormLabel {
    position: relative;
    display: inline-block;
    width: 210px;
    top: 5px;
    left: -10px;
    vertical-align: middle;
}

.badgeInfos {
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;

    /* Main/Light */
    color: $jcorp-main-white !important;
}

.badgeDefault {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 70px;
    height: 22px;

    background: rgba(#6c757d, 0.8);
    border: none;

    clip-path: polygon(0 0, calc(100% - 0.4em) 0, 100% 0.4em, 100% 100%, 0 100%, 0.4em 100%, 0% calc(100% - 0.4em), 0 0%);
}

.badgePending {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 70px;
    height: 22px;

    background: rgba(#e1aa04, 0.8);
    border: none;

    clip-path: polygon(0 0, calc(100% - 0.4em) 0, 100% 0.4em, 100% 100%, 0 100%, 0.4em 100%, 0% calc(100% - 0.4em), 0 0%);
}

.badgeSuccess {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 70px;
    height: 22px;

    background: rgba(rgb(10, 112, 10), 0.8);
    border: none;

    clip-path: polygon(0 0, calc(100% - 0.4em) 0, 100% 0.4em, 100% 100%, 0 100%, 0.4em 100%, 0% calc(100% - 0.4em), 0 0%);
}

.badgeFail {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 70px;
    height: 22px;

    background: rgba(rgb(141, 17, 17), 0.8);
    border: none;

    clip-path: polygon(0 0, calc(100% - 0.4em) 0, 100% 0.4em, 100% 100%, 0 100%, 0.4em 100%, 0% calc(100% - 0.4em), 0 0%);
}

.mintPictureBox {
    position: relative;
    display: block;

    margin-top: 80px;
    margin-bottom: -200px;

    left: calc(50% - 165px);

    .cardContainer {
        position: relative;
        display: block;
        width: 177px;
        height: 177px;
    }

    .cardContainerHome1 {
        position: relative;
        display: block;
        width: 177px;
        height: 177px;

        top: 0;
        left: 130px;

        z-index: 99;
    }

    .cardContainerHome2 {
        position: relative;
        display: block;
        width: 177px;
        height: 177px;

        top: -20px;
        right: -160px;

        z-index: 90;
    }

    .cardContainerHome3 {
        position: relative;
        display: block;
        width: 177px;
        height: 177px;

        top: -290px;
        left: 0;

        z-index: 95;
    }

    .cardBox {
        position: relative;
        display: block;

        width: 177px;
        height: 177px;

        background: $jcorp-main-red;
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .cardBoxIn {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 175px;
        height: 175px;

        background: #18101B;
        border-radius: 3px;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .cardInfos {
        position: absolute;
        display: block;
        text-align: left;

        bottom: -5px;
        left: 18px;
    }

    .cardHierarchy {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        /* or 24px */

        text-transform: uppercase;

        /* Main/Red */
        color: $jcorp-main-white;
    }

    .cardPower {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        /* or 20px */

        margin-top: -8px;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .cardInfosBis {
        position: absolute;
        display: block;

        bottom: 8px;
        right: 15px;
    }

    .cardNumber {
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 100%;
        /* or 24px */

        text-transform: uppercase;
        text-align: center;

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .cardName {
        position: relative;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        /* or 24px */

        text-transform: uppercase;
        text-align: center;
        top: -3px;

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .cardGamePictureBox {
        position: absolute;
        top: -20px;
        left: 9px;

        width: 159px;
        height: 159px;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .cardGamePicture {
        width: 159px;
        border-radius: 3px;
    }
}

.statusSuccess {
    position: relative;
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin: auto;

    font-family: Archivo;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 120%;
    /* or 41px */

    text-transform: uppercase;
    text-align: center;

    /* Main/Light */
    color: $jcorp-main-white;

    background: rgba(#15c92d, 0.6);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 0.8em) 0, 100% 0.8em, 100% 100%, 0 100%, 0.8em 100%, 0% calc(100% - 0.8em), 0 0%);
}

.statusWarning {
    position: relative;
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin: auto;

    font-family: Archivo;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 120%;
    /* or 41px */

    text-transform: uppercase;
    text-align: center;

    /* Main/Light */
    color: $jcorp-main-white;

    background: rgba(#ffc107, 0.6);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 0.8em) 0, 100% 0.8em, 100% 100%, 0 100%, 0.8em 100%, 0% calc(100% - 0.8em), 0 0%);
}

.statusAlert {
    position: relative;
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin: auto;

    font-family: Archivo;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 120%;
    /* or 41px */

    text-transform: uppercase;
    text-align: center;

    /* Main/Light */
    color: $jcorp-main-white;

    background: rgba($jcorp-main-red, 0.6);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 0.8em) 0, 100% 0.8em, 100% 100%, 0 100%, 0.8em 100%, 0% calc(100% - 0.8em), 0 0%);
}

.menuContainer {
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 30px;

    width: 110%;
    left: -5%;
}

.menuBox {
    position: relative;
    display: inline-block;

    margin: 5px;

    width: 300px;
    height: 200px;

    background: $jcorp-main-red;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.menuBoxIn {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    background: #18101B;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.menuBoxImgContainer {
    position: absolute;
    top: -50px;
    left: 0;
    opacity: 0.75;
}

.menuBoxImgContainer:hover {
    opacity: 1;
    cursor: pointer;
}

.menuBoxImg {
    width: 300px;
}

.menuBoxIn2 {
    position: absolute;
    display: block;
    top: 0;
    right: 1px;

    width: 200px;

    background: linear-gradient(0deg, rgba(#18101B, 0.8) 0%, rgba($jcorp-main-red, 0.9) 100%);

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.menuBoxIn2Text {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 30px;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 100%;
    /* or 20px */

    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-white;
}

.menuBoxIn3 {
    position: absolute;
    display: block;
    bottom: 1px;
    left: 1px;

    width: 200px;

    background: linear-gradient(0deg, rgba(#18101B, 0.8) 0%, rgba(#18101B, 0.5) 100%);

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.menuBoxIn3Text {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 30px;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 100%;
    /* or 20px */

    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-white;
}

.menuInfosContainer {
    position: absolute;
    top: 2px;
    right: 12px;
}

.menuInfosContainer:hover {
    cursor: pointer;
}

.menuInfosIcon {
    width: 32px;
}

.settingsMenuBox {
    position: relative;
    display: block;
    margin-top: 10px;
}

.settingsMenuTitle {
    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 89%;
    /* or 21px */

    margin-bottom: 15px;

    letter-spacing: 0.07em;
    text-transform: uppercase;

    /* Main/Red */

    color: $jcorp-main-white;
}

.btnDisconnect {
    position: relative;
    display: block;
    width: 160px;
    height: 58px;
    margin: auto;

    background: $jcorp-main-red;
    border-radius: 2px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
}

.btnDisconnect:hover {
    background: $jcorp-main-black;
}

.btnDisconnectIn {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    background: $jcorp-main-black;
    border-radius: 2px;

    /* Main/Light */
    color: $jcorp-main-red;

    transition-duration: 0.4s;

    clip-path: polygon(0 0, calc(100% - 1.45em) 0, 100% 1.45em, 100% 100%, 0 100%, 1.45em 100%, 0% calc(100% - 1.45em), 0 0%);
}

.btnDisconnectIn:hover {
    background: $jcorp-main-red;
    /* Main/Light */
    color: $jcorp-main-white;
    cursor: pointer;
}

.btnDisconnectIn:active {
    background: rgba($jcorp-main-red, 0.6);
    transition-duration: 0s;
}

.btnDisconnectText {
    text-align: center;

    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;

    padding-top: 17px;
}

.btnConnect {
    position: relative;
    display: block;
    width: 160px;
    height: 58px;

    background: $jcorp-main-red;
    border-radius: 2px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
}

.btnConnect:hover {
    background: $jcorp-main-black;
}

.btnConnectIn {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    background: $jcorp-main-black;
    border-radius: 2px;

    transition-duration: 0.4s;

    clip-path: polygon(0 0, calc(100% - 1.45em) 0, 100% 1.45em, 100% 100%, 0 100%, 1.45em 100%, 0% calc(100% - 1.45em), 0 0%);
}

.btnConnectIn:hover {
    background: $jcorp-main-red;
    cursor: pointer;
}

.btnConnectIn:active {
    background: rgba($jcorp-main-red, 0.6);
    transition-duration: 0s;
}

.btnConnectText {
    text-align: center;

    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;

    /* Main/Light */
    color: $jcorp-main-white;

    padding-top: 17px;
}

.btnConnectSmall {
    position: relative;
    display: inline-block;
    margin: 5px;

    width: 130px;
    height: 50px;

    color: $jcorp-main-white;
    background: $jcorp-main-red;
    border-radius: 2px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1.2em) 0, 100% 1.2em, 100% 100%, 0 100%, 1.2em 100%, 0% calc(100% - 1.2em), 0 0%);
}

.btnConnectSmall:hover {
    background: $jcorp-main-black;
}

.btnConnectSmallIn {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    background: $jcorp-main-black;
    border-radius: 2px;

    transition-duration: 0.4s;

    clip-path: polygon(0 0, calc(100% - 1.2em) 0, 100% 1.2em, 100% 100%, 0 100%, 1.2em 100%, 0% calc(100% - 1.2em), 0 0%);
}

.btnConnectSmallIn:hover {
    background: $jcorp-main-red;
    cursor: pointer;
}

.btnConnectSmallIn:active {
    background: rgba($jcorp-main-red, 0.6);
    transition-duration: 0s;
}

.btnConnectSmallText {
    text-align: center;

    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    text-transform: uppercase;

    /* Main/Light */
    color: $jcorp-main-white;

    padding-top: 15px;
}

.logOffContainer {
    position: absolute;
    top: 10px;
    right: 10px;
}

.btnMenu {
    position: relative;
    display: inline-block;
    margin: 5px;

    width: 130px;
    height: 50px;

    color: $jcorp-main-white;
    background: $jcorp-main-green;
    border-radius: 2px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1.2em) 0, 100% 1.2em, 100% 100%, 0 100%, 1.2em 100%, 0% calc(100% - 1.2em), 0 0%);
}

.btnMenu:hover {
    background: $jcorp-main-black;
}

.btnMenuIn {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    background: $jcorp-main-black;
    border-radius: 2px;

    transition-duration: 0.4s;

    clip-path: polygon(0 0, calc(100% - 1.2em) 0, 100% 1.2em, 100% 100%, 0 100%, 1.2em 100%, 0% calc(100% - 1.2em), 0 0%);
}

.btnMenuIn:hover {
    background: rgba($jcorp-main-green, 0.7);
    cursor: pointer;
}

.btnMenuIn:active {
    background: rgba($jcorp-main-green, 0.5);
    transition-duration: 0s;
}

.btnMenuText {
    text-align: center;

    /* Button/Default */
    font-family: Teko;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    text-transform: uppercase;

    /* Main/Light */
    color: $jcorp-main-white;

    padding-top: 15px;
}

.imgBoxFaction {
    position: relative;
    display: block;
    margin-left: 15px;

    width: 77px;

    background: $jcorp-main-white;
    border-radius: 3px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 0.5em) 0, 100% 0.5em, 100% 100%, 0 100%, 0.5em 100%, 0% calc(100% - 0.5em), 0 0%);
}

.imgFactionSmall {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    border-radius: 3px;

    clip-path: polygon(0 0, calc(100% - 0.5em) 0, 100% 0.5em, 100% 100%, 0 100%, 0.5em 100%, 0% calc(100% - 0.5em), 0 0%);
}

.searchContainer {
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 30px;

    width: 110%;
    left: -5%;
}

.searchBox {
    position: relative;
    display: inline-block;
    margin: 5px;
    padding-top: 60px;
    padding-bottom: 20px;

    width: 240px;

    background: rgba($jcorp-main-white, 0.2);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.searchBoxIn2 {
    position: absolute;
    display: block;
    top: 0;
    right: 0;

    width: 200px;

    background: linear-gradient(0deg, rgba(#18101B, 0.8) 0%, rgba($jcorp-main-red, 0.9) 100%);

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.searchBoxIn2Text {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 30px;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 100%;
    /* or 20px */

    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-white;
}

.stripModalContainerRight {
    position: relative;
    display: block;
    height: 55px;
}

.stripModalBoxRight {
    position: absolute;
    width: 66%;
    height: 55px;

    top: 0;
    right: 0;

    /* Main/Red */
    background: $jcorp-main-red;

    z-index: 1;
}

.stripModalBoxRight::before {
    content: "";
    position: absolute;
    width: 100vw;
    height: 55px;
    top: 0;
    left: 0;

    /* Main/Red */
    background: $jcorp-main-red;

    z-index: 1;
}

.stripModalBoxRight::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 55px;
    top: 0;
    left: -30px;

    /* Main/Red */
    background: $jcorp-main-red;
    border-top-right-radius: 2px;

    clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 1.5em), calc(100% - 1.5em) 100%, 0% 100%, 0% 100%, 0 0%);

    z-index: 1;
    transform: rotateY(180deg);
}

.stripModalBoxRight .stripBoxRightRank {
    position: absolute;
    display: block;
    top: -28px;
    left: 0;
}

.stripModalBoxRight .stripBoxRightList {
    position: absolute;
    display: block;

    top: 8px;
    left: 30px;

    z-index: 2;
}

.stripModalBoxRight .stripInfos {
    position: relative;
    display: block;

    width: 150px;
    height: 50px;

    background: $jcorp-main-white;
    border-radius: 3px;
    border: none;

    clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
}

.stripModalBoxRight .stripInfosIn {
    position: relative;
    display: inline-block;
    margin: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 3px;

    background: linear-gradient(-284.72deg, #e9e7d5 13.28%, #afaea6 96.53%);

    clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
}

.stripModalBoxRight .stripInfosText {
    margin: 3px;
    text-align: center;

    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 100%;
    /* or 20px */

    text-transform: uppercase;

    color: $jcorp-main-black;
}

.leadSizeRankingBox {
    display: inline-block;
    padding-left: 10px;
}

.leadSizeRanking {
    font-family: Teko;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 89%;
    /* or 21px */

    margin-bottom: 15px;

    letter-spacing: 0.07em;
    text-transform: uppercase;

    /* Main/Red */

    color: $jcorp-main-white;
}

.leadSizeRanking:hover {
    cursor: pointer;
    text-decoration: underline;
}

.tournamentSkillsContainer {
    position: relative;
    display: block;
    margin: auto;
    width: 340px;
    padding-top: 10px;
    padding-bottom: 10px;

    background: rgba($jcorp-main-white, 0.2);
    border-radius: 4px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.tournamentSkillPictureBox {
    position: relative;
    display: block;

    width: 102px;
    height: 102px;

    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.tournamentSkillPicture {
    width: 102px;
    border-radius: 3px;
}

.tournamentSkillsInfos {
    position: relative;
    display: block;
    text-align: center;
}

.tournamentSkillsDataBox {
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.tournamentSkillsDataBox:first-child {
    padding-right: 10px;
}

.tournamentSkillsDataBox:last-child {
    padding-left: 10px;
}

.tournamentSkillsActionContainer {
    position: relative;
    display: block;
    text-align: center;
    margin-top: 20px;
}

.tournamentSkillsActionIcon {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;
    font-size: 20px;
    color: $jcorp-main-white;
}

.tournamentSkillsActionIcon:hover {
    color: $jcorp-main-red;
    cursor: pointer;
}


// ----- NEW DESIGN ----- //
.navSubGameContainer {
    position: relative;
}

.navGroupBottomMainDiv {
    position: relative;
    display: block;
    top: 19px;
    width: 95%;

    @media (max-width: 991px) {
        display: none;
    }
}

/*
.navGroupBottomMainDiv {
    position: absolute;
    top: calc(40% + 67px);
    left: 0%;
    right: 5vw;

    @media (max-width: 991px) {
        display: none;
    }
}
    */

@media (min-width: 992px) {
    .navGroupBottomMainDivInMobile {
        display: none;
    }
}

#navGroupBottomPart {
    padding: 0;
    z-index: 10;
    height: 67px;
    // left: 0%;
    // right: 5vw;
    background: $jcorp-main-red;
    // border-right: 1px solid $jcorp-main-red;
    text-transform: uppercase;
    clip-path: polygon(10% 0,
            100% 0,
            100% 10%,
            100% 60%,
            calc(100% - 2em) 100%,
            0% 100%,
            0% 100%,
            0 0%);

    .navGroupBottomPartIn {
        margin: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        display: inline-block;

        background-color: $jcorp-main-black;
        clip-path: polygon(10% 0,
                100% 0,
                100% 10%,
                100% 60%,
                calc(100% - 2em) 100%,
                0% 100%,
                0% 100%,
                0 0%);
    }

    .bottom_nav_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .ranked_Arena_wrapper {
            cursor: pointer;
            display: flex;
            padding-left: 20px;
            gap: 18px;
            align-items: center;

            h2 {
                font-family: Teko;
                color: $jcorp-main-white;
                height: 24px;
            }

            div.icon-box {
                border: 1px solid $jcorp-main-red;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                border-radius: 2px;

                .icon {
                    color: $jcorp-main-red;
                    font-size: 16px;
                }
            }
        }

        .navGroupBottomPartNavigation_lists {
            gap: 53px;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                color: $jcorp-main-white;
                cursor: pointer;
                font-family: Archivo;
                font-size: 16px;
                transition-duration: 0.4s;

                &:hover {
                    color: $jcorp-main-red;
                }
            }
        }
    }
}

.navBottomHeadingDropDown {
    position: absolute;
    top: 100%;
    list-style: none;
    color: $jcorp-main-white;
    background: $jcorp-main-black;

    text-transform: uppercase;
    border: 1px solid $jcorp-main-red;
    border-top-color: transparent;
    transition: all 0.3s linear;
    padding: 0 20px;
    z-index: 30;
    visibility: hidden;
    opacity: 0;
    height: 0px;

    li {
        transition: all 0.3s linear;
        padding: 8px 10px;
        cursor: pointer;
        font-family: Teko;
        font-size: 28px;
        opacity: 0;

        &:hover {
            color: $jcorp-main-red;
        }
    }

    &.active {
        visibility: visible;
        opacity: 1;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: auto;

        li {
            opacity: 1;
        }
    }
}

@media (max-width: 991px) {
    .navGroupBottomMainDivInMobile {
        display: block;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 72px;
        background: $jcorp-main-black;
        z-index: 990;

        ul {
            height: 100%;
            display: flex;
            gap: 3px;
            padding: 0;

            li {
                padding-top: 15px;
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: center;
                align-items: center;
                background: linear-gradient(180deg,
                        rgba(36, 16, 44, 0) 0%,
                        #3f3145 100%);
                border: 1px solid #404252;
                border-radius: 2px 2px 0 0;

                h6 {
                    color: $jcorp-main-white;
                    font-family: Teko;
                    font-size: 12px;
                    text-transform: uppercase;
                }

                &.active {
                    background: linear-gradient(345.58deg,
                            #9f0836 -0.85%,
                            #620c31 97.68%);
                    border-color: #ff003d;
                    box-shadow: 0px 4px 34px rgba(255, 0, 61, 0.8);

                    h6 {
                        color: $jcorp-main-red;
                    }
                }
            }
        }
    }
}

.pageHeading {
    text-transform: uppercase;
    position: relative;

    h2 {
        position: absolute;
        top: 10px;
        left: -6px;
        color: $jcorp-main-red;
        font-family: ThirdRailDemo;
        font-size: 24px;
    }

    span {
        font-size: 72px;
        letter-spacing: -0.08em;
        color: $jcorp-main-white;
        font-family: Archivo;
    }

    @media (max-width: 991px) {
        h2 {
            top: -4px;
            left: -4px;
            font-size: 16px;
        }

        span {
            font-size: 24px;
        }
    }
}

.menuWrapper {
    position: relative;
    display: block;
}

.menuBackTitle {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 80px);
    text-transform: uppercase;
    letter-spacing: -0.08em;
    color: $jcorp-main-white;
    font-family: Archivo;
    font-size: 24px;
    line-height: 20px;
}

.menuBack {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    width: 80px;
    font-family: Teko;
    font-size: 22px;
    font-weight: 300;
    text-transform: uppercase;
    color: $jcorp-main-white;
}

.menuBackIcon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.boxWrapperContainer {
    margin-top: 20px;
}

.inventoryTabsWrapper {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 16px;

    .inventoryTabsLine {
        height: 1px;
        background: $jcorp-main-red;
        flex: 1;
    }

    .inventoryTabsItemsWrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        .inventoryTabsItems {
            background-color: $jcorp-main-black;
            color: $jcorp-main-white;
            border: 1px solid $jcorp-main-red;
            border-radius: 2px;
            font-family: Teko;
            font-size: 20px;
            padding: 8px 24px 4px 24px;
            cursor: pointer;
            height: 36px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            transition-duration: 0.4s;
        }

        @media (min-width: 992px) {
            .inventoryTabsItems:hover {
                background-color: $jcorp-main-red;
            }
        }

        .inventoryTabsItems:active {
            background-color: rgba($jcorp-main-red, 0.6);
            border: 1px solid $jcorp-main-red;
            transition-duration: 0s;
        }

        .inventoryTabsItemsActive {
            background-color: $jcorp-main-red;
            color: $jcorp-main-black;
        }
    }
}