@import "common_values.scss";

@media (max-width: 991px) {
    .topArenaContainer {
        margin-top: 80px !important;
    }

    .bigBox {
        display: none;
    }

    .connectBtn {
        position: relative !important;
        margin-bottom: 15px !important;
        padding: 10px !important;

        /* Main/Light */
        color: $jcorp-main-white !important;

        width: 92% !important;
        height: 60px !important;
        background: $jcorp-main-black !important;
        border: 1px solid $jcorp-main-red !important;
        border-radius: 0 !important;
        box-shadow: none !important;

        transition-duration: 0.4s !important;
    }

    .connectBtn:active {
        background: rgba($jcorp-main-red, 0.6) !important;
        border: 1px solid $jcorp-main-red !important;
        transition-duration: 0s !important;
    }

    .smallBox {
        display: block;
    }

    .maintenanceContainer {
        position: relative;
        display: block;
    }

    .maintenanceContainer:before {
        content: ' ';
        display: fixed;
        position: absolute;
        left: 0;
        top: -30px;
        width: 100%;
        height: 640px;
        background-image: url('../img/titan.png');
        background-repeat: no-repeat;
        background-position: calc(50% + 60px) 0;
        background-size: 680px;
    }

    .topMaintenanceContainer {
        margin-top: calc(30% + 50px) !important;
    }

    .pageContainer {
        position: relative;
        display: block;
    }

    .pageContainer:before {
        content: ' ';
        display: fixed;
        position: absolute;
        left: 0;
        top: -30px;
        width: 100%;
        height: 640px;
        background-image: var(--bg-image);
        background-repeat: no-repeat;
        background-position: calc(50% + 60px) 0;
        background-size: 680px;
    }

    .shadeContainer .pageImgTexture2 {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: -100px;
        width: 100%;
        height: 700px;
        background-image: url('../img/texture.png');
        background-repeat: no-repeat;
        background-position: 50% 0;
    }

    .shadeContainer .shade1 {
        position: absolute;
        top: -30px;
        left: 0;
        width: 100%;
        height: 320px;

        background: linear-gradient(180deg, #24102C 0%, rgba(36, 16, 44, 0) 100%);
        //border: 1px solid blue;
    }

    .shadeContainer .shade2 {
        position: absolute;
        top: -30px;
        left: calc(50% + 60px);
        width: 340px;
        height: 650px;

        background: linear-gradient(270deg, #24102C 0%, rgba(36, 16, 44, 0) 100%);
        //border: 1px solid green;
    }

    .shadeContainer .shade3 {
        position: absolute;
        top: -30px;
        right: calc(50% - 60px);
        width: 340px;
        height: 650px;

        background: linear-gradient(90deg, #24102C 0%, rgba(36, 16, 44, 0) 100%);
        //border: 1px solid red;
    }

    .shadeContainer .shade4 {
        position: absolute;
        top: 260px;
        left: 0;
        width: 100%;
        height: 360px;

        background: linear-gradient(180deg, #24102C 0%, rgba(36, 16, 44, 0) 100%);
        //border: 1px solid grey;
        transform: rotate(-180deg);
    }

    .shadeContainer .shade5 {
        position: absolute;
        top: 260px;
        left: -50px;
        width: 125%;
        height: 360px;

        background: linear-gradient(180deg, #24102C 0%, rgba(36, 16, 44, 0) 100%);
        //border: 1px solid yellow;
        transform: rotate(-180deg);
    }

    .navGroupContainer {
        position: relative;
        width: 100%;
        height: 80px;
    }

    #navGroup {
        position: absolute;
        width: 70px;
        height: 70px;
        left: 0%;
        top: 40%;

        /* Main/Red */

        background: $jcorp-main-red;
        border-radius: 2px;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 1.8em), calc(100% - 1.8em) 100%, 0% 100%, 0% 100%, 0 0%);
    }

    #navbarResponsive {
        display: none;
    }

    .logoNav {
        display: none;
    }

    .logoContainer {
        display: none;
    }

    .socialIconsContainer {
        display: none;
    }

    .navBtnContainer {
        display: none;
    }

    #navGroup .navToggleBox {
        position: absolute;
        left: 18%;
        top: 9px;
        color: $jcorp-main-white;
        font-style: normal;
        font-weight: 900;
        font-size: 22px;
        line-height: 32%;
        border: none;
        box-shadow: none;
    }

    .logoSmall {
        position: absolute;
        width: 160px;
        right: 2%;
        top: 65%;
    }

    #navGroupCollapsed {
        position: absolute;
        display: block;
        width: 80vw;
        height: 100vh;
        left: 0%;

        padding: 10px;

        /* Main/Red */

        background: $jcorp-main-red;
        border-radius: 2px;

        transition: 1s ease-in-out;

        z-index: 195;
    }

    #navGroupCollapsed::after {
        content: "";
        position: absolute;
        width: 20vw;
        height: 100vh;
        left: 100%;
        top: 0;

        opacity: 0.7;

        /* Main/Red */

        background: $jcorp-main-black;

        z-index: 190;
    }

    #navGroupCollapsed #navbarResponsive {
        display: block;
        margin-top: -6px;
    }

    #navGroupCollapsed .navbarScrollable {
        max-height: 97vh;
    }

    #navGroupCollapsed .navToggleBox {
        border: none;
        box-shadow: none;
        z-index: 199;
    }

    #navGroupCollapsed .navToggle {
        position: absolute;
        right: -30px;
        top: 38px;
        width: 60px;
        height: 60px;

        background: $jcorp-main-red;
        border-radius: 2px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 1.2em), calc(100% - 1.2em) 100%, 0% 100%, 0% 100%, 0 0%);
    }

    #navGroupCollapsed .navToggle:hover {
        background: $jcorp-main-black;
    }

    #navGroupCollapsed .navToggleIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: $jcorp-main-black;
        border-radius: 2px;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 1.2em), calc(100% - 1.2em) 100%, 0% 100%, 0% 100%, 0 0%);
    }

    #navGroupCollapsed .navToggleIn:hover {
        background: $jcorp-main-red;
    }

    #navGroupCollapsed .navToggleIn:active {
        background: rgba($jcorp-main-red, 0.6);
        transition-duration: 0s;
    }

    #navGroupCollapsed .navToggleVector {
        position: absolute;
        left: 20px;
        top: 20px;
        width: 16px;
        height: 16px;
    }

    #navGroupCollapsed .logoNav {
        position: relative;
        display: block;
        width: 160px;
        margin-left: 40px;
        margin-top: 35px;
        margin-bottom: 40px;
    }

    #navGroupCollapsed .navBtnContainerSmall {
        margin-top: 50px;
        margin-bottom: 20px;
    }

    #navGroupCollapsed #navbarResponsive .nav-item {
        margin-top: 10px !important;
        margin-left: 50px !important;
    }

    .navLink {
        display: block;
        padding: 0.5rem;
        padding-right: 0;
        padding-left: 0;

        color: $jcorp-main-white !important;
        font-family: Teko;
        font-style: normal !important;
        font-weight: 300;
        font-size: 24px;
        text-transform: uppercase;
        transition-duration: 0.4s;
    }

    .navLink:hover {
        color: $jcorp-main-black !important;
        cursor: pointer;
    }

    #navGroupCollapsed .socialIconsSmallContainer {
        padding-top: 40px;
    }

    #navGroupCollapsed .socialIconsSmallContainer .socialIcons {
        margin-right: 30px;
    }

    .egldCommunity {
        border-radius: 8px;
        border: none;
        width: 320px;
        height: 80px;
    }

    .homeInfosContainer {
        position: relative;
        display: block;
        margin-top: 70px;
    }

    .homeVersionBox {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 89%;
        /* identical to box height, or 11px */

        letter-spacing: 0.07em;
        text-transform: uppercase;

        color: $jcorp-main-white;

        position: absolute;
        top: 0;
        left: 0;
    }

    .homeTextInfos {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 89%;
        /* identical to box height, or 11px */

        letter-spacing: 0.07em;
        text-transform: uppercase;

        color: $jcorp-main-white;

        position: absolute;
        top: 0;
        right: 0;
    }

    .homeContainer {
        position: relative;
        display: block;
        margin-top: 390px;
    }

    .homeBox {
        position: relative;
        display: block;
    }

    .homeLogo {
        position: absolute;
        width: 360px;
        top: -47px;
        left: -3%;
    }

    .homeContainerTitle {
        position: relative;
        display: block;

        /* Headlines/T1 */
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 64px;
        line-height: 85%;
        /* or 88px */

        letter-spacing: -0.08em;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .homeContainerText {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 180%;
        /* or 36px */

        color: $jcorp-main-white;

        margin-top: 40px;
    }

    .homePictureBox {
        position: relative;
        display: block;

        margin-top: 80px;
        margin-bottom: -230px;

        left: calc(50% - 165px);
    }

    .videoContainer {
        position: relative;
        height: 340px;
        width: 110%;
        left: -5%;

        margin-top: 100px;

        background: $jcorp-main-red;
        border: none;

        clip-path: polygon(calc(100% - 6em) 0, calc(100% - 4em) 2em, 100% 2em, 100% 100%, calc(100% - 15em) 100%, calc(100% - 17em) calc(100% - 2em), 0 calc(100% - 2em), 0 100%, 0 100%, 0 0);
    }

    .videoContainerIn {
        position: absolute;
        top: 0.8px;
        left: 0;

        height: 338.4px;
        width: 100%;

        background: #18101B;

        clip-path: polygon(calc(100% - 6em) 0, calc(100% - 4em) 2em, 100% 2em, 100% 100%, calc(100% - 15em) 100%, calc(100% - 17em) calc(100% - 2em), 0 calc(100% - 2em), 0 100%, 0 100%, 0 0);
    }

    .videoContainer .shade1 {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 340px;

        background: linear-gradient(270deg, $jcorp-main-black 0%, rgba(36, 16, 44, 0) 20%);
        //border: 1px solid green;

        z-index: 5;
    }

    .videoContainer .shade2 {
        position: absolute;
        top: 0;
        right: 50%;
        width: 50%;
        height: 340px;

        background: linear-gradient(90deg, $jcorp-main-black 0%, rgba(36, 16, 44, 0) 20%);
        //border: 1px solid yellow;

        z-index: 5;
    }

    .videoContainer .videoBox {

        /* Neutral/Black */
        position: relative;
        display: block;
        width: 90%;
        height: 200px;

        top: 60px;
        left: 5%;

        background: #000000;
        /* Main/Red */
        border: 1px solid $jcorp-main-red;
        border-radius: 4px;

        z-index: 10;
    }

    .videoContainer .videoContent {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }

    .universeContainer {
        position: relative;
        display: block;
        margin-top: 150px;
    }

    .universePictureBox {
        position: relative;
        display: block;
    }

    .universePictureBoxA {
        position: relative;
        display: block;
        width: 310px;
        height: 310px;
        background: $jcorp-main-red !important;
        border-radius: 10px;
        border: none;
        box-shadow: none !important;

        clip-path: polygon(0 0, calc(100% - 2em) 0, 100% 2em, 100% 100%, 0 100%, 2em 100%, 0% calc(100% - 2em), 0 0%);
    }

    .universePictureBoxInA {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 308px;
        height: 308px;

        clip-path: polygon(0 0, calc(100% - 2em) 0, 100% 2em, 100% 100%, 0 100%, 2em 100%, 0% calc(100% - 2em), 0 0%);
    }

    .universePictureA {
        width: 308px;
        border-radius: 10px;
    }

    .universePictureBoxB {
        position: relative;
        display: block;
        top: -355px;
        margin-bottom: -165px;
        right: -120px;
        width: 225px;
        height: 169px;
        background: $jcorp-main-red !important;
        border-radius: 6px;
        border: none;
        box-shadow: none !important;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0.5em 100%, 0.5em 5em, 0 4.5em, 0 1em, 1em 0);
    }

    .universePictureBoxInB {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 223px;
        height: 167px;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0.5em 100%, 0.5em 4.85em, 0 4.35em, 0 1em, 1em 0);
    }

    .universePictureB {
        width: 223px;
        border-radius: 6px;
    }

    .universeBox {
        position: relative;
        display: block;
        margin-top: 60px;
    }

    .universeTitle {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 56px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .universeSub {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 89%;
        /* or 21px */

        margin-bottom: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-red;
    }

    .universeTextSmall {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        /* or 29px */

        width: 98%;
        text-align: left;

        margin-top: 40px;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .universeText {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 140%;
        /* or 29px */

        width: 98%;
        text-align: left;

        margin-top: 30px;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .factionTitleContainer {
        position: relative;
        display: block;
        text-align: center;
        margin-top: 100px;
    }

    .factionTitle {
        position: relative;
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 60px;
        line-height: 89%;
        /* or 71px */

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .factionTitle:last-child {
        display: inline-block;
        left: -12px;
    }

    .factionTitleSmall {
        position: relative;
        display: inline-block;
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 89%;
        /* or 71px */
        margin-left: 20px;
        left: -20px;
        top: 15px;
        /* Main/Red */
        color: $jcorp-main-red;

        vertical-align: top;
        transform: rotate(-5.96deg);
    }

    .factionContainer {
        position: relative;
        display: block;
    }

    .factionBox {
        position: relative;
        display: block;
        text-align: center;
        margin-top: 100px;
    }

    .factionName {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .factionSub {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 89%;
        /* or 21px */

        margin-top: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-red;
    }

    .factionText {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        /* or 29px */

        text-align: left;

        margin-top: 40px;
        padding-left: 38px;
        padding-right: 38px;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .gameContainer {
        position: relative;
        display: block;
        width: 110%;
        left: -5%;
        margin-top: 200px;

        z-index: 99;
    }

    .gameContainer .gameContainerSub {
        position: relative;
        display: block;
        width: 100%;
        margin-top: -500px;
        padding-top: 40px;
        padding-bottom: 100px;

        background: $jcorp-main-red;
        border: none;

        clip-path: polygon(calc(100% - 4em) 0, calc(100% - 2em) 2em, 100% 2em, 100% 100%, calc(100% - 7em) 100%, calc(100% - 9em) calc(100% - 2em), 0 calc(100% - 2em), 0 100%, 0 100%, 0 0);
    }

    .gameContainer .gameContainerSubBefore {
        content: "";
        position: absolute;
        width: 100%;
        height: calc(100% + 21px - 2em);

        top: -21px;
        right: 99%;

        background: $jcorp-main-red;
        transform: rotate(-180deg);
    }

    .gameContainer .gameContainerSubAfter {
        content: "";
        position: absolute;
        width: 100%;
        height: calc(100% + 21px - 2em);

        top: calc(-21px + 2em);
        left: 99%;

        background: $jcorp-main-red;
    }

    .gameContainer .gameBox {
        position: relative;
        display: block;
        padding-top: 370px;
        padding-left: 28px;
        padding-right: 28px;
    }

    .gameContainer .gameTitle {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 80px;
        line-height: 85%;
        /* or 88px */

        letter-spacing: -0.08em;
        text-transform: uppercase;

        color: $jcorp-main-black;
    }

    .gameContainer .gameSub {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 89%;
        /* or 21px */

        margin-top: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        color: $jcorp-main-white;
    }

    .gameContainer .gameText {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;

        width: 98%;
        text-align: left;

        margin-top: 40px;

        color: $jcorp-main-black;
    }

    .gameContainer .gameButtonBox {
        display: none;
    }

    .gameContainer .gameButtonBoxSmall {
        position: relative;
        display: block;
        padding-left: 28px;
    }

    .gameContainer .gamePictureBox {
        display: none;
    }

    .gameContainer .gamePictureBoxSmall {
        position: relative;
        display: block;

        z-index: 50;
    }

    .gamePictureBoxA {
        position: relative;
        display: block;
        top: -80px;
        left: 12px;
        width: 310px;
        height: 310px;
        background: $jcorp-main-red !important;
        border-radius: 10px;
        border: none;
        box-shadow: none !important;

        clip-path: polygon(calc(100% - 2em) 0, 100% 2em, 100% 7.5em, calc(100% - 1.5em) 9em, calc(100% - 1.5em) 100%, 0 100%, 2em 100%, 0 calc(100% - 2em), 0% 100%, 0 0);
    }

    .gamePictureBoxInA {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 308px;
        height: 308px;

        clip-path: polygon(calc(100% - 2em) 0, 100% 2em, 100% 7.5em, calc(100% - 1.5em) 9em, calc(100% - 1.5em) 100%, 0 100%, 2em 100%, 0 calc(100% - 2em), 0% 100%, 0 0);
    }

    .gamePictureA {
        width: 308px;
        border-radius: 10px;
    }

    .gamePictureBoxB {
        position: relative;
        display: block;
        top: -160px;
        right: -170px;
        width: 225px;
        height: 169px;
        background: $jcorp-main-red !important;
        border-radius: 6px;
        border: none;
        box-shadow: none !important;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0.5em 100%, 0.5em 5em, 0 4.5em, 0 1em, 1em 0);
    }

    .gamePictureBoxInB {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 223px;
        height: 167px;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0.5em 100%, 0.5em 4.85em, 0 4.35em, 0 1em, 1em 0);
    }

    .gamePictureB {
        width: 223px;
        border-radius: 6px;
    }

    .statsContainer {
        position: relative;
        display: block;

        width: 200%;
        left: -50%;
        top: -100px;

        margin-bottom: -100px;

        z-index: 1;
    }

    .statsContainer .statsContainerOut {
        position: relative;
        display: block;
        width: 100%;

        background: $jcorp-main-red;
        border: none;

        clip-path: polygon(100% 0, 100% 2em, 100% 2em, 100% 100%, calc(100% - 23em) 100%, calc(100% - 25em) calc(100% - 2em), 0 calc(100% - 2em), 0 100%, 0 100%, 0 0);
    }

    .statsContainer .statsContainerIn {
        position: relative;
        display: block;
        width: 100%;

        background: #18101B;

        clip-path: polygon(100% 0, 100% 2em, 100% 2em, 100% calc(100% - 1px), calc(100% - 23em + 0.5px) calc(100% - 1px), calc(100% - 25em + 0.5px) calc(100% - 2em - 1px), 0 calc(100% - 2em - 1px), 0 100%, 0 100%, 0 0);
    }

    .statsContainer .statsBox {
        position: relative;
        display: block;

        padding-top: 120px;
        padding-bottom: 100px;
    }

    .statsContainer .statsInfos {
        position: relative;
        display: block;

        text-align: center;
        margin-top: 40px;
    }

    .statsContainer .statsInfosValue {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 300;
        font-size: 72px;
        line-height: 85%;
        /* or 61px */

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .statsContainer .statsInfosTitle {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 89%;
        /* or 21px */

        letter-spacing: 0.07em;
        text-transform: uppercase;

        margin-top: 15px;

        /* Neutral/White */
        color: $jcorp-main-white;
    }

    .infosContainer {
        position: relative;
        display: block;
        margin-top: 120px;
    }

    .infosBox {
        position: relative;
        display: block;
    }

    .infosBoxModal {
        position: relative;
        display: inline-block;
    }

    .infosBoxModalSub {
        position: absolute;
        display: inline-block;
        top: -5px;
        right: 0;
    }

    .infosTitle {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .infosFactionTitle {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;
        text-align: center;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .infosBalance {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .infosSub {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 89%;
        /* or 21px */

        margin-bottom: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-red;
    }

    .infosSubTitle {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 89%;
        /* or 21px */

        margin-bottom: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-red;
    }

    .infosIconContainer {
        display: inline-block;
        vertical-align: text-top;
        margin-left: 15px;
    }

    .infosIconContainer:hover {
        cursor: pointer;
    }

    .infosIcon {
        width: 32px;
    }

    .infosModalTitle {
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 89%;
        /* or 21px */

        margin-bottom: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-red;
    }

    .infosModalTx {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 89%;
        /* or 21px */

        margin-bottom: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-white;
    }

    .infosBoxSub {
        position: relative;
        display: block;
    }

    .infosBoxSubSpecial {
        position: relative;
        display: block;
    }

    .infosBoxAccount {
        position: relative;
        display: block;
        top: -10px;
    }

    .infosText {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 180%;
        /* or 29px */

        width: 98%;
        text-align: left;

        margin-top: 40px;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .homeROFLogoContainer {
        position: relative;
        display: block;
        margin-top: 120px;
    }

    .homeROFLogo {
        position: relative;
        display: block;
        left: -3%;
        width: 105%;
    }

    .swiper {
        position: relative;
        display: flex;
        margin-top: 40px;
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
        position: relative;
        display: block;
        width: 300px !important;
    }

    .swiper-pagination-bullet-active {
        background-color: $jcorp-main-red !important;
    }

    .roadmapContainer1 {
        position: relative;
        display: flex;
        margin-top: 50px;
        margin-bottom: -15px;
        padding-bottom: 15px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .roadmapCardContainer1 {
        position: relative;
        display: block;
        margin-left: 15px;
        margin-right: 15px;
    }

    .cardRoadmapBox {
        position: relative;
        display: block;

        width: 300px;
        height: 525px;

        background: $jcorp-main-red;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .cardRoadmapBoxIn {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 298px;
        height: 523px;

        background: #18101B;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .cardRoadmapPictureBox {
        position: relative;
        width: 100%;
        max-height: 295px;
        overflow: hidden;
    }

    .cardRoadmapPicture {
        position: relative;
        width: 100%;
    }

    .cardRoadmapInfos {
        position: relative;
        display: block;

        margin: 40px;
        margin-top: 25px;
    }

    .cardRoadmapPhase {
        font-family: Teko;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        /* identical to box height, or 24px */

        text-transform: uppercase;

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .cardRoadmapTitle {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 85%;
        /* or 20px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        margin-top: 18px;

        /* Main/Red */
        color: $jcorp-main-white;
    }

    .cardRoadmapText {
        font-family: 'Rajdhani';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;

        margin-top: 18px;
        text-align: left;

        /* Main/Red */
        color: $jcorp-main-white;
    }

    .teamContainer {
        position: relative;
        display: block;
        margin-top: 80px;
    }

    .partnersContainer {
        position: relative;
        display: block;
        margin-top: 30px;
        width: 110%;
    }

    .partnersBox {
        position: relative;
        display: inline-block;
        margin-top: 30px;
        margin-right: 30px;
        width: 175px;
        text-align: center;

        font-family: Rajdhani;
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        line-height: 180%;
        /* or 43px */
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .partnersBox .partnersInfos {
        margin-top: 25px;
        margin-bottom: 15px;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 89%;
        /* or 21px */

        letter-spacing: 0.07em;
        /* or 43px */

        /* Main/Light */
        color: $jcorp-main-red;
    }

    .FAQContainer {
        position: relative;
        display: block;
        margin-top: 60px;
    }

    .FAQBox {
        position: relative;
        display: block;
        margin-top: 15px;
        padding: 24px 32px;

        /* Tint/Purple */
        background: #18101B;
        /* Main/Red */

        border: 1px solid $jcorp-main-red;
        border-radius: 4px;
    }

    .FAQBoxQuestion {
        position: relative;
        display: block;

        cursor: pointer;
    }

    .FAQBoxText {
        position: relative;
        display: inline-block;

        width: 90%;

        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 180%;
        /* identical to box height, or 45px */


        /* Main/Light */
        color: $jcorp-main-white;
    }

    .FAQBoxIcon {
        position: absolute;
        display: inline-block;

        width: 10%;
        text-align: center;
        font-size: 16px;

        top: 50%;
        transform: translateY(-50%);

        color: $jcorp-main-red;
    }

    .FAQBoxInfos {
        position: relative;
        display: block;

        margin-top: 20px;
        width: 100%;

        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        /* or 43px */


        /* Main/Light */
        color: $jcorp-main-white;
    }

    .FAQBoxInfos a {
        /* Main/Light */
        color: $jcorp-main-red;
        font-weight: 900;
    }

    .FAQBoxInfos a:hover {
        /* Main/Light */
        color: rgba($jcorp-main-red, 0.6);
    }

    .cardContainer {
        position: relative;
        display: block;
        width: 177px;
        height: 177px;
    }

    .cardContainerHome1 {
        position: relative;
        display: block;
        width: 177px;
        height: 177px;

        top: 0;
        left: 130px;

        z-index: 99;
    }

    .cardContainerHome2 {
        position: relative;
        display: block;
        width: 177px;
        height: 177px;

        top: -20px;
        right: -160px;

        z-index: 90;
    }

    .cardContainerHome3 {
        position: relative;
        display: block;
        width: 177px;
        height: 177px;

        top: -290px;
        left: 0;

        z-index: 95;
    }

    .cardBox {
        position: relative;
        display: block;

        width: 177px;
        height: 177px;

        background: $jcorp-main-red;
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .cardBoxIn {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 175px;
        height: 175px;

        background: #18101B;
        border-radius: 3px;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .cardInfos {
        position: absolute;
        display: block;
        text-align: left;

        bottom: -5px;
        left: 18px;
    }

    .cardHierarchy {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        /* or 24px */

        text-transform: uppercase;

        /* Main/Red */
        color: $jcorp-main-white;
    }

    .cardPower {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        /* or 20px */

        margin-top: -8px;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .cardInfosBis {
        position: absolute;
        display: block;

        bottom: 8px;
        right: 15px;
    }

    .cardNumber {
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 100%;
        /* or 24px */

        text-transform: uppercase;
        text-align: center;

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .cardName {
        position: relative;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        /* or 24px */

        text-transform: uppercase;
        text-align: center;
        top: -3px;

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .cardGamePictureBox {
        position: absolute;
        top: -20px;
        left: 9px;

        width: 159px;
        height: 159px;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .cardGamePicture {
        width: 159px;
        border-radius: 3px;
    }

    .factionCardContainer {
        position: relative;
        display: block;
        margin-top: 80px;
        left: calc(50% - 137.5px);
    }

    .teamCardContainer {
        position: relative;
        display: block;
        margin-top: 100px;
        left: calc(50% - 137.5px);
    }

    .buttonsNFTContainer {
        position: relative;
        display: block;
        text-align: center;

        margin-top: 40px;
    }

    .buttonsNFTContainerBis {
        position: relative;
        display: block;
        text-align: center;
    }

    .historyContainer {
        position: relative;
        display: block;
        text-align: center;

        margin-top: 40px;
    }

    .historySelection {
        position: relative;
        display: block;
        margin-top: 10px;
    }

    .phaseContainer {
        position: relative;
        display: block;
        text-align: center;

        margin-top: 40px;
    }

    .pageTitle {
        position: relative;
        display: block;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 89%;
        /* or 71px */

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .pageTitleMaintenance {
        position: relative;
        display: block;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 89%;
        /* or 71px */

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .infosConquestContainer {
        position: relative;
        display: block;
    }

    .conquestTitleBox {
        position: relative;
        display: block;

        padding-bottom: 54px;
    }

    .conquestTitle {
        position: absolute;
        display: block;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 70px;
        line-height: 89%;
        /* or 71px */

        transform: rotate(-8deg);

        /* Main/Red */
        color: $jcorp-main-red;

        z-index: 2;
    }

    .conquestNumber {
        position: absolute;
        display: block;

        top: 26px;
        left: 236px;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */

        color: $jcorp-main-white;

        z-index: 1;
    }

    .conquestSub {
        position: absolute;
        display: block;

        top: 45px;
        left: 151px;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */

        color: $jcorp-main-white;

        z-index: 1;
    }

    .phaseTitle {
        position: relative;
        display: block;
        text-align: left;

        left: 40px;
        margin-top: 16px;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 54px;
        line-height: 89%;
        /* or 71px */

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .infosConquestBox {
        position: relative;
        display: block;
        width: 100%;
    }

    .infosTimerContainer1 {
        position: relative;
        display: block;
        text-align: right;
        right: 25px;

        margin-top: 30px;
    }

    .infosTimerContainer {
        position: relative;
        display: block;

        margin-top: 30px;
        padding-bottom: 30px;
    }

    .infosTimerTitle {
        position: absolute;
        display: block;
        text-align: left;

        width: 170px;
        right: 0;
    }

    .infosTimerArena {
        position: absolute;
        display: block;
        text-align: left;

        width: 150px;
        right: 0;
        top: 40px;
    }

    .infosTimerBox {
        position: absolute;
        display: block;
        text-align: left;

        width: 150px;
        right: 0;
    }

    .timerNumber {
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .timerSep {
        font-family: Archivo;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 85%;
        /* or 41px */

        margin-left: 6px;
        margin-right: 6px;

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .infosFactionContainer {
        position: relative;
        display: block;

        margin-top: 40px;
    }

    .infosFactionImgContainer {
        position: relative;
        display: block;

        margin-top: 40px;
    }

    .infosClaim {
        position: relative;
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        margin: auto;
        width: 300px;

        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;
        text-align: center;

        /* Main/Light */
        color: $jcorp-main-white;

        background: rgba($jcorp-main-white, 0.2);
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 0.8em) 0, 100% 0.8em, 100% 100%, 0 100%, 0.8em 100%, 0% calc(100% - 0.8em), 0 0%);
    }

    .infosWallet {
        position: relative;
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        margin: auto;
        width: 240px;

        font-family: Rajdhani;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 120%;
        /* or 41px */

        text-align: center;

        /* Main/Light */
        color: $jcorp-main-white;

        background: rgba($jcorp-main-white, 0.2);
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 0.8em) 0, 100% 0.8em, 100% 100%, 0 100%, 0.8em 100%, 0% calc(100% - 0.8em), 0 0%);
    }

    .walletContainer {
        position: relative;
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;

        background: rgba($jcorp-main-white, 0.2);
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .walletText {
        position: relative;
        display: block;
        text-align: center;
    }

    .walletButton {
        position: relative;
        display: block;
        text-align: center;
        margin-top: 10px;
    }

    .squadContainer {
        position: relative;
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;

        background: rgba($jcorp-main-white, 0.2);
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .squadInfosContainer {
        position: relative;
        display: block;
        margin-left: 10px;
        margin-right: 10px;
    }

    .squadTitle {
        position: relative;
        display: block;
    }

    .squadInfos {
        position: relative;
        display: block;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .squadInfosModal {
        position: relative;
        display: block;
        margin-top: 20px;
    }

    .squadIcon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    .squadTextContainer {
        position: relative;
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle;
        width: calc(100% - 80px);
    }

    .squadText {
        position: relative;
        display: block;

        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .squadTextModal {
        position: relative;
        display: block;

        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .actionContainer {
        position: relative;
        display: block;
        text-align: center;
        margin-top: 20px;
    }

    .actionIcon {
        position: relative;
        display: inline-block;
        margin-right: 20px;
        margin-left: 20px;
        font-size: 20px;
        color: $jcorp-main-white;
    }

    .actionIcon:hover {
        color: $jcorp-main-red;
        cursor: pointer;
    }

    .conquestSquadContainer {
        position: relative;
        display: block;
        margin-bottom: 10px;

        background: rgba($jcorp-main-white, 0.2);
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .conquestSquadInfosContainer {
        position: relative;
        display: block;
        padding: 10px;
    }

    .conquestSquadInfosContainer .overlayHover {
        display: none;
    }

    .conquestSquadInfosContainer:hover .overlayHover {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 47px;
        border-radius: 4px;
        background-color: rgba($jcorp-main-white, 0.7);
        cursor: pointer;

        z-index: 90;
    }

    .conquestSquadInfosContainer:hover .overlayHoverText {
        position: relative;
        display: block;
        text-align: center;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 100%;
        /* or 24px */

        text-transform: uppercase;
        color: $jcorp-main-red;
    }

    .conquestSquadTitle {
        position: relative;
        display: block;
    }

    .conquestSquadInfos {
        position: relative;
        display: block;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .conquestSquadIcon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    .conquestSquadTextContainer {
        position: relative;
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle;
    }

    .conquestSquadText {
        position: relative;
        display: block;

        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .tokenLogoContainer {
        margin-top: 10px;
        margin-left: 25px;
        text-align: center;
        position: absolute;
        top: 0px;
        left: -14px;
        background: #fff;
        border: 3px solid $jcorp-main-black;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: 0px 1px 5px $jcorp-main-black;
    }

    .deployBoxTitle {
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        /* or 24px */

        text-transform: uppercase;
        color: $jcorp-main-white;
    }

    .tokenLogo {
        padding-top: 2px;
        width: 30px;
    }

    .tokenIcon {
        margin-top: 4px;
        color: #ced4da;
    }

    .walletForm {
        position: relative;
        display: block;
        text-align: center;
        width: 300px;
        margin: auto;
        margin-top: 15px;
    }

    .formLabel {
        margin-left: 5px;
    }

    .walletFormButtons {
        position: relative;
        display: block;
        text-align: right;
        top: -5px;
        right: -3px;
    }

    .dataContainer {
        position: relative;
        display: block;
    }

    .dataBox {
        position: relative;
        display: block;
        margin-top: 50px;
    }

    .dataBox:first-child {
        margin-top: 0px;
    }

    .dataSepartorLine {
        position: relative;
        display: block;
        text-align: left;
        border-bottom: 1px solid rgba($jcorp-main-red, 0.3);
    }

    .dataTitle {
        display: inline-block;

        font-family: Archivo;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 180%;
        /* or 20px */

        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .dataAttribut {
        position: absolute;
        right: 0;

        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 180%;
        /* or 20px */

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .factionsContainer {
        position: relative;
        display: block;
        text-align: center;
        width: 110%;
        left: -5%;
    }

    .factionsInfos {
        position: relative;
        display: inline-block;
        text-align: center;
        width: 105px;
        margin: 5px;

        font-family: Teko;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        /* or 20px */

        /* Main/Light */
        color: $jcorp-main-white;

        border-bottom: 3px solid rgba(var(--factionColor), 1);
    }

    .mapInfosContainerRight {
        position: absolute;
        display: block;
        right: 5px;
        top: 0;

        z-index: 99;
    }

    .mapInfosContainerLeft {
        position: absolute;
        display: block;
        left: 5px;
        top: 0;

        z-index: 99;
    }

    .mapBoxIconContainer {
        position: relative;
        display: block;
        margin-top: 10px;
    }

    .mapBoxIconContainer:first-child {
        margin-top: 5px;
    }

    .mapBoxIcon {
        position: relative;
        text-align: center;
        width: 30px;
        height: 30px;
        padding: 4px;

        color: $jcorp-main-white;
        background-color: rgba($jcorp-main-red, 0.8);
        box-shadow: 0px 1px 5px $jcorp-main-black;
        border-radius: 3px !important;
        border: none;
    }

    .mapBoxIcon:hover {
        background-color: rgba($jcorp-main-red, 1);
        cursor: pointer;
    }

    .mapBoxIconFa {
        position: relative;
        top: 1px;
        font-size: 20px;
    }

    .factionsContainer1 {
        position: absolute;
        display: block;
        left: 0;
        bottom: 0;

        z-index: 99;
    }

    .factionsInfos1 {
        position: relative;
        display: inline-block;
        text-align: center;
        margin: 5px;

        font-family: Teko;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 100%;
        /* or 20px */

        /* Main/Light */
        color: $jcorp-main-white;

        border-bottom: 3px solid rgba(var(--factionColor), 1);
    }

    .mapImperial {
        position: absolute;
        top: calc(var(--mapHeight) * 0.04);
        left: calc(var(--mapWidth) * 0.75);

        z-index: 99;
    }

    .mapInn {
        position: absolute;
        top: calc(var(--mapHeight) * 0.42);
        left: calc(var(--mapWidth) * 0.54);

        z-index: 99;
    }

    .mapTemple {
        position: absolute;
        top: calc(var(--mapHeight) * 0.32);
        left: calc(var(--mapWidth) * 0.42);

        z-index: 99;
    }

    .mapMarket {
        position: absolute;
        top: calc(var(--mapHeight) * 0.77);
        left: calc(var(--mapWidth) * 0.25);

        z-index: 99;
    }

    .mapJoy {
        position: absolute;
        top: calc(var(--mapHeight) * 0.46);
        left: calc(var(--mapWidth) * 0.26);

        z-index: 99;
    }

    .mapPort {
        position: absolute;
        top: calc(var(--mapHeight) * 0.70);
        left: calc(var(--mapWidth) * 0.56);

        z-index: 99;
    }

    .mapSamurai {
        position: absolute;
        top: calc(var(--mapHeight) * 0.33);
        right: calc(var(--mapWidth) * 0.78);

        z-index: 99;
    }

    .mapNinja {
        position: absolute;
        top: calc(var(--mapHeight) * 0.25);
        left: calc(var(--mapWidth) * 0.75);

        z-index: 99;
    }

    .mapInfosIcon {
        width: 16px;
        padding: 1px;

        color: $jcorp-main-white;
        background: rgba($jcorp-main-red, 0.8);
        border-radius: 2px !important;
        border: none;

        cursor: pointer;
    }

    .empImperial {
        position: absolute;
        top: calc(var(--mapHeight) * 0.07);
        left: calc(var(--mapWidth) * 0.57);

        z-index: 99;
    }

    .empInn {
        position: absolute;
        top: calc(var(--mapHeight) * 0.55);
        left: calc(var(--mapWidth) * 0.53);

        z-index: 99;
    }

    .empTemple {
        position: absolute;
        top: calc(var(--mapHeight) * 0.47);
        left: calc(var(--mapWidth) * 0.40);

        z-index: 99;
    }

    .empMarket {
        position: absolute;
        top: calc(var(--mapHeight) * 0.75);
        left: calc(var(--mapWidth) * 0.35);

        z-index: 99;
    }

    .empJoy {
        position: absolute;
        top: calc(var(--mapHeight) * 0.60);
        left: calc(var(--mapWidth) * 0.24);

        z-index: 99;
    }

    .empPort {
        position: absolute;
        top: calc(var(--mapHeight) * 0.58);
        left: calc(var(--mapWidth) * 0.63);

        z-index: 99;
    }

    .empSamurai {
        position: absolute;
        top: calc(var(--mapHeight) * 0.39);
        right: calc(var(--mapWidth) * 0.78);

        z-index: 99;
    }

    .empNinja {
        position: absolute;
        top: calc(var(--mapHeight) * 0.18);
        left: calc(var(--mapWidth) * 0.75);

        z-index: 99;
    }

    .empInfosIcon {
        width: 16px;
        padding: 2px;

        color: $jcorp-main-white;
        background: rgba($jcorp-main-black, 0.9);
        border-radius: 2px !important;
        border: none;

        cursor: pointer;
    }

    .battleInfosContainer {
        position: relative;
        display: block;
        width: 100%;
        margin-top: 80px;

        z-index: 99;
    }

    .battleInfosContainer .battleInfosContainerSub {
        position: relative;
        display: block;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 60px;

        background: $jcorp-main-red;
        border: none;

        clip-path: polygon(calc(100% - 4em) 0, calc(100% - 2em) 2em, 100% 2em, 100% 100%, calc(100% - 7em) 100%, calc(100% - 9em) calc(100% - 2em), 0 calc(100% - 2em), 0 100%, 0 100%, 0 0);
    }

    .battleInfosContainer .battleInfosContainerSubBefore {
        content: "";
        position: absolute;
        width: 100%;
        height: calc(100% - 2em);

        top: 0px;
        right: 99%;

        background: $jcorp-main-red;
        transform: rotate(-180deg);
    }

    .battleInfosContainer .battleInfosContainerSubAfter1 {
        content: "";
        position: absolute;
        width: 100%;
        height: calc(100% - 2em);

        top: calc(2em);
        left: 99%;

        background: $jcorp-main-red;
    }

    .arenaRegistrationInfosContainer {
        position: relative;
        display: block;
        margin: auto;
        width: 85%;
    }

    .arenaRegistrationInfosPictureBox {
        position: absolute;
        top: -20px;
        left: -25px;

        z-index: 99;
    }

    .arenaRegistrationInfosPictureBox .arenaRegistrationInfosPicture {
        width: 67px;
    }

    .arenaRegistrationInfosBox {
        position: relative;
        display: block;

        background: $jcorp-main-red;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 2em) 0, 100% 2em, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaRegistrationInfosBoxIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: linear-gradient(284.72deg, #9F0836 13.28%, #620C31 96.53%);
        border-radius: 3px;

        clip-path: polygon(0 0, calc(100% - 2em) 0, 100% 2em, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaRegistrationTextBox {
        text-align: center;
        padding-top: 30px;
        padding-bottom: 25px;
    }

    .arenaRegistrationTextBox .arenaRegistrationText {
        font-family: Rajdhani;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
    }

    .arenaRegistrationTextBox .arenaRegistrationTextBig {
        font-family: Rajdhani;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
    }

    .arenaRegistrationInfosBoxIn2 {
        position: relative;
        display: inline-block;

        width: 100%;

        border-top: 1px solid $jcorp-main-red;

        background: rgba($jcorp-main-red, 0.4);
    }

    .arenaRegistrationTextBox2 {
        text-align: center;
        padding: 10px;
    }

    .arenaRegistrationTextBox2 .arenaRegistrationText {
        font-family: Rajdhani;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
    }

    .arenaResultContainer2 {
        position: relative;
        display: block;
        margin-bottom: 50px;
    }

    .arenaResultBox {
        position: relative;
        display: block;

        background: $jcorp-main-red;
        border-radius: 5px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultBoxIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: #18101B;
        border-radius: 5px;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultBoxIn:hover {
        background: rgba(#18101B, 0.9);
        cursor: pointer;
    }

    .arenaResultBoxIn2 {
        position: relative;
        display: inline-block;

        width: 100%;

        background: linear-gradient(0deg, rgba(#18101B, 0) 0%, rgba($jcorp-main-red, 0.2448) 100%);

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultBoxInText2 {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        /* Main/Red */
        color: $jcorp-main-white;
    }

    .arenaResultBoxInfos {
        position: relative;
        display: block;
        width: 100%;
        padding: 10px;
    }

    .arenaResultBoxInfosLeft {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 40%;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .arenaResultBoxInfosCenter {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 20%;

        display: inline-block;
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 89%;
        /* or 71px */

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .arenaResultBoxInfosRight {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 40%;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .arenaResultSeasonBox {
        position: absolute;
        display: block;
        top: -22px;
        left: calc(50% - 75px);
    }

    .arenaResultSeason {
        position: relative;
        display: block;

        width: 150px;
        height: 50px;

        background: $jcorp-main-white;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultSeasonIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(-284.72deg, #e9e7d5 13.28%, #afaea6 96.53%);

        clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultSeasonText {
        margin: 3px;
        text-align: center;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-black;
    }

    .arenaResultLeft {
        position: absolute;
        display: block;
        bottom: -10px;
        left: calc(20% - 45px);
    }

    .arenaResultText {
        margin: 3px;
        text-align: center;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;
    }

    .arenaResultLeftPending {
        position: relative;
        display: block;

        width: 90px;
        height: 40px;

        color: #484a4d;
        background: #484a4d;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultLeftPendingIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(-284.72deg, #c2cad3 13.28%, #8b9096 96.53%);

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultLeftWinner {
        position: relative;
        display: block;

        width: 90px;
        height: 40px;

        color: $jcorp-main-green;
        background: $jcorp-main-green;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultLeftWinnerIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(-284.72deg, #0d9e69 13.28%, #0c7750 96.53%);

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultLeftLoser {
        position: relative;
        display: block;

        width: 90px;
        height: 40px;

        color: $jcorp-main-red;
        background: $jcorp-main-red;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultLeftLoserIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(-284.72deg, #9F0836 13.28%, #620C31 96.53%);

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultRight {
        position: absolute;
        display: block;
        bottom: -10px;
        right: calc(20% - 45px);
    }

    .arenaResultRightPending {
        position: relative;
        display: block;

        width: 90px;
        height: 40px;

        color: #484a4d;
        background: #484a4d;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultRightPendingIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(284.72deg, #c2cad3 13.28%, #8b9096 96.53%);

        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultRightWinner {
        position: relative;
        display: block;

        width: 90px;
        height: 40px;

        color: $jcorp-main-green;
        background: $jcorp-main-green;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultRightWinnerIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(284.72deg, #0d9e69 13.28%, #0c7750 96.53%);

        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultRightLoser {
        position: relative;
        display: block;

        width: 90px;
        height: 40px;

        color: $jcorp-main-red;
        background: $jcorp-main-red;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultRightLoserIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(284.72deg, #9F0836 13.28%, #620C31 96.53%);

        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .roundCardLifeText {
        position: relative;
        display: inline-block;
        top: 2px;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 100%;
        /* or 20px */

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */
        color: $jcorp-main-white;
    }

    .roundCardLifeTextSep {
        position: relative;
        display: inline-block;
        top: -1px;
        font-size: 18px;
    }

    .roundCardLeftLifeIcon {
        position: absolute;
        display: block;
        top: -11px;
        left: -10px;
        color: $jcorp-main-red;
        font-size: 28px;
    }

    .roundCardRightLifeIcon {
        position: absolute;
        top: -11px;
        right: -10px;
        color: $jcorp-main-red;
        font-size: 28px;
    }

    .stripContainerLeft {
        position: relative;
        display: block;
    }

    .stripBoxLeft {
        position: relative;
        width: 75vw;
        height: 55px;

        /* Main/Red */
        background: $jcorp-main-red;

        z-index: 1;
    }

    .stripBoxLeft::before {
        content: "";
        position: absolute;
        width: 100vw;
        height: 55px;
        top: 0;
        right: 0;

        /* Main/Red */
        background: $jcorp-main-red;

        z-index: 1;
    }

    .stripBoxLeft::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 55px;
        top: 0;
        right: -30px;

        /* Main/Red */
        background: $jcorp-main-red;
        border-top-right-radius: 2px;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 1.5em), calc(100% - 1.5em) 100%, 0% 100%, 0% 100%, 0 0%);

        z-index: 1;
    }

    .stripBoxLeft .stripBoxLeftRank {
        position: absolute;
        display: block;
        top: -28px;
        right: 0;
    }

    .stripBoxLeft .stripInfos {
        position: relative;
        display: block;

        width: 150px;
        height: 50px;

        background: $jcorp-main-white;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .stripBoxLeft .stripInfosIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(-284.72deg, #e9e7d5 13.28%, #afaea6 96.53%);

        clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .stripBoxLeft .stripInfosText {
        margin: 3px;
        text-align: center;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-black;
    }

    .stripBoxLeft .stripBoxLeftList {
        position: absolute;
        display: block;

        top: 8px;
        right: 30px;

        z-index: 2;
    }

    .stripBoxLeft .stripBoxLeftSearchBox {
        position: absolute;
        display: block;

        top: 3px;
        right: 0;

        z-index: 2;
    }

    .stripBoxLeft .stripBoxLeftBtnSearchBox {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        top: -20px;
    }

    .stripBoxLeft .stripBoxLeftBtnSearch {
        position: relative;
        display: block;

        width: 48px;
        height: 48px;

        background: $jcorp-main-red;
        border-radius: 2px;
        border: none;

        transition-duration: 0.4s;

        clip-path: polygon(0 0, calc(100% - 0.5em) 0, 100% 0.5em, 100% 100%, 0 100%, 0.5em 100%, 0% calc(100% - 0.5em), 0 0%);
    }

    .stripBoxLeft .stripBoxLeftBtnSearch:hover {
        background: $jcorp-main-black;
        cursor: pointer;
    }

    .stripBoxLeft .stripBoxLeftBtnSearchIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: $jcorp-main-black;
        border-radius: 2px;
        border: none;

        transition-duration: 0.4s;

        clip-path: polygon(0 0, calc(100% - 0.45em) 0, 100% 0.45em, 100% 100%, 0 100%, 0.45em 100%, 0% calc(100% - 0.45em), 0 0%);
    }

    .stripBoxLeft .stripBoxLeftBtnSearchIn:hover {
        background: $jcorp-main-red;
    }

    .stripBoxLeft .stripBoxLeftBtnSearchIn:active {
        background: rgba($jcorp-main-red, 0.6);
        transition-duration: 0s;
    }

    .stripBoxLeft .stripBoxLeftIconSearchBox {
        position: absolute;
        top: 9px;
        left: 9px;
    }

    .stripBoxLeft .stripBoxLeftIconSearch {
        font-size: 28px;
    }

    .stripBoxLeft .stripBoxLeftSearch {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 190px;
    }

    .stripBoxLeft .stripBoxLeftTimerBox {
        position: absolute;
        display: block;

        top: 15px;
        right: 0;

        z-index: 2;
    }

    .stripBoxLeft .stripBoxLeftTimer {
        position: relative;
        display: block;
        width: 210px;
    }

    .stripBoxLeft .infosTimerText {
        position: relative;
        display: inline-block;
        margin-right: 20px;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-white;
    }

    .stripBoxLeft .infosTimerTimer {
        position: relative;
        display: inline-block;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-black;
    }

    .stripBoxLeft .stripBoxLeftBtn {
        position: absolute;
        display: block;

        top: 10px;
        right: 40px;

        z-index: 2;
    }

    .stripBoxLeft .stripBtn {
        position: relative;
        display: block;
        width: 160px;
        height: 58px;

        background: $jcorp-main-red;
        border-radius: 2px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .stripBoxLeft .stripBtn:hover {
        background: $jcorp-main-black;
    }

    .stripBoxLeft .stripBtnIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: $jcorp-main-black;
        border-radius: 2px;

        transition-duration: 0.4s;

        clip-path: polygon(0 0, calc(100% - 1.45em) 0, 100% 1.45em, 100% 100%, 0 100%, 1.45em 100%, 0% calc(100% - 1.45em), 0 0%);
    }

    .stripBoxLeft .stripBtnIn:hover {
        background: $jcorp-main-red;
        cursor: pointer;
    }

    .stripBoxLeft .stripBtnIn:active {
        background: rgba($jcorp-main-red, 0.6);
        transition-duration: 0s;
    }

    .stripBoxLeft .stripBtnText {
        text-align: center;

        /* Button/Default */
        font-family: Teko;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;

        padding-top: 17px;
    }

    .stripContainerRight {
        position: relative;
        display: block;
        height: 80px;
    }

    .stripBoxRight {
        position: absolute;
        width: 60vw;
        height: 55px;

        top: 0;
        right: 0;

        /* Main/Red */
        background: $jcorp-main-red;

        z-index: 1;
    }

    .stripBoxRight::before {
        content: "";
        position: absolute;
        width: 100vw;
        height: 55px;
        top: 0;
        left: 0;

        /* Main/Red */
        background: $jcorp-main-red;

        z-index: 1;
    }

    .stripBoxRight::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 55px;
        top: 0;
        left: -30px;

        /* Main/Red */
        background: $jcorp-main-red;
        border-top-right-radius: 2px;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 1.5em), calc(100% - 1.5em) 100%, 0% 100%, 0% 100%, 0 0%);

        z-index: 1;
        transform: rotateY(180deg);
    }

    .stripBoxRight .stripBoxRightRank {
        position: absolute;
        display: block;
        top: -28px;
        left: 0;
    }

    .stripBoxRight .stripBoxRightList {
        position: absolute;
        display: block;

        top: 8px;
        left: 30px;

        z-index: 2;
    }

    .stripBoxRight .stripInfos {
        position: relative;
        display: block;

        width: 150px;
        height: 50px;

        background: $jcorp-main-white;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .stripBoxRight .stripInfosIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(-284.72deg, #e9e7d5 13.28%, #afaea6 96.53%);

        clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .stripBoxRight .stripInfosText {
        margin: 3px;
        text-align: center;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-black;
    }

    .stripBoxRight .stripBoxRightBtn {
        position: absolute;
        display: block;

        top: 10px;
        left: 40px;

        z-index: 2;
    }

    .stripBoxRight .stripBtn {
        position: relative;
        display: block;
        width: 160px;
        height: 58px;

        background: $jcorp-main-red;
        border-radius: 2px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);

        transform: rotateY(180deg);
    }

    .stripBoxRight .stripBtn:hover {
        background: $jcorp-main-black;
    }

    .stripBoxRight .stripBtnIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: $jcorp-main-black;
        border-radius: 2px;

        transition-duration: 0.4s;

        clip-path: polygon(0 0, calc(100% - 1.45em) 0, 100% 1.45em, 100% 100%, 0 100%, 1.45em 100%, 0% calc(100% - 1.45em), 0 0%);
    }

    .stripBoxRight .stripBtnIn:hover {
        background: $jcorp-main-red;
        cursor: pointer;
    }

    .stripBoxRight .stripBtnIn:active {
        background: rgba($jcorp-main-red, 0.6);
        transition-duration: 0s;
    }

    .stripBoxRight .stripBtnText {
        text-align: center;

        /* Button/Default */
        font-family: Teko;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;

        padding-top: 17px;

        transform: rotateY(180deg);
    }

    #footerNavBar {
        position: relative;
        display: block;
        width: 100%;
        margin-top: 100px;
        padding-bottom: 80px;

        /* Main/Red */
        background: $jcorp-main-red;

        clip-path: polygon(3.5em 2em, calc(100% - 7em) 2em, calc(100% - 5em) 0, 100% 0, 100% 100%, 0 100%, 0 0, 1.5em 0);
    }

    #footerNavBar .footerContent {
        position: relative;
        display: block;

        margin-top: 50px;

        text-align: center;
    }

    #footerNavBar .logoNav {
        position: relative;
        display: block;
        margin: auto;
        width: 220px;
        padding-top: 20px;
        margin-bottom: 40px;
    }

    #footerNavBar .footerNav {
        position: relative;
    }

    #footerNavBar .footerNavElement {
        position: relative;
        display: block;

        margin-top: 10px;
    }

    #footerNavBar .socialIconsContainer {
        display: none;
    }

    #footerNavBar .socialIconsSmallContainer {
        padding-top: 30px;
    }

    #footerNavBar .socialIconsSmallContainer .socialIcons {
        position: relative;
        left: 15px;
        margin-right: 30px;
    }

    .buttonsContainer {
        text-align: center;
        margin-top: 30px;
    }

    .socialIcons {
        margin-left: 10px;
    }

    .socialIconsIn {
        width: 20px;
        height: 20px;
    }

    .tabsWrapper {
        display: none;
    }

    .tabsWrapperInMobile {
        display: block;

        .tabsWrapperContainerMobile {
            display: flex;
            align-items: center;
            gap: 6px;
            list-style: none;
            border-bottom: 1px solid #695a5e;
            width: 100%;
            padding: 0;

            li {
                padding: 16px 8px 6px 8px;
                border-bottom: 4px solid transparent;
                font-family: Teko;
                font-size: 18px;
                font-weight: 300;
                text-transform: uppercase;
                color: $jcorp-main-white;

                &.tabsWrapperContainerItemsActive {
                    border-color: $jcorp-main-red;
                }
            }
        }
    }

    .contentMenuShow {
        display: block;
    }

    .contentMenuHide {
        display: none;
    }

    .contentWrapperShow {
        display: block;
    }

    .contentWrapperHide {
        display: none;
    }

    .tabsWrapperInMobileAccount {
        position: relative;
        display: block;
        margin-top: 10px;

        .tabsWrapperContainerMobile {
            display: block;
            width: 100%;
            padding: 0;

            .tabsWrapperContainerItems:first-child {
                padding: 16px 16px;
                border: 1px solid #ffffff;
            }

            .tabsWrapperContainerItems {
                padding: 16px 16px;
                border: 1px solid #ffffff;
                border-top: none;
            }

            .tabsWrapperContainerItems:active {
                background-color: rgba($jcorp-main-red, 0.6);
            }

            .wrapperItem {
                display: inline-block;
                width: calc(100% - 20px);
                font-family: Teko;
                font-size: 22px;
                font-weight: 300;
                text-transform: uppercase;
                color: $jcorp-main-white;
            }

            .wrapperIcon {
                display: inline-block;
                vertical-align: middle;
                width: 20px;
                font-family: Teko;
                font-size: 18px;
                color: $jcorp-main-white;
            }
        }
    }

    .arenaTimerInfosContainer {
        position: relative;
        display: block;
        margin: auto;
        width: 100%;
    }

    .arenaTimerInfosBox {
        position: relative;
        display: block;
        height: 90px;

        background: $jcorp-main-red;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .arenaTimerInfosBoxIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: linear-gradient(284.72deg, #620C31 13.28%, #9F0836 96.53%);
        border-radius: 3px;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .arenaTimerHeadingBox {
        position: absolute;
        display: inline-block;
        top: 18px;
        left: 30px;
    }

    .arenaTimerHeadingBox .arenaTimerHeading {
        display: inline-block;

        font-family: Archivo;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-white;
    }

    .arenaTimerRankBox {
        position: absolute;
        display: inline-block;
        top: 50px;
        left: 30px;
    }

    .arenaTimerRankBox .arenaTimerRank {
        display: inline-block;

        font-family: Archivo;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-black;
    }

    .arenaTimerTextBox {
        position: absolute;
        display: inline-block;
        top: 35px;
        left: calc(100% - 170px);
    }

    .arenaTimerTextBox .arenaTimerText {
        display: inline-block;
        margin-right: 15px;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-white;
    }

    .arenaTimerTextBox .arenaTimerTimer {
        display: inline-block;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-red;
    }

    .arenaFightContainerLeft {
        position: relative;
        display: block;
        margin-top: 30px;
    }

    .arenaFightContainerRight {
        position: relative;
        display: block;
        margin-top: 30px;
    }
}