@import "common_values.scss";

._modalBoxContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 999;
    opacity: 0;
    visibility: hidden;

    background: rgba($jcorp-main-black, 0.9);

    /*@media (min-width: 992px) {
        backdrop-filter: blur(4px);
    }*/

    backdrop-filter: blur(4px);
    transition: all 0.4s ease;

    &.active {
        opacity: 1;
        visibility: visible;

        ._modalContainer {
            top: 0;
        }
    }
}

._modalContainer {
    position: relative;
    top: -10%;
    width: 60%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 100px;
    z-index: 1000;

    @media (min-width: 992px) {
        filter: drop-shadow(0 0 140px rgba($jcorp-main-red, 0.4));
    }

    transition: all 0.4s ease;

    @media (max-width: 991px) {
        width: 104%;
        left: -2%;
    }
}

._modalTitleContainer {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
}

._modalTitleTitle {
    font-family: ThirdRailDemo;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 100%;
    /* or 24px */

    text-transform: uppercase;

    /* Main/Red */
    color: $jcorp-main-red;

    @media (max-width: 991px) {
        font-size: 36px;
    }
}

._modalTitleSubTitle {
    margin-top: 20px;

    font-family: Archivo;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 100%;
    /* or 20px */

    text-transform: uppercase;

    color: $jcorp-main-white;
}

._modalContentContainer {
    position: relative;
    display: block;
    width: 90%;
    margin: 0 auto;
}

._modalCloseIcon {
    position: absolute;
    top: -1em;
    right: 1em;

    font-size: 20px;
    color: $jcorp-main-white;
    cursor: pointer;
    transition: all 0.1s linear;
    z-index: 50;

    &:hover {
        color: $jcorp-main-red;
    }
}

._modalContentBox {
    position: relative;
    display: block;

    background: $jcorp-main-red;
    border-radius: 3px;
    border: none;

    clip-path: polygon(0 0, calc(100% - 5em) 0, calc(100% - 3.5em) 1.5em, 100% 1.5em, 100% 100%, calc(100% - 4.1em) 100%, calc(100% - 5.6em) calc(100% - 1.5em), 0 calc(100% - 1.5em));

    @media (max-width: 551px) {
        clip-path: polygon(0 0, calc(100% - 5em) 0, calc(100% - 3.5em) 1.5em, 100% 1.5em, 100% 100%, 100% 100%, 100% calc(100% - 1.5em), 0 calc(100% - 1.5em));
    }
}

._modalContentBoxIn {
    position: relative;
    display: inline-block;
    margin: 1px;
    padding-top: 50px;
    padding-bottom: 100px;
    padding-left: 5%;
    padding-right: 5%;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    background: $jcorp-main-bg-black;
    border-radius: 3px;

    clip-path: polygon(0 0, calc(100% - 5em) 0, calc(100% - 3.5em) 1.5em, 100% 1.5em, 100% 100%, calc(100% - 4em) 100%, calc(100% - 5.5em) calc(100% - 1.5em), 0 calc(100% - 1.5em));

    @media (max-width: 551px) {
        clip-path: polygon(0 0, calc(100% - 5em) 0, calc(100% - 3.5em) 1.5em, 100% 1.5em, 100% 100%, 100% 100%, 100% calc(100% - 1.5em), 0 calc(100% - 1.5em));
    }
}

._modalButtonsBox {
    position: absolute;
    bottom: 1em;
    left: 50%;
    transform: translate(-50%, 25%);
    display: flex;
    gap: 24px;

    ._modalButton {
        position: relative;
        display: block;
        width: 160px;
        height: 58px;

        background: $jcorp-main-red;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1.25em) 0, 100% 1.25em, 100% 100%, 0 100%, 1.25em 100%, 0% calc(100% - 1.25em), 0 0%);
    }

    ._modalButton:hover {
        background: $jcorp-main-red;
    }

    ._modalButtonIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        color: $jcorp-main-red;
        background: $jcorp-main-black;
        border-radius: 3px;

        transition-duration: 0.4s;

        clip-path: polygon(0 0, calc(100% - 1.25em) 0, 100% 1.25em, 100% 100%, 0 100%, 1.25em 100%, 0% calc(100% - 1.25em), 0 0%);
    }

    ._modalButtonIn:hover {
        color: $jcorp-main-black;
        background: $jcorp-main-red;
        cursor: pointer;
    }

    ._modalButtonIn:active {
        background: rgba($jcorp-main-red, 0.6);
        transition-duration: 0s;
    }

    ._modalButtonReverse {
        position: relative;
        display: block;
        width: 160px;
        height: 58px;

        background: $jcorp-main-red;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1.25em) 0, 100% 1.25em, 100% 100%, 0 100%, 1.25em 100%, 0% calc(100% - 1.25em), 0 0%);
    }

    ._modalButtonReverse:hover {
        background: $jcorp-main-red;
    }

    ._modalButtonReverseIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        color: $jcorp-main-black;
        background: $jcorp-main-red;
        border-radius: 3px;

        transition-duration: 0.4s;

        clip-path: polygon(0 0, calc(100% - 1.25em) 0, 100% 1.25em, 100% 100%, 0 100%, 1.25em 100%, 0% calc(100% - 1.25em), 0 0%);
    }

    ._modalButtonReverseIn:hover {
        color: $jcorp-main-red;
        background: $jcorp-main-black;
        cursor: pointer;
    }

    ._modalButtonReverseIn:active {
        background: rgba($jcorp-main-black, 0.6);
        transition-duration: 0s;
    }

    ._modalButtonReverseDisable {
        position: relative;
        display: block;
        width: 160px;
        height: 58px;

        background: $jcorp-main-black;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1.25em) 0, 100% 1.25em, 100% 100%, 0 100%, 1.25em 100%, 0% calc(100% - 1.25em), 0 0%);

        cursor: not-allowed;
    }

    ._modalButtonReverseDisableIn {
        position: relative;
        display: inline-block;
        padding-top: 2px;

        width: 100%;
        height: 100%;

        color: rgba($jcorp-main-black, 0.6);
        background: rgba($jcorp-main-red, 0.6);
        border-radius: 3px;

        transition-duration: 0.4s;

        clip-path: polygon(0 0, calc(100% - 1.25em) 0, 100% 1.25em, 100% 100%, 0 100%, 1.25em 100%, 0% calc(100% - 1.25em), 0 0%);
    }

    ._modalButtonText {
        text-align: center;

        /* Button/Default */
        font-family: Teko;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.07em;
        text-transform: uppercase;

        //color: $jcorp-main-white;

        padding-top: 17px;
    }
}

._replayContainer {
    position: relative;
    display: block;
    width: 120%;
    left: -10%;
    //height: 100px; //TODO
    height: 70px;

    ._replayDivider {
        position: absolute;
        width: 120%;
        left: -10%;
        top: 53px;
        left: 0;
        border-bottom: 1px solid $jcorp-main-red;
    }

    ._replayButtonBox {
        display: none; //TODO

        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%, 25%);

        ._replayButton {
            position: relative;
            display: block;
            width: 220px;
            height: 58px;

            background: $jcorp-main-red;
            border-radius: 3px;
            border: none;

            clip-path: polygon(0 0, calc(100% - 1.25em) 0, 100% 1.25em, 100% 100%, 0 100%, 1.25em 100%, 0% calc(100% - 1.25em), 0 0%);
        }

        ._replayButton:hover {
            background: $jcorp-main-red;
        }

        ._replayButtonIn {
            position: relative;
            display: inline-block;
            margin: 1px;

            width: calc(100% - 2px);
            height: calc(100% - 2px);

            color: $jcorp-main-black;
            background: $jcorp-main-red;
            border-radius: 3px;

            transition-duration: 0.4s;

            clip-path: polygon(0 0, calc(100% - 1.25em) 0, 100% 1.25em, 100% 100%, 0 100%, 1.25em 100%, 0% calc(100% - 1.25em), 0 0%);
        }

        ._replayButtonIn:hover {
            color: $jcorp-main-red;
            background: $jcorp-main-black;
            cursor: pointer;
        }

        ._replayButtonIn:active {
            background: rgba($jcorp-main-black, 0.6);
            transition-duration: 0s;
        }

        ._replayButtonText {
            text-align: center;

            /* Button/Default */
            font-family: Teko;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 100%;
            letter-spacing: 0.07em;
            text-transform: uppercase;

            //color: $jcorp-main-white;

            padding-top: 17px;
        }
    }
}

._modalFightInfosContainer {
    position: relative;
    display: block;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;

    ._modalFightTitle {
        font-family: Archivo;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 100%;
        /* or 20px */

        margin-bottom: 15px;

        text-transform: uppercase;

        color: $jcorp-main-white;
    }

    ._modalFightSubTitle {
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 42px;
        line-height: 89%;
        /* or 21px */

        margin-bottom: 5px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-red;
    }

    ._modalFightSubTitleInfos {
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        /* or 24px */

        text-transform: uppercase;
        color: $jcorp-main-red;
    }
}