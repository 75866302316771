@import "common_values.scss";

@media (min-width: 992px) {
    .topArenaContainer {
        margin-top: 80px !important;
    }

    .smallBox {
        display: none;
    }

    .bigBox {
        display: block;
    }

    .connectBtn {
        position: relative !important;
        margin-bottom: 15px !important;
        padding: 10px !important;

        /* Main/Light */
        color: $jcorp-main-white !important;

        width: 320px !important;
        height: 60px !important;
        background: $jcorp-main-black !important;
        border: 1px solid $jcorp-main-red !important;
        border-radius: 0 !important;
        box-shadow: none !important;

        transition-duration: 0.4s !important;
    }

    .connectBtn:hover {
        background: $jcorp-main-red !important;
        border: 1px solid $jcorp-main-black !important;
    }

    .connectBtn:active {
        background: rgba($jcorp-main-red, 0.6) !important;
        border: 1px solid $jcorp-main-red !important;
        transition-duration: 0s !important;
    }

    .btnStandard:hover {
        background: $jcorp-main-red !important;
        border: 1px solid $jcorp-main-black !important;
    }

    .btnStandardReverse:hover {
        background: $jcorp-main-black !important;
        border: 1px solid $jcorp-main-red !important;
    }

    .btnStandardSmall:hover {
        background: $jcorp-main-red !important;
        border: 1px solid $jcorp-main-black !important;
    }

    .btnStandardSmallReverse:hover {
        background: $jcorp-main-black !important;
        border: 1px solid $jcorp-main-red !important;
    }

    .btnStandardTiny:hover {
        background: $jcorp-main-red !important;
        border: 1px solid $jcorp-main-black !important;
    }

    .btnStandardTinyReverse:hover {
        background: $jcorp-main-black !important;
        border: 1px solid $jcorp-main-red !important;
    }

    .navStandardSmall:hover {
        background: $jcorp-main-red !important;
        border: 1px solid $jcorp-main-black !important;
    }

    .navStandardTiny:hover {
        background: $jcorp-main-red !important;
        border: 1px solid $jcorp-main-black !important;
    }

    .actionStandardSmall:hover {
        background: $jcorp-main-red !important;
        border: 1px solid $jcorp-main-black !important;
    }

    .actionStandardSmallReverse:hover {
        background: $jcorp-main-black !important;
        border: 1px solid $jcorp-main-red !important;
    }

    .btnRed:hover {
        background: $jcorp-main-red !important;
        border: 1px solid $jcorp-main-black !important;
    }

    .btnRedReverse:hover {
        background: $jcorp-main-black !important;
        border: 1px solid $jcorp-main-red !important;
    }

    .maintenanceContainer {
        position: relative;
    }

    .maintenanceContainer:before {
        content: ' ';
        position: absolute;
        display: block;
        left: 0;
        top: 60px;
        width: 100%;
        height: 850px;
        background-image: url('../img/titan.png');
        background-repeat: no-repeat;
        background-position: calc(50% + 60px) 0;
        background-size: 900px;
    }

    .topMaintenanceContainer {
        margin-top: calc(25% + 50px) !important;
    }

    .pageContainer {
        position: relative;
        min-height: 100vh;
        margin-bottom: -260px;
    }

    .pageContainer:after {
        content: ' ';
        position: relative;
        display: block;
        height: 260px;
        margin-bottom: 100px;
    }

    .pageContainer:before {
        content: ' ';
        position: absolute;
        display: block;
        left: 0;
        top: 60px;
        width: 100%;
        height: 850px;
        background-image: var(--bg-image);
        background-repeat: no-repeat;
        background-position: calc(50% + 60px) 0;
        background-size: 900px;
    }

    .shadeContainer .pageImgTexture2 {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 40px;
        width: 100%;
        height: 850px;
        background-image: url('../img/texture.png');
        background-repeat: no-repeat;
        background-size: 100vw;
    }

    .shadeContainer .shade1 {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        height: 425px;

        background: linear-gradient(180deg, #24102C 0%, rgba(36, 16, 44, 0) 100%);
        //border: 1px solid blue;
    }

    .shadeContainer .shade2 {
        position: absolute;
        top: 60px;
        left: calc(50% + 60px);
        width: 450px;
        height: 850px;

        background: linear-gradient(270deg, #24102C 0%, rgba(36, 16, 44, 0) 100%);
        //border: 1px solid green;
    }

    .shadeContainer .shade3 {
        position: absolute;
        top: 60px;
        right: calc(50% - 60px);
        width: 450px;
        height: 850px;

        background: linear-gradient(90deg, #24102C 0%, rgba(36, 16, 44, 0) 100%);
        //border: 1px solid red;
    }

    .shadeContainer .shade4 {
        position: absolute;
        top: 450px;
        left: 0;
        width: 100%;
        height: 470px;

        background: linear-gradient(180deg, #24102C 0%, rgba(36, 16, 44, 0) 100%);
        //border: 1px solid grey;
        transform: rotate(-180deg);
    }

    .shadeContainer .shade5 {
        position: absolute;
        top: 450px;
        left: 0;
        width: 100%;
        height: 470px;

        background: linear-gradient(180deg, #24102C 0%, rgba(36, 16, 44, 0) 100%);
        //border: 1px solid yellow;
        transform: rotate(-180deg);
    }

    .logoSmall {
        display: none;
    }

    .logoSmallContainer {
        display: none;
    }

    .socialIconsSmallContainer {
        display: none;
    }

    .navBtnContainerSmall {
        display: none;
    }

    .navGroupContainer {
        position: relative;
        width: 100%;
        height: 80px;
    }

    #navGroup {
        position: absolute;
        height: 67px;
        left: 0%;
        right: 5vw;
        top: 40%;

        /* Main/Red */
        background: $jcorp-main-red;
    }

    #navGroup::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 67px;
        right: -30px;

        /* Main/Red */
        background: $jcorp-main-red;
        border-top-right-radius: 2px;

        clip-path: polygon(10% 0, 100% 0, 100% 10%, 100% 60%, calc(100% - 2em) 100%, 0% 100%, 0% 100%, 0 0%);
    }

    #navGroup .logoNav {
        position: absolute;
        width: 160px;
        left: 10%;
        top: 12px;
    }

    #navGroup .logoNav:hover {
        cursor: pointer;
    }

    #navGroup #navbarResponsive .nav-item {
        margin-left: 20px !important;
    }

    .navLink {
        display: block;
        padding: 0.5rem;

        color: $jcorp-main-white !important;
        font-family: Teko;
        font-style: normal !important;
        font-weight: 300;
        font-size: 24px;
        text-transform: uppercase;
        transition-duration: 0.4s;
    }

    .navLink:hover {
        color: $jcorp-main-black !important;
        cursor: pointer;
    }

    #navGroup .socialIconsContainer {
        margin-top: 5px;
    }

    #navGroup .socialIconsContainer .socialIcons {
        margin-right: 30px;
    }

    .navBtnContainer {
        position: absolute;
        right: 8vw;
        top: 30%;
    }

    .navBtnContainer span {
        margin-right: 20px;
    }

    .navBtnContainer span:last-child {
        margin-right: 0px;
    }

    #navbarResponsive {
        position: absolute;
        right: 15vw;
    }

    .egldCommunity {
        border-radius: 8px;
        border: none;
        width: 290px;
        height: 70px
    }

    .homeInfosContainer {
        position: relative;
        display: block;
        margin-top: 50px;
    }

    .homeVersionBox {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 89%;
        /* identical to box height, or 11px */

        letter-spacing: 0.07em;
        text-transform: uppercase;

        color: $jcorp-main-white;

        position: absolute;
        top: 0;
        left: 0;
    }

    .homeTextInfos {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 89%;
        /* identical to box height, or 11px */

        letter-spacing: 0.07em;
        text-transform: uppercase;

        color: $jcorp-main-white;

        position: absolute;
        top: 0;
        right: 0;
    }

    .homeContainer {
        position: relative;
        display: block;
        margin-top: 170px;
    }

    .homeBox {
        position: relative;
        display: inline-block;
    }

    .homeLogo {
        position: absolute;
        width: 630px;
        top: -77px;
        left: -57px;
    }

    .homeContainerTitle {
        position: relative;
        display: block;

        /* Headlines/T1 */
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 104px;
        line-height: 85%;
        /* or 88px */

        letter-spacing: -0.08em;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .homeContainerText {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 180%;
        /* or 36px */

        color: $jcorp-main-white;

        margin-top: 40px;
    }

    .homePictureBox {
        position: absolute;
        display: inline-block;

        top: 20px;
        right: 0;
    }

    .videoContainer {
        position: relative;
        height: 500px;
        width: 110%;
        left: -5%;
        margin-top: 100px;

        background: $jcorp-main-red;
        border: none;

        clip-path: polygon(calc(100% - 6em) 0, calc(100% - 4em) 2em, 100% 2em, 100% 100%, calc(100% - 15em) 100%, calc(100% - 17em) calc(100% - 2em), 5em calc(100% - 2em), 3em 100%, 0 100%, 0 0);
    }

    .videoContainerIn {
        position: absolute;
        top: 0.8px;
        left: 0;

        height: 498.4px;
        width: 100%;

        background: #18101B;

        clip-path: polygon(calc(100% - 6em) 0, calc(100% - 4em) 2em, 100% 2em, 100% 100%, calc(100% - 15em) 100%, calc(100% - 17em) calc(100% - 2em), 5em calc(100% - 2em), 3em 100%, 0 100%, 0 0);
    }

    .videoContainer .shade1 {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 500px;

        background: linear-gradient(270deg, $jcorp-main-black 0%, rgba(36, 16, 44, 0) 20%);
        //border: 1px solid green;

        z-index: 5;
    }

    .videoContainer .shade2 {
        position: absolute;
        top: 0;
        right: 50%;
        width: 50%;
        height: 500px;

        background: linear-gradient(90deg, $jcorp-main-black 0%, rgba(36, 16, 44, 0) 20%);
        //border: 1px solid yellow;

        z-index: 5;
    }

    .videoContainer .videoBox {

        /* Neutral/Black */
        position: relative;
        display: block;
        width: 70%;
        height: 350px;

        top: 60px;
        left: 15%;

        background: #000000;
        /* Main/Red */
        border: 1px solid $jcorp-main-red;
        border-radius: 4px;

        z-index: 10;
    }

    .videoContainer .videoContent {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }

    .universeContainer {
        position: relative;
        display: block;
        margin-top: 120px;

        width: 120%;
        left: -5%;
    }

    .universePictureBox {
        position: relative;
        display: inline-block;
        width: 600px;
        left: -20px;
    }

    .universePictureBoxA {
        position: relative;
        display: block;
        width: 552px;
        height: 552px;
        background: $jcorp-main-red !important;
        border-radius: 10px;
        border: none;
        box-shadow: none !important;

        clip-path: polygon(0 0, calc(100% - 3em) 0, 100% 3em, 100% 100%, 0 100%, 3em 100%, 0% calc(100% - 3em), 0 0%);
    }

    .universePictureBoxInA {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 550px;
        height: 550px;

        clip-path: polygon(0 0, calc(100% - 3em) 0, 100% 3em, 100% 100%, 0 100%, 3em 100%, 0% calc(100% - 3em), 0 0%);
    }

    .universePictureA {
        width: 550px;
        border-radius: 10px;
    }

    .universePictureBoxB {
        position: absolute;
        top: -85px;
        right: -35px;
        width: 406px;
        height: 305px;
        background: $jcorp-main-red !important;
        border-radius: 6px;
        border: none;
        box-shadow: none !important;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 2em), calc(100% - 2em) 100%, 1.5em 100%, 1.5em 9em, 0 7.5em, 0 2em, 2em 0);
    }

    .universePictureBoxInB {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 404px;
        height: 303px;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 2em), calc(100% - 2em) 100%, 1.5em 100%, 1.5em 8.85em, 0 7.35em, 0 2em, 2em 0);
    }

    .universePictureB {
        width: 404px;
        border-radius: 6px;
    }

    .universeBox {
        position: relative;
        display: inline-block;
        width: 420px;
        top: 10px;
        right: -80px;
    }

    .universeTitle {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 72px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .universeSub {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 89%;
        /* or 21px */

        margin-bottom: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-red;
    }

    .universeTextSmall {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        /* or 29px */

        width: 375px;
        text-align: left;

        margin-top: 40px;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .universeText {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 140%;
        /* or 29px */

        width: 375px;
        text-align: left;

        margin-top: 30px;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .factionTitleContainer {
        position: relative;
        display: block;
        text-align: center;
        margin-top: 100px;
    }

    .factionTitle {
        display: inline-block;
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 80px;
        line-height: 89%;
        /* or 71px */

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .factionTitleSmall {
        display: inline-block;
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 89%;
        /* or 71px */

        /* Main/Red */
        color: $jcorp-main-red;

        margin: 25px;
        vertical-align: top;
    }

    .factionContainer {
        position: relative;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 30%;
        margin-top: 80px;
        gap: 5%;

        width: 110%;
        left: -5%;
    }

    .factionBox {
        position: relative;
        display: block;
        text-align: center;
        padding-left: 38px;
        padding-right: 38px;
    }

    .factionName {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .factionSub {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 89%;
        /* or 21px */

        margin-top: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-red;
    }

    .factionText {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        /* or 29px */

        text-align: left;

        margin-top: 40px;

        /* Main/Light */

        color: $jcorp-main-white;
    }

    .gameContainer {
        position: relative;
        display: block;
        width: 110%;
        height: 760px;
        left: -5%;
        margin-top: 200px;

        z-index: 99;
    }

    .gameContainer .gameContainerSub {
        position: relative;
        display: block;
        width: 110%;
        height: 760px;
        left: -5%;

        background: $jcorp-main-red;

        clip-path: polygon(calc(100% - 4em) 0, calc(100% - 2em) 2em, 100% 2em, 100% 100%, calc(100% - 18em) 100%, calc(100% - 20em) calc(100% - 2em), 5em calc(100% - 2em), 3em 100%, 0 100%, 0 0);
    }

    .gameContainer .gameContainerSubBefore {
        content: "";
        position: absolute;
        width: 200%;
        height: 760px;

        top: 0;
        right: 102%;

        background: $jcorp-main-red;
        transform: rotate(-180deg);
    }

    .gameContainer .gameContainerSubAfter {
        content: "";
        position: absolute;
        width: 200%;
        height: calc(760px - 2em);

        top: 2em;
        left: 99%;

        background: $jcorp-main-red;
    }

    .gameContainer .shade11 {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 800px;

        background: linear-gradient(270deg, $jcorp-main-black 0%, rgba(36, 16, 44, 0) 20%);
        //border: 1px solid green;

        z-index: 5;
    }

    .gameContainer .shade12 {
        position: absolute;
        top: 0;
        right: 50%;
        width: 50%;
        height: 800px;

        background: linear-gradient(90deg, $jcorp-main-black 0%, rgba(36, 16, 44, 0) 20%);
        //border: 1px solid yellow;

        z-index: 5;
    }

    .gameContainer .gameBox {
        position: relative;
        display: block;
        width: 380px;
        top: 100px;
        left: 80px;
    }

    .gameContainer .gameTitle {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 104px;
        line-height: 85%;
        /* or 88px */

        letter-spacing: -0.08em;
        text-transform: uppercase;

        color: $jcorp-main-black;
    }

    .gameContainer .gameSub {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 89%;
        /* or 21px */

        margin-top: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        color: $jcorp-main-white;
    }

    .gameContainer .gameText {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;

        text-align: left;

        margin-top: 40px;

        color: $jcorp-main-black;
    }

    .gameContainer .gameButtonBox {
        position: relative;
        display: block;
        top: -200px;
        left: 20px;
    }

    .gameContainer .gameButtonBoxSmall {
        display: none;
    }

    .gameContainer .gamePictureBoxSmall {
        display: none;
    }

    .gameContainer .gamePictureBox {
        position: absolute;
        display: block;
        width: 600px;
        top: 0;
        right: 50px;
    }

    .gamePictureBoxA {
        position: absolute;
        top: -60px;
        right: 25px;
        width: 552px;
        height: 552px;
        background: $jcorp-main-red !important;
        border-radius: 10px;
        border: none;
        box-shadow: none !important;

        clip-path: polygon(calc(100% - 2em) 0, 100% 2em, 100% 10.5em, calc(100% - 1.5em) 12em, calc(100% - 1.5em) 100%, 0 100%, 3em 100%, 0 calc(100% - 3em), 0% 100%, 0 0);
    }

    .gamePictureBoxInA {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 550px;
        height: 550px;

        clip-path: polygon(calc(100% - 2em) 0, 100% 2em, 100% 10.5em, calc(100% - 1.5em) 12em, calc(100% - 1.5em) 100%, 0 100%, 3em 100%, 0 calc(100% - 3em), 0% 100%, 0 0);
    }

    .gamePictureA {
        width: 550px;
        border-radius: 10px;
    }

    .gamePictureBoxB {
        position: absolute;
        top: 340px;
        right: -25px;
        width: 360px;
        height: 270px;
        background: $jcorp-main-red !important;
        border-radius: 6px;
        border: none;
        box-shadow: none !important;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 2em), calc(100% - 2em) 100%, 1.5em 100%, 1.5em 9em, 0 7.5em, 0 2em, 2em 0);
    }

    .gamePictureBoxInB {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 358px;
        height: 268px;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 2em), calc(100% - 2em) 100%, 1.5em 100%, 1.5em 8.85em, 0 7.35em, 0 2em, 2em 0);
    }

    .gamePictureB {
        width: 358px;
        border-radius: 6px;
    }

    .statsContainer {
        position: relative;
        display: block;
        height: 220px;

        top: -60px;
        right: 100px;

        z-index: 1;
    }

    .statsContainer .statsContainerOut {
        position: absolute;
        display: block;
        height: 280px;
        width: 500%;

        top: 0;
        right: 0;

        background: $jcorp-main-red;
        border-bottom-right-radius: 5px;
        border: none;

        clip-path: polygon(100% 0, 100% 2em, 100% 2em, 100% 100%, calc(100% - 21em) 100%, calc(100% - 23em) calc(100% - 2em), 0 calc(100% - 2em), 0 100%, 0 100%, 0 0);
    }

    .statsContainer .statsContainerIn {
        position: absolute;
        top: 1px;
        left: 0;

        height: 278px;
        width: calc(100% - 1px);

        background: #18101B;
        border-bottom-right-radius: 5px;

        clip-path: polygon(100% 0, 100% 2em, 100% 2em, 100% 100%, calc(100% - 20.9em) 100%, calc(100% - 22.9em) calc(100% - 2em), 0 calc(100% - 2em), 0 100%, 0 100%, 0 0);
    }

    .statsContainer .statsBox {
        position: absolute;
        display: block;

        margin-top: 100px;
        right: 125px;
    }

    .statsContainer .statsInfos {
        position: relative;
        display: inline-block;

        text-align: center;
        margin-right: 70px;
    }

    .statsContainer .statsInfos:last-child {
        margin-right: 0;
    }

    .statsContainer .statsInfosValue {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 300;
        font-size: 72px;
        line-height: 85%;
        /* or 61px */


        /* Main/Red */
        color: $jcorp-main-red;
    }

    .statsContainer .statsInfosTitle {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 89%;
        /* or 21px */

        letter-spacing: 0.07em;
        text-transform: uppercase;

        margin-top: 15px;

        /* Neutral/White */

        color: $jcorp-main-white;
    }

    .infosContainer {
        position: relative;
        display: block;
        margin-top: 120px;
    }

    .infosBox {
        position: relative;
        display: inline-block;
        width: 550px;
    }

    .infosBoxModal {
        position: relative;
        display: inline-block;
    }

    .infosBoxModalSub {
        position: absolute;
        display: inline-block;
        top: -5px;
        right: 50px;
    }

    .infosTitle {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 72px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .infosFactionTitle {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 72px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .infosBalance {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .infosSub {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 89%;
        /* or 21px */

        margin-bottom: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-red;
    }

    .infosSubTitle {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 89%;
        /* or 21px */

        margin-bottom: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-red;
    }

    .infosIconContainer {
        display: inline-block;
        vertical-align: text-top;
        margin-left: 15px;
    }

    .infosIconContainer:hover {
        cursor: pointer;
    }

    .infosIcon {
        width: 32px;
    }

    .infosModalTitle {
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 89%;
        /* or 21px */

        margin-bottom: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-red;
    }

    .infosModalTx {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 89%;
        /* or 21px */

        margin-bottom: 15px;

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */

        color: $jcorp-main-white;
    }

    .infosBoxSub {
        position: relative;
        display: block;
        top: 0;
        right: 0;
    }

    .infosBoxSubSpecial {
        position: relative;
        display: block;
        top: -85px;
        right: 0;
    }

    .infosBoxAccount {
        position: relative;
        display: block;
        top: -25px;
        right: 0;
    }

    .infosText {
        position: relative;
        bottom: -8px;
        text-align: left;

        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 180%;
        /* or 29px */

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .homeROFLogoContainer {
        position: relative;
        display: block;
        margin-top: 120px;
    }

    .homeROFLogo {
        position: relative;
        display: block;
        left: -8%;
        width: 120%;
    }

    .swiper {
        position: relative;
        display: flex;
        margin-top: 40px;
        left: -10%;
        width: 120%;
        padding-top: 30px;
        padding-bottom: 40px;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
        position: relative;
        display: block;
        width: 375px !important;
    }

    .swiper-pagination-bullet-active {
        background-color: $jcorp-main-red !important;
    }

    .roadmapContainer2 {
        position: relative;
        display: flex;
        margin-top: 50px;
        margin-bottom: -15px;
        padding-bottom: 15px;
        left: -5%;
        width: 120%;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .roadmapCardContainer2 {
        position: relative;
        display: block;
        margin-right: 30px;
    }

    .roadmapContainer1 {
        position: relative;
        display: block;
        margin-top: 50px;
        text-align: center;
    }

    .roadmapCardContainer1 {
        position: relative;
        display: inline-block;
        margin-top: 30px;
        margin-right: 30px;
        text-align: left;
    }

    .roadmapContainera {
        position: relative;
        display: block;
        margin-top: 50px;
        left: -5%;
        width: 120%;
    }

    .roadmapCardContainera {
        position: relative;
        display: inline-block;
        margin-top: 30px;
        margin-right: 30px;
    }

    .cardRoadmapBox {
        position: relative;
        display: block;

        width: 375px;
        height: 573px;

        background: $jcorp-main-red;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .cardRoadmapBoxIn {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 373px;
        height: 571px;

        background: #18101B;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .cardRoadmapPictureBox {
        position: relative;
        width: 100%;
        max-height: 368px;
        overflow: hidden;
    }

    .cardRoadmapPicture {
        position: relative;
        width: 100%;
    }

    .cardRoadmapInfos {
        position: relative;
        display: block;

        margin: 40px;
        margin-top: 25px;
    }

    .cardRoadmapPhase {
        font-family: Teko;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        /* identical to box height, or 24px */

        text-transform: uppercase;

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .cardRoadmapTitle {
        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        line-height: 85%;
        /* or 27px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        margin-top: 18px;

        /* Main/Red */
        color: $jcorp-main-white;
    }

    .cardRoadmapText {
        font-family: 'Rajdhani';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;

        margin-top: 18px;
        text-align: left;

        /* Main/Red */
        color: $jcorp-main-white;
    }

    .teamContainer {
        position: relative;
        display: block;
        left: -5%;
        width: 120%;
    }

    .partnersContainer {
        position: relative;
        display: block;
        margin-top: 30px;
        left: -5%;
        width: 120%;
    }

    .partnersBox {
        position: relative;
        display: inline-block;
        margin-top: 30px;
        margin-right: 30px;
        width: 175px;
        text-align: center;

        font-family: Rajdhani;
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        line-height: 180%;
        /* or 43px */
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .partnersBox .partnersInfos {
        margin-top: 25px;
        margin-bottom: 15px;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 89%;
        /* or 21px */

        letter-spacing: 0.07em;
        /* or 43px */

        /* Main/Light */
        color: $jcorp-main-red;
    }

    .FAQContainer {
        position: relative;
        display: block;
        margin-top: 60px;
    }

    .FAQBox {
        position: relative;
        display: block;
        margin-top: 15px;
        padding: 24px 32px;

        /* Tint/Purple */
        background: #18101B;
        /* Main/Red */

        border: 1px solid $jcorp-main-red;
        border-radius: 4px;
    }

    .FAQBoxQuestion {
        position: relative;
        display: block;

        cursor: pointer;
    }

    .FAQBoxText {
        position: relative;
        display: inline-block;

        width: 95%;

        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 140%;
        /* identical to box height, or 45px */


        /* Main/Light */
        color: $jcorp-main-white;
    }

    .FAQBoxIcon {
        position: absolute;
        display: inline-block;

        width: 5%;
        text-align: center;
        font-size: 18px;

        top: 50%;
        transform: translateY(-50%);

        color: $jcorp-main-red;
    }

    .FAQBoxInfos {
        position: relative;
        display: block;

        margin-top: 20px;
        width: 100%;

        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 180%;
        /* or 43px */


        /* Main/Light */
        color: $jcorp-main-white;
    }

    .FAQBoxInfos a {
        /* Main/Light */
        color: $jcorp-main-red;
        font-weight: 900;
    }

    .FAQBoxInfos a:hover {
        /* Main/Light */
        color: rgba($jcorp-main-red, 0.6);
    }

    .cardContainer {
        position: relative;
        display: block;
        width: 275px;
        height: 275px;
    }

    .cardContainerHome1 {
        position: relative;
        display: block;
        width: 275px;
        height: 275px;

        top: 0;
        right: -50px;

        z-index: 99;
    }

    .cardContainerHome2 {
        position: absolute;
        display: block;
        width: 275px;
        height: 275px;

        top: 260px;
        right: -80px;

        z-index: 90;
    }

    .cardContainerHome3 {
        position: absolute;
        display: block;
        width: 275px;
        height: 275px;

        top: 120px;
        right: 160px;

        z-index: 95;
    }

    .cardBox {
        position: relative;
        display: block;

        width: 275px;
        height: 275px;

        background: $jcorp-main-red;
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .cardBoxIn {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 273px;
        height: 273px;

        background: #18101B;
        border-radius: 3px;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .cardInfos {
        position: absolute;
        display: block;
        text-align: left;

        bottom: 6px;
        left: 25px;
    }

    .cardHierarchy {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 100%;
        /* or 24px */

        text-transform: uppercase;

        /* Main/Red */
        color: $jcorp-main-white;
    }

    .cardPower {
        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 100%;
        /* or 20px */

        margin-top: 2px;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .cardInfosBis {
        position: absolute;
        display: block;

        bottom: 20px;
        right: 30px;
    }

    .cardNumber {
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 42px;
        line-height: 100%;
        /* or 24px */

        text-transform: uppercase;
        text-align: center;

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .cardName {
        position: relative;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        /* or 24px */

        text-transform: uppercase;
        text-align: center;
        top: -5px;

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .cardGamePictureBox {
        position: absolute;
        top: -35px;
        left: 15px;

        width: 245px;
        height: 245px;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .cardGamePicture {
        width: 245px;
        border-radius: 3px;
    }

    .factionCardContainer {
        position: relative;
        display: block;
        margin-top: 80px;
    }

    .teamCardContainer {
        position: relative;
        display: inline-block;
        margin-top: 100px;
        margin-right: 15px;
    }

    .buttonsNFTContainer {
        position: absolute;
        display: inline-block;
        top: -72px;
        right: 0;
    }

    .buttonsNFTContainerBis {
        position: absolute;
        display: inline-block;
        top: -72px;
        right: 0;
    }

    .historyContainer {
        position: absolute;
        display: inline-block;
        text-align: center;
        top: -100px;
        right: 0;
    }

    .historySelection {
        position: relative;
        display: block;
        margin-top: 10px;
    }

    .phaseContainer {
        position: absolute;
        display: inline-block;
        text-align: center;
        top: -80px;
        right: 0;
    }

    .pageTitle {
        position: relative;
        display: block;
        text-align: left;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 80px;
        line-height: 89%;
        /* or 71px */

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .pageTitleMaintenance {
        position: relative;
        display: block;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 80px;
        line-height: 89%;
        /* or 71px */

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .infosConquestContainer {
        position: relative;
        display: block;
        margin-top: 30px;
    }

    .conquestTitleBox {
        position: relative;
        display: block;
    }

    .conquestTitle {
        position: relative;
        display: block;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 80px;
        line-height: 89%;
        /* or 71px */

        transform: rotate(-8deg);
        margin-top: -35px;

        /* Main/Red */
        color: $jcorp-main-red;

        z-index: 2;
    }

    .conquestNumber {
        position: absolute;
        display: block;

        top: 8px;
        left: 272px;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 58px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;

        z-index: 1;
    }

    .conquestSub {
        position: absolute;
        display: block;

        top: 29px;
        left: 180px;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 42px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;

        z-index: 1;
    }

    .phaseTitle {
        position: relative;
        display: block;

        left: 75px;
        margin-top: 22px;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 60px;
        line-height: 89%;
        /* or 71px */

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .infosConquestBox {
        position: relative;
        display: inline-block;
        width: 500px;
    }

    .infosTimerContainer {
        position: relative;
        display: inline-block;
        bottom: 40px;
        left: 300px;
    }

    .infosTimerTitle {
        position: relative;
        display: block;
    }

    .infosTimerBox {
        position: relative;
        display: block;
    }

    .timerNumber {
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .timerSep {
        font-family: Archivo;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 85%;
        /* or 41px */

        margin-left: 8px;
        margin-right: 8px;

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .infosFactionContainer {
        position: relative;
        display: inline-block;
        left: 300px;
        bottom: 70px;
        margin-bottom: -60px;
    }

    .infosFactionImgContainer {
        position: relative;
        display: inline-block;
        left: 300px;
        vertical-align: top;
    }

    .infosClaim {
        position: relative;
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 300px;

        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;
        text-align: center;

        /* Main/Light */
        color: $jcorp-main-white;

        background: rgba($jcorp-main-white, 0.2);
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 0.8em) 0, 100% 0.8em, 100% 100%, 0 100%, 0.8em 100%, 0% calc(100% - 0.8em), 0 0%);
    }

    .infosWallet {
        position: relative;
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 240px;

        font-family: Rajdhani;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 120%;
        /* or 20px */
        text-align: center;

        /* Main/Light */
        color: $jcorp-main-white;

        background: rgba($jcorp-main-white, 0.2);
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 0.8em) 0, 100% 0.8em, 100% 100%, 0 100%, 0.8em 100%, 0% calc(100% - 0.8em), 0 0%);
    }

    .walletContainer {
        position: relative;
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;

        background: rgba($jcorp-main-white, 0.2);
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .walletText {
        position: relative;
        display: block;
        margin-left: 20px;
    }

    .walletButton {
        position: absolute;
        top: -4px;
        right: 0;
        margin-right: 20px;
    }

    .squadContainer {
        position: relative;
        display: block;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;

        background: rgba($jcorp-main-white, 0.2);
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .squadInfosContainer {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 160px);
        margin-left: 10px;
        margin-right: 20px;
    }

    .squadTitle {
        position: relative;
        display: block;
    }

    .squadInfos {
        position: relative;
        display: block;
        margin-top: 20px;
        margin-left: 10px;
    }

    .squadInfosModal {
        position: relative;
        display: block;
        margin-top: 20px;
    }

    .squadIcon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    .squadTextContainer {
        position: relative;
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle;
        width: calc(100% - 70px);
    }

    .squadText {
        position: relative;
        display: block;

        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .squadTextModal {
        position: relative;
        display: block;

        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .actionContainer {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 120px;
        margin-right: 10px;
    }

    .actionIcon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        margin-left: 10px;
        font-size: 20px;
        color: $jcorp-main-white;
    }

    .actionIcon:hover {
        color: $jcorp-main-red;
        cursor: pointer;
    }

    .conquestSquadContainer {
        position: relative;
        display: inline-block;
        width: 341px;
        margin-bottom: 10px;
        margin-left: 7px;
        margin-right: 7px;

        background: rgba($jcorp-main-white, 0.2);
        border-radius: 4px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .conquestSquadInfosContainer {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        padding: 10px;
    }

    .conquestSquadInfosContainer .overlayHover {
        display: none;
    }

    .conquestSquadInfosContainer:hover .overlayHover {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 47px;
        border-radius: 4px;
        background-color: rgba($jcorp-main-white, 0.7);
        cursor: pointer;

        z-index: 90;
    }

    .conquestSquadInfosContainer:hover .overlayHoverText {
        position: relative;
        display: block;
        text-align: center;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 100%;
        /* or 24px */

        text-transform: uppercase;
        color: $jcorp-main-red;
    }

    .conquestSquadTitle {
        position: relative;
        display: block;
    }

    .conquestSquadInfos {
        position: relative;
        display: block;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .conquestSquadIcon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    .conquestSquadTextContainer {
        position: relative;
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle;
    }

    .conquestSquadText {
        position: relative;
        display: block;

        font-family: Archivo;
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 85%;
        /* or 41px */

        letter-spacing: -0.07em;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .tokenLogoContainer {
        margin-top: 10px;
        margin-left: 25px;
        text-align: center;
        position: absolute;
        top: 0px;
        left: -6px;
        background: #fff;
        border: 3px solid $jcorp-main-black;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: 0px 1px 5px $jcorp-main-black;
    }

    .deployBoxTitle {
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        /* or 24px */

        text-transform: uppercase;
        color: $jcorp-main-white;
    }

    .tokenLogo {
        padding-top: 2px;
        width: 30px;
    }

    .tokenIcon {
        margin-top: 4px;
        color: #ced4da;
    }

    .walletForm {
        position: relative;
        display: block;
        text-align: center;
        width: 300px;
        margin: auto;
        margin-top: 15px;
    }

    .formLabel {
        margin-left: 5px;
    }

    .walletFormButtons {
        position: relative;
        display: block;
        text-align: right;
        top: -5px;
        right: -3px;
    }

    .dataContainer {
        position: relative;
        display: block;
    }

    .dataBox {
        position: relative;
        display: inline-block;
        width: 50%;
        vertical-align: top;
    }

    .dataBox:first-child {
        padding-right: 25px;
    }

    .dataBox:last-child {
        padding-left: 25px;
    }

    .dataSepartorLine {
        position: relative;
        display: block;
        text-align: left;
        border-bottom: 1px solid rgba($jcorp-main-red, 0.3);
    }

    .dataTitle {
        display: inline-block;

        font-family: Archivo;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 180%;
        /* or 20px */

        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .dataAttribut {
        position: absolute;
        right: 0;

        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 180%;
        /* or 20px */

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .factionsContainer {
        position: relative;
        display: block;
    }

    .factionsInfos {
        position: relative;
        display: inline-block;
        text-align: center;
        width: 105px;
        margin: 5px;

        font-family: Teko;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        /* or 20px */

        /* Main/Light */
        color: $jcorp-main-white;

        border-bottom: 3px solid rgba(var(--factionColor), 1);
    }

    .mapInfosContainerRight {
        position: absolute;
        display: block;
        right: 20px;
        top: 0px;

        z-index: 99;
    }

    .mapInfosContainerLeft {
        position: absolute;
        display: block;
        left: 20px;
        top: 0px;

        z-index: 99;
    }

    .mapInfosBonus {
        position: relative;
        display: block;
        text-align: center;
        margin: 5px;

        padding: 5px;
        padding-bottom: 2px;

        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        /* or 24px */

        text-transform: uppercase;
        color: $jcorp-main-black;

        background-color: rgba($jcorp-main-white, 0.8);
        border-radius: 4px;
        border: none;

        transition-duration: 0.4s;
    }

    .mapInfosBonus:hover {
        background-color: rgba($jcorp-main-white, 1);
        box-shadow: 0px 1px 5px $jcorp-main-black;
        cursor: pointer;
    }

    .mapBoxIconContainer {
        position: relative;
        display: block;
        margin-top: 20px;
    }

    .mapBoxIcon {
        position: relative;
        text-align: center;
        width: 60px;
        height: 60px;
        padding: 7px;

        color: $jcorp-main-white;
        background-color: rgba($jcorp-main-red, 0.8);
        box-shadow: 0px 1px 5px $jcorp-main-black;
        border-radius: 4px;
        border: none;
    }

    .mapBoxIcon:hover {
        background-color: rgba($jcorp-main-red, 1);
        cursor: pointer;
    }

    .mapBoxIconFa {
        position: relative;
        top: 3px;
        font-size: 40px;
    }

    .factionsContainer1 {
        position: absolute;
        display: block;
        left: 5px;
        bottom: 5px;

        z-index: 99;
    }

    .factionsInfos1 {
        position: relative;
        display: inline-block;
        text-align: center;
        margin: 5px;

        font-family: Teko;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 100%;
        /* or 20px */

        /* Main/Light */
        color: $jcorp-main-white;

        border-bottom: 3px solid rgba(var(--factionColor), 1);
    }

    .mapImperial {
        position: absolute;
        top: calc(var(--mapHeight) * 0.06);
        left: calc(var(--mapWidth) * 0.74);

        z-index: 99;
    }

    .mapInn {
        position: absolute;
        top: calc(var(--mapHeight) * 0.45);
        left: calc(var(--mapWidth) * 0.55);

        z-index: 99;
    }

    .mapTemple {
        position: absolute;
        top: calc(var(--mapHeight) * 0.34);
        left: calc(var(--mapWidth) * 0.43);

        z-index: 99;
    }

    .mapMarket {
        position: absolute;
        top: calc(var(--mapHeight) * 0.78);
        left: calc(var(--mapWidth) * 0.26);

        z-index: 99;
    }

    .mapJoy {
        position: absolute;
        top: calc(var(--mapHeight) * 0.49);
        left: calc(var(--mapWidth) * 0.27);

        z-index: 99;
    }

    .mapPort {
        position: absolute;
        top: calc(var(--mapHeight) * 0.71);
        left: calc(var(--mapWidth) * 0.56);

        z-index: 99;
    }

    .mapSamurai {
        position: absolute;
        top: calc(var(--mapHeight) * 0.36);
        right: calc(var(--mapWidth) * 0.78);

        z-index: 99;
    }

    .mapNinja {
        position: absolute;
        top: calc(var(--mapHeight) * 0.26);
        left: calc(var(--mapWidth) * 0.76);

        z-index: 99;
    }

    .mapInfosIcon {
        width: 32px;
        padding: 1px;

        color: $jcorp-main-white;
        background: rgba($jcorp-main-red, 0.8);
        border-radius: 4px !important;
        border: none;

        cursor: pointer;
    }

    .empImperial {
        position: absolute;
        top: calc(var(--mapHeight) * 0.10);
        left: calc(var(--mapWidth) * 0.58);

        z-index: 99;
    }

    .empInn {
        position: absolute;
        top: calc(var(--mapHeight) * 0.57);
        left: calc(var(--mapWidth) * 0.54);

        z-index: 99;
    }

    .empTemple {
        position: absolute;
        top: calc(var(--mapHeight) * 0.49);
        left: calc(var(--mapWidth) * 0.40);

        z-index: 99;
    }

    .empMarket {
        position: absolute;
        top: calc(var(--mapHeight) * 0.76);
        left: calc(var(--mapWidth) * 0.35);

        z-index: 99;
    }

    .empJoy {
        position: absolute;
        top: calc(var(--mapHeight) * 0.61);
        left: calc(var(--mapWidth) * 0.25);

        z-index: 99;
    }

    .empPort {
        position: absolute;
        top: calc(var(--mapHeight) * 0.60);
        left: calc(var(--mapWidth) * 0.63);

        z-index: 99;
    }

    .empSamurai {
        position: absolute;
        top: calc(var(--mapHeight) * 0.41);
        right: calc(var(--mapWidth) * 0.78);

        z-index: 99;
    }

    .empNinja {
        position: absolute;
        top: calc(var(--mapHeight) * 0.20);
        left: calc(var(--mapWidth) * 0.76);

        z-index: 99;
    }

    .empInfosIcon {
        width: 32px;
        padding: 3px;

        color: $jcorp-main-white;
        background: rgba($jcorp-main-black, 0.8);
        border-radius: 4px !important;
        border: none;

        cursor: pointer;
    }

    .battleInfosContainer {
        position: relative;
        display: block;
        width: 110%;
        height: 150px;
        margin-top: 80px;

        z-index: 99;
    }

    .battleInfosContainer .battleInfosContainerSub {
        position: relative;
        display: block;
        width: 110%;
        height: 150px;

        background: $jcorp-main-red;
        border-radius: 5px;

        clip-path: polygon(calc(100% - 2em) 0, calc(100%) 2em, 100% 2em, 100% 100%, calc(100% - 18em) 100%, calc(100% - 20em) calc(100% - 2em), 5em calc(100% - 2em), 3em 100%, 0 100%, 0 0);
    }

    .battleInfosContainer .battleInfosContainerSubBefore {
        content: "";
        position: absolute;
        width: 200%;
        height: 150px;

        top: 0;
        right: 99%;

        background: $jcorp-main-red;
        transform: rotate(-180deg);
    }

    .battleInfosContainer .battleInfosContainerSubAfter1 {
        content: "";
        position: absolute;
        width: 200%;
        height: calc(150px - 2em);

        top: 2em;
        left: 99%;

        background: $jcorp-main-red;
    }

    .arenaRegistrationInfosContainer {
        position: absolute;
        width: 40%;
        top: -160px;
        right: -160px;
    }

    .arenaRegistrationInfosPictureBox {
        position: absolute;
        top: -20px;
        left: -35px;

        z-index: 99;
    }

    .arenaRegistrationInfosPictureBox .arenaRegistrationInfosPicture {
        width: 80px;
    }

    .arenaRegistrationInfosBox {
        position: relative;
        display: block;

        background: $jcorp-main-red;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 2em) 0, 100% 2em, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaRegistrationInfosBoxIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: linear-gradient(284.72deg, #9F0836 13.28%, #620C31 96.53%);
        border-radius: 3px;

        clip-path: polygon(0 0, calc(100% - 2em) 0, 100% 2em, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaRegistrationTextBox {
        text-align: center;
        padding-top: 30px;
        padding-bottom: 25px;
    }

    .arenaRegistrationTextBox .arenaRegistrationText {
        font-family: Rajdhani;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
    }

    .arenaRegistrationTextBox .arenaRegistrationTextBig {
        font-family: Rajdhani;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
    }

    .arenaRegistrationInfosBoxIn2 {
        position: relative;
        display: inline-block;

        width: 100%;

        border-top: 1px solid $jcorp-main-red;

        background: rgba($jcorp-main-red, 0.4);
    }

    .arenaRegistrationTextBox2 {
        text-align: center;
        padding: 10px;
    }

    .arenaRegistrationTextBox2 .arenaRegistrationText {
        font-family: Rajdhani;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
    }

    .arenaResultContainer2 {
        position: relative;
        display: block;
        margin-bottom: 50px;
        width: 80%;
        left: 10%;
    }

    .arenaResultBox {
        position: relative;
        display: block;

        background: $jcorp-main-red;
        border-radius: 5px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultBoxIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: #18101B;
        border-radius: 5px;

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultBoxIn:hover {
        background: rgba(#18101B, 0.9);
        cursor: pointer;
    }

    .arenaResultBoxIn2 {
        position: relative;
        display: inline-block;

        width: 100%;

        background: linear-gradient(0deg, rgba(#18101B, 0) 0%, rgba($jcorp-main-red, 0.2448) 100%);

        clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultBoxInText2 {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        /* Main/Red */
        color: $jcorp-main-white;
    }

    .arenaResultBoxInfos {
        position: relative;
        display: block;
        width: 100%;
        padding: 10px;
    }

    .arenaResultBoxInfosLeft {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 40%;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .arenaResultBoxInfosCenter {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 20%;

        display: inline-block;
        font-family: ThirdRailDemo;
        font-style: normal;
        font-weight: 400;
        font-size: 60px;
        line-height: 89%;
        /* or 71px */

        /* Main/Red */
        color: $jcorp-main-red;
    }

    .arenaResultBoxInfosRight {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 40%;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .arenaResultSeasonBox {
        position: absolute;
        display: block;
        top: -22px;
        left: calc(50% - 75px);
    }

    .arenaResultSeason {
        position: relative;
        display: block;

        width: 150px;
        height: 50px;

        background: $jcorp-main-white;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultSeasonIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(-284.72deg, #e9e7d5 13.28%, #afaea6 96.53%);

        clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultSeasonText {
        margin: 3px;
        text-align: center;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-black;
    }

    .arenaResultLeft {
        position: absolute;
        display: block;
        bottom: -10px;
        left: calc(20% - 75px);
    }

    .arenaResultText {
        margin: 6px;
        text-align: center;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;
    }

    .arenaResultLeftPending {
        position: relative;
        display: block;

        width: 150px;
        height: 50px;

        color: #484a4d;
        background: #484a4d;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultLeftPendingIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(-284.72deg, #c2cad3 13.28%, #8b9096 96.53%);

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultLeftWinner {
        position: relative;
        display: block;

        width: 150px;
        height: 50px;

        color: $jcorp-main-green;
        background: $jcorp-main-green;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultLeftWinnerIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(-284.72deg, #0d9e69 13.28%, #0c7750 96.53%);

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultLeftLoser {
        position: relative;
        display: block;

        width: 150px;
        height: 50px;

        color: $jcorp-main-red;
        background: $jcorp-main-red;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultLeftLoserIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(-284.72deg, #9F0836 13.28%, #620C31 96.53%);

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
    }

    .arenaResultRight {
        position: absolute;
        display: block;
        bottom: -10px;
        right: calc(20% - 75px);
    }

    .arenaResultRightPending {
        position: relative;
        display: block;

        width: 150px;
        height: 50px;

        color: #484a4d;
        background: #484a4d;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultRightPendingIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(284.72deg, #c2cad3 13.28%, #8b9096 96.53%);

        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultRightWinner {
        position: relative;
        display: block;

        width: 150px;
        height: 50px;

        color: $jcorp-main-green;
        background: $jcorp-main-green;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultRightWinnerIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(284.72deg, #0d9e69 13.28%, #0c7750 96.53%);

        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultRightLoser {
        position: relative;
        display: block;

        width: 150px;
        height: 50px;

        color: $jcorp-main-red;
        background: $jcorp-main-red;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .arenaResultRightLoserIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(284.72deg, #9F0836 13.28%, #620C31 96.53%);

        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .roundCardLifeText {
        position: relative;
        display: inline-block;
        top: 1px;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 100%;
        /* or 20px */

        letter-spacing: 0.07em;
        text-transform: uppercase;

        /* Main/Red */
        color: $jcorp-main-white;
    }

    .roundCardLifeTextSep {
        position: relative;
        display: inline-block;
        top: -2px;
        font-size: 18px;
    }

    .roundCardLeftLifeIcon {
        position: absolute;
        display: block;
        top: -12px;
        left: -10px;
        color: $jcorp-main-red;
        font-size: 28px;
    }

    .roundCardRightLifeIcon {
        position: absolute;
        top: -12px;
        right: -10px;
        color: $jcorp-main-red;
        font-size: 28px;
    }

    .stripContainerLeft {
        position: relative;
        display: block;
    }

    .stripBoxLeft {
        position: relative;
        width: 300px;
        height: 55px;

        /* Main/Red */
        background: $jcorp-main-red;

        z-index: 1;
    }

    .stripBoxLeft::before {
        content: "";
        position: absolute;
        width: 100vw;
        height: 55px;
        top: 0;
        right: 0;

        /* Main/Red */
        background: $jcorp-main-red;

        z-index: 1;
    }

    .stripBoxLeft::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 55px;
        top: 0;
        right: -30px;

        /* Main/Red */
        background: $jcorp-main-red;
        border-top-right-radius: 2px;
        border: none;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 1.5em), calc(100% - 1.5em) 101%, 0% 101%, 0% 100%, 0 0%);

        z-index: 1;
    }

    .stripBoxLeft .stripBoxLeftRank {
        position: absolute;
        display: block;
        top: -28px;
        right: 0;
    }

    .stripBoxLeft .stripInfos {
        position: relative;
        display: block;

        width: 150px;
        height: 50px;

        background: $jcorp-main-white;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .stripBoxLeft .stripInfosIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(-284.72deg, #e9e7d5 13.28%, #afaea6 96.53%);

        clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .stripBoxLeft .stripInfosText {
        margin: 3px;
        text-align: center;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-black;
    }

    .stripBoxLeft .stripBoxLeftList {
        position: absolute;
        display: block;

        top: 8px;
        right: 30px;

        z-index: 2;
    }

    .stripBoxLeft .stripBoxLeftSearchBox {
        position: absolute;
        display: block;

        top: 3px;
        right: 0;

        z-index: 2;
    }

    .stripBoxLeft .stripBoxLeftBtnSearchBox {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        top: -20px;
    }

    .stripBoxLeft .stripBoxLeftBtnSearch {
        position: relative;
        display: block;

        width: 48px;
        height: 48px;

        background: $jcorp-main-red;
        border-radius: 2px;
        border: none;

        transition-duration: 0.4s;

        clip-path: polygon(0 0, calc(100% - 0.5em) 0, 100% 0.5em, 100% 100%, 0 100%, 0.5em 100%, 0% calc(100% - 0.5em), 0 0%);
    }

    .stripBoxLeft .stripBoxLeftBtnSearch:hover {
        background: $jcorp-main-black;
        cursor: pointer;
    }

    .stripBoxLeft .stripBoxLeftBtnSearchIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: $jcorp-main-black;
        border-radius: 2px;
        border: none;

        transition-duration: 0.4s;

        clip-path: polygon(0 0, calc(100% - 0.45em) 0, 100% 0.45em, 100% 100%, 0 100%, 0.45em 100%, 0% calc(100% - 0.45em), 0 0%);
    }

    .stripBoxLeft .stripBoxLeftBtnSearchIn:hover {
        background: $jcorp-main-red;
    }

    .stripBoxLeft .stripBoxLeftBtnSearchIn:active {
        background: rgba($jcorp-main-red, 0.6);
        transition-duration: 0s;
    }

    .stripBoxLeft .stripBoxLeftIconSearchBox {
        position: absolute;
        top: 9px;
        left: 9px;
    }

    .stripBoxLeft .stripBoxLeftIconSearch {
        font-size: 28px;
    }

    .stripBoxLeft .stripBoxLeftSearch {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 190px;
    }

    .stripBoxLeft .stripBoxLeftTimerBox {
        position: absolute;
        display: block;

        top: 15px;
        right: 0;

        z-index: 2;
    }

    .stripBoxLeft .stripBoxLeftTimer {
        position: relative;
        display: block;
        width: 210px;
    }

    .stripBoxLeft .infosTimerText {
        position: relative;
        display: inline-block;
        margin-right: 20px;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-white;
    }

    .stripBoxLeft .infosTimerTimer {
        position: relative;
        display: inline-block;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-black;
    }

    .stripBoxLeft .stripBoxLeftBtn {
        position: absolute;
        display: block;

        top: 10px;
        right: 40px;

        z-index: 2;
    }

    .stripBoxLeft .stripBtn {
        position: relative;
        display: block;
        width: 160px;
        height: 58px;

        background: $jcorp-main-red;
        border-radius: 2px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .stripBoxLeft .stripBtn:hover {
        background: $jcorp-main-black;
    }

    .stripBoxLeft .stripBtnIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: $jcorp-main-black;
        border-radius: 2px;

        transition-duration: 0.4s;

        clip-path: polygon(0 0, calc(100% - 1.45em) 0, 100% 1.45em, 100% 100%, 0 100%, 1.45em 100%, 0% calc(100% - 1.45em), 0 0%);
    }

    .stripBoxLeft .stripBtnIn:hover {
        background: $jcorp-main-red;
        cursor: pointer;
    }

    .stripBoxLeft .stripBtnIn:active {
        background: rgba($jcorp-main-red, 0.6);
        transition-duration: 0s;
    }

    .stripBoxLeft .stripBtnText {
        text-align: center;

        /* Button/Default */
        font-family: Teko;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;

        padding-top: 17px;
    }

    .stripContainerRight {
        position: relative;
        display: block;
    }

    .stripContainerRightAdjusted {
        top: -72px;
    }

    .bigBoxMargin80 {
        margin-top: 80px;
    }

    .stripBoxRight {
        position: absolute;
        width: 100px;
        height: 55px;

        top: -90px;
        right: 0;

        /* Main/Red */
        background: $jcorp-main-red;

        z-index: 1;
    }

    .stripBoxRight::before {
        content: "";
        position: absolute;
        width: 100vw;
        height: 55px;
        top: 0;
        left: 0;

        /* Main/Red */
        background: $jcorp-main-red;

        z-index: 1;
    }

    .stripBoxRight::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 55px;
        top: 0;
        left: -30px;

        /* Main/Red */
        background: $jcorp-main-red;
        border-top-right-radius: 2px;

        clip-path: polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 1.5em), calc(100% - 1.5em) 100%, 0% 100%, 0% 100%, 0 0%);

        z-index: 1;
        transform: rotateY(180deg);
    }

    .stripBoxRight .stripBoxRightRank {
        position: absolute;
        display: block;
        top: -28px;
        left: 0;
    }

    .stripBoxRight .stripInfos {
        position: relative;
        display: block;

        width: 150px;
        height: 50px;

        background: $jcorp-main-white;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .stripBoxRight .stripInfosIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 3px;

        background: linear-gradient(-284.72deg, #e9e7d5 13.28%, #afaea6 96.53%);

        clip-path: polygon(0 1em, 1em 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0% 100%, 0 0%);
    }

    .stripBoxRight .stripInfosText {
        margin: 3px;
        text-align: center;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-black;
    }

    .stripBoxRight .stripBoxRightBtn {
        position: absolute;
        display: block;

        top: 10px;
        left: 40px;

        z-index: 2;
    }

    .stripBoxRight .stripBtn {
        position: relative;
        display: block;
        width: 160px;
        height: 58px;

        background: $jcorp-main-red;
        border-radius: 2px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);

        transform: rotateY(180deg);
    }

    .stripBoxRight .stripBtn:hover {
        background: $jcorp-main-black;
    }

    .stripBoxRight .stripBtnIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: $jcorp-main-black;
        border-radius: 2px;

        transition-duration: 0.4s;

        clip-path: polygon(0 0, calc(100% - 1.45em) 0, 100% 1.45em, 100% 100%, 0 100%, 1.45em 100%, 0% calc(100% - 1.45em), 0 0%);
    }

    .stripBoxRight .stripBtnIn:hover {
        background: $jcorp-main-red;
        cursor: pointer;
    }

    .stripBoxRight .stripBtnIn:active {
        background: rgba($jcorp-main-red, 0.6);
        transition-duration: 0s;
    }

    .stripBoxRight .stripBtnText {
        text-align: center;

        /* Button/Default */
        font-family: Teko;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        text-transform: uppercase;

        /* Main/Light */
        color: $jcorp-main-white;

        padding-top: 17px;

        transform: rotateY(180deg);
    }

    #footerNavBar {
        position: relative;
        display: block;
        width: 100%;

        height: 260px;
        //min-height: 260px;
        //max-height: calc(100% - 260px);

        /* Main/Red */
        background: $jcorp-main-red;

        clip-path: polygon(7em 2em, calc(100% - 34em) 2em, calc(100% - 32em) 0, 100% 0, 100% 100%, 0 100%, 0 0, 5em 0);
    }

    #footerNavBar .footerContent {
        position: relative;
        display: block;

        margin-top: 100px;
    }

    #footerNavBar .logoNav {
        position: absolute;
        width: 220px;
        left: 7%;
        top: 0%;
    }

    #footerNavBar .logoNav:hover {
        cursor: pointer;
    }

    #footerNavBar .footerNav {
        position: absolute;
        right: 10%;
        top: 0%;
        margin-top: 10px;
    }

    #footerNavBar .footerNavElement {
        position: relative;
        display: inline-block;

        margin-left: 25px;
    }

    #footerNavBar .socialIconsSmallContainer {
        display: none;
    }

    #footerNavBar .socialIconsContainer {
        margin-top: 5px;
    }

    #footerNavBar .socialIconsContainer .socialIcons {
        margin-right: 30px;
    }

    .buttonsContainer {
        margin-top: 30px;
    }

    .socialIcons {
        margin-left: 10px;
    }

    .socialIconsIn {
        width: 20px;
        height: 20px;
    }

    .btnDisconnectContainer {
        position: absolute;
        top: 0;
        right: 0;
    }

    .tabsWrapper {
        clip-path: polygon(1em 0%, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 1em);
        text-transform: uppercase;

        height: 48px;
        border-radius: 3px;
        background: #faf7e2;
        display: inline-block;

        align-items: center;
        justify-content: center;

        .tabsWrapperIn {
            background: #24102c;
            border-radius: 3px;
            clip-path: polygon(1em 0%, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 1em);
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            margin: 1px;

            .tabsWrapperContainer {
                display: flex;
                align-items: center;
                gap: 40px;
                padding: 0 30px;
                list-style: none;
                // justify-content: space-around;
                margin: 0 !important;
                height: 100%;

                .tabsWrapperContainerItems {
                    // height: 100%;
                    font-family: 'Teko';
                    font-size: 24px;
                    color: $jcorp-main-white;
                    position: relative;
                    cursor: pointer;
                }

                .tabsWrapperContainerItemsActive::after {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100%;
                    height: 5px;
                    background-color: $jcorp-main-red;
                }
            }
        }
    }

    .tabsWrapperInMobile {
        display: none;
    }

    .tabsWrapperInMobileAccount {
        display: none;
    }

    .arenaTimerInfosContainer {
        position: relative;
        display: block;
        width: 40%;
        z-index: 2;
    }

    .arenaTimerInfosBox {
        position: relative;
        display: block;
        height: 90px;

        background: $jcorp-main-red;
        border-radius: 3px;
        border: none;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .arenaTimerInfosBoxIn {
        position: relative;
        display: inline-block;
        margin: 1px;

        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: linear-gradient(284.72deg, #620C31 13.28%, #9F0836 96.53%);
        border-radius: 3px;

        clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
    }

    .arenaTimerHeadingBox {
        position: absolute;
        display: inline-block;
        top: 17px;
        left: 30px;
    }

    .arenaTimerHeadingBox .arenaTimerHeading {
        display: inline-block;

        font-family: Archivo;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-white;
    }

    .arenaTimerRankBox {
        position: absolute;
        display: inline-block;
        top: 49px;
        left: 30px;
    }

    .arenaTimerRankBox .arenaTimerRank {
        display: inline-block;

        font-family: Archivo;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-black;
    }

    .arenaTimerTextBox {
        position: absolute;
        display: inline-block;
        top: 34px;
        left: calc(100% - 185px);
    }

    .arenaTimerTextBox .arenaTimerText {
        display: inline-block;
        margin-right: 15px;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-white;
    }

    .arenaTimerTextBox .arenaTimerTimer {
        display: inline-block;

        font-family: Teko;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 100%;
        /* or 20px */

        text-transform: uppercase;

        color: $jcorp-main-red;
    }

    .arenaFightContainerLeft {
        position: relative;
        display: block;
        margin-top: 30px;
        width: 70%;
    }

    .arenaFightContainerRight {
        position: relative;
        display: block;
        margin-top: 30px;
        width: 70%;
        left: 30%;
    }
}