    @import "common_values.scss";

    .maintenanceText {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 180%;
        /* or 29px */

        /* Main/Light */
        color: $jcorp-main-white;
    }

    .spinny-cogs {
        display: block;

        .fa {
            &:nth-of-type(1) {
                @extend .fa-spin-one;
            }

            &:nth-of-type(3) {
                @extend .fa-spin-two;
            }
        }
    }

    @-webkit-keyframes fa-spin-one {
        0% {
            -webkit-transform: translateY(-2rem) rotate(0deg);
            transform: translateY(-2rem) rotate(0deg);
        }

        100% {
            -webkit-transform: translateY(-2rem) rotate(-359deg);
            transform: translateY(-2rem) rotate(-359deg);
        }
    }

    @keyframes fa-spin-one {
        0% {
            -webkit-transform: translateY(-2rem) rotate(0deg);
            transform: translateY(-2rem) rotate(0deg);
        }

        100% {
            -webkit-transform: translateY(-2rem) rotate(-359deg);
            transform: translateY(-2rem) rotate(-359deg);
        }
    }

    .fa-spin-one {
        margin-right: 10px;
        -webkit-animation: fa-spin-one 1s infinite linear;
        animation: fa-spin-one 1s infinite linear;
    }

    @-webkit-keyframes fa-spin-two {
        0% {
            -webkit-transform: translateY(-.5rem) translateY(1rem) rotate(0deg);
            transform: translateY(-.5rem) translateY(1rem) rotate(0deg);
        }

        100% {
            -webkit-transform: translateY(-.5rem) translateY(1rem) rotate(-359deg);
            transform: translateY(-.5rem) translateY(1rem) rotate(-359deg);
        }
    }

    @keyframes fa-spin-two {
        0% {
            -webkit-transform: translateY(-.5rem) translateY(1rem) rotate(0deg);
            transform: translateY(-.5rem) translateY(1rem) rotate(0deg);
        }

        100% {
            -webkit-transform: translateY(-.5rem) translateY(1rem) rotate(-359deg);
            transform: translateY(-.5rem) translateY(1rem) rotate(-359deg);
        }
    }

    .fa-spin-two {
        margin-left: 10px;
        -webkit-animation: fa-spin-two 2s infinite linear;
        animation: fa-spin-two 2s infinite linear;
    }