@font-face {
    font-family: 'CustomFont';
    src: url('../font/Roboto-Bold.ttf') format('truetype');
}

.modalContent {
    position: relative;
    display: inline-block;
    overflow-x: hidden;

    color: #FAF7E2;
}

.boxTitle {
    color: #FAF7E2;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 3px;
}

.boxLoadingText {
    margin-top: 50px;
}

.boxConnectTitle {
    color: #FAF7E2;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 3px;
}

.boxConnect {
    color: #FAF7E2;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin-top: 20px;
    padding: 3px;
    padding-top: 20px;
}

.boxContent {
    position: relative;
    display: block;
    color: #FAF7E2;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin-top: 20px;
    padding: 3px;
}

.boxContent:first-child {
    margin-top: 50px;
}

.boxSortContent {
    position: relative;
    display: block;
    color: #FAF7E2;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.containerItem {
    position: relative;
    display: inline-block;
    color: #FAF7E2;
}

.contentItemActions {
    position: relative;
    display: block;
    text-align: center;
    padding: 10px;
}

.contentItemBox {
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 12px;
}

.contentItemBoxButtons {
    margin-top: 5px;
}

.containerShop {
    position: relative;
    display: inline-block;
    color: #FAF7E2;
    border-radius: 5px;
    padding: 3px;
    margin: 5px;
    border: 1px solid #ced4da;
    box-shadow: 0px 1px 5px #000;
}

.contentShopActions {
    position: relative;
    display: block;
    text-align: center;
    padding: 10px;
}

.contentShopBox {
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 12px;
}

.contentShopBoxButtons {
    margin-top: 5px;
}

.titleContent {
    text-align: center;
    border-bottom: 2px solid #FAF7E2;
}

.contentContainer {
    position: relative;
    display: block;
    margin-top: 40px;
}

.iconContainer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.buttonContainer {
    position: relative;
    display: block;
    text-align: center;
}

.homeLinkContent {
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 10px;
    margin: 5px;
    margin-right: 15px;
    margin-left: 15px;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #000;
}

.linkContent {
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 10px;
    margin: 5px;
    margin-top: 15px;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #000;
}

.buttonContent {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 80px;
    padding: 10px;
    margin: 5px;
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #000;
}

.buttonContentTab {
    position: relative;
    display: inline-block;
    text-align: center;
    min-width: 70px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #000;
}

.buttonContentMint {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 120px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #000;
}

.buttonContentCard {
    text-align: center;
    position: absolute;
    top: 35%;
    left: 20%;
    width: 80px;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #000;
}

.buttonBlueOpacity {
    opacity: 0.7;
    background-color: rgba(12, 49, 99);
}

.buttonBlueOpacity:hover {
    cursor: default;
}

.buttonRedOpacity {
    opacity: 0.7;
    background-color: rgb(99, 12, 12);
}

.buttonRedOpacity:hover {
    cursor: default;
}

.buttonBlue {
    background-color: rgb(12, 49, 99);
}

.buttonBlue:hover {
    cursor: pointer;
    background-color: rgb(16, 65, 129);
}

.buttonRed {
    background-color: rgb(99, 12, 12);
}

.buttonRed:hover {
    cursor: pointer;
    background-color: rgb(141, 17, 17);
}

.buttonGreen {
    background-color: rgb(7, 90, 7);
}

.buttonGreen:hover {
    cursor: pointer;
    background-color: rgb(10, 112, 10);
}

.buttonGrey {
    background-color: rgb(68, 68, 68);
}

.buttonGrey:hover {
    cursor: pointer;
    background-color: rgb(100, 100, 100);
}

.navTokenContainer {
    position: relative;
    display: block;
    text-align: center;
    margin-top: 107px;
    background-color: #fff;
    box-shadow: 0px 1px 5px #fff;
}

.navToken {
    position: relative;
    display: inline-block;
    margin: 15px;
}

.navTokenText {
    text-align: center;
    padding: 5px;
    padding-left: 35px;
    color: #000;
    background: #fff;
    border: 3px solid rgb(12, 49, 99);
    border-radius: 5px;
    box-shadow: 0px 1px 5px #000;
}

.navTokenLogoContainer {
    position: absolute;
    top: -6px;
    left: -20px;
    background: #fff;
    border: 3px solid rgb(12, 49, 99);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 1px 5px #000;
}

.navTokenLogo {
    padding-top: 7px;
    width: 30px;
}

.homeCommunityContainer {
    position: relative;
    display: block;
    text-align: center;
    margin-top: 120px;
}

.homeElrondText {
    position: relative;
    display: block;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin-bottom: 40px;
}

.homeElrondLogo {
    width: 100px;
    margin-left: 20px;
    padding-bottom: 6px;
    font-weight: bold;
}

.homeEventsContainer {
    text-align: justify;
}

.homeCardsContainer {
    position: relative;
    display: block;
    text-align: center;
    margin-top: 80px;
    padding-bottom: 60px;
}

.mintCardsContainer {
    position: relative;
    display: block;
    text-align: center;
    margin-top: 80px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .large {
        display: none;
    }
}

@media only screen and (min-width: 767px) {
    .small {
        display: none;
    }
}

.colonne {
    position: relative;
    display: inline-block;
    margin: auto;
    padding: 0;
}

.colonne::after {
    clear: both;
    display: block;
}

.colonne div {
    position: relative;
    float: left;
    width: 130px;
    margin: 0;
    margin: 0 0 0 -30px;
}

.colonne div:first-child {
    margin-left: 0;
}

.slide div {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.slide div .homeCards-2 {
    margin-top: -23px;
}

.slide div .homeCards-item {
    margin-top: -30px;
}

.slide div .homeCards-3 {
    margin-top: -23px;
}

.slide div:hover .homeCards-1 {
    margin-top: -30px;
    margin-left: -10px;
}

.slide div:hover .homeCards-2 {
    margin-top: -53px;
    margin-left: -5px;
}

.slide div:hover .homeCards-item {
    margin-top: -60px;
}

.slide div:hover .homeCards-3 {
    margin-top: -53px;
    margin-left: 5px;
}

.slide div:hover .homeCards-4 {
    margin-top: -30px;
    margin-left: 10px;
}

.homeCards-1 {
    z-index: 5;
    position: relative;
    display: inline-block;
    width: 130px;
    border-radius: 5px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 5px;
    margin: 5px;
    background-color: #f8f9fa;
    box-shadow: 0px 1px 5px #000;
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
}

.homeCards-2 {
    z-index: 4;
    position: relative;
    display: inline-block;
    width: 130px;
    border-radius: 5px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 5px;
    margin: 5px;
    background-color: #f8f9fa;
    box-shadow: 0px 1px 5px #000;
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
}

.homeCards-3 {
    z-index: 2;
    position: relative;
    display: inline-block;
    width: 130px;
    border-radius: 5px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 5px;
    margin: 5px;
    background-color: #f8f9fa;
    box-shadow: 0px 1px 5px #000;
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
}

.homeCards-4 {
    z-index: 1;
    position: relative;
    display: inline-block;
    width: 130px;
    border-radius: 5px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 5px;
    margin: 5px;
    background-color: #f8f9fa;
    box-shadow: 0px 1px 5px #000;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
}

.homeCards-item {
    z-index: 3;
    position: relative;
    display: inline-block;
    width: 130px;
    border-radius: 5px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 5px;
    margin: 5px;
    background-color: #f8f9fa;
    box-shadow: 0px 1px 5px #000;
}

.fantasy {
    font-family: CustomFont;
}

.fantasyUpper {
    text-transform: uppercase;
    font-family: CustomFont;
}

.fantasyTitle {
    text-transform: uppercase;
    font-family: CustomFont;
    color: #000;
    font-size: 20px;
}

.text {
    font-size: 18px;
    font-family: CustomFont;
    color: #A6A6A6;
    text-align: justify;
}

.text-bis {
    font-size: 18px;
    font-family: CustomFont;
    color: #A6A6A6;
}

.infos {
    color: #2D77E0;
}

.titleB {
    color: #fff;
    background-color: #ced4da;
}

.titleDef {
    color: #fff;
    background-color: rgb(12, 49, 99);
    border: 1px solid rgb(16, 65, 129) !important;
}

.boxDef {
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #000;
    background-color: rgb(12, 49, 99);
    border: 1px solid rgb(16, 65, 129) !important;
}

.titleAttack {
    color: #fff;
    background-color: rgb(99, 12, 12);
    border: 1px solid rgb(141, 17, 17) !important;
}

.boxAttack {
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #000;
    background-color: rgb(99, 12, 12);
    border: 1px solid rgb(141, 17, 17) !important;
}

.modalSize {
    width: 70%;
    max-width: none !important;
    padding: auto;
    margin: auto;
}

.mapFieldModal {
    margin: 5px;
}

.mapFieldModal img {
    border-radius: 5px;
    box-shadow: 0px 1px 5px #000;
}

.mapFieldModal .img-mapper-map {
    cursor: auto;
}

.iconsModalRight {
    float: right;
    padding-right: 10px;
    cursor: pointer;
}

.mapField {
    position: relative;
    display: block;
}

.mapField img {
    border-radius: 5px;
    box-shadow: 0px 1px 5px #000;
}

.mapField .img-mapper-map {
    cursor: pointer;
}

.img-mapper-canvas {
    opacity: 0.4;
}

.mapFieldTooltip {
    position: absolute;
    color: #fff;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
    transform: translate3d(-50%, -50%, 0);
    border-radius: 5px;
    pointer-events: none;
    z-index: 1000;
}

.subTitle {
    position: relative;
    display: inline-block;
    margin-top: 5px;
    text-shadow: -2px -2px 2px rgb(12, 49, 99),
        2px 2px 2px rgb(12, 49, 99),
        -2px 2px 2px rgb(12, 49, 99),
        2px -2px 2px rgb(12, 49, 99),
        -2px -2px 3px #fff,
        2px 2px 3px #fff,
        -2px 2px 3px #fff,
        2px -2px 3px #fff;
}

.textFactionInfos {
    position: relative;
    display: block;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.factionInfos {
    position: relative;
    display: inline-block;
    min-width: 120px;
    padding-top: 5px;
}

.tokenReward {
    position: relative;
    display: inline-block;
    padding-top: 5px;
}

.blockReward {
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid #ced4da;
}

.textReward {
    position: relative;
    display: block;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ced4da;
}

.tokenRewardText {
    text-align: left;
    padding: 5px;
    padding-left: 45px;
}

.tokenRewardLogoContainer {
    text-align: center;
    position: absolute;
    top: 6px;
    left: -20px;
    background: #fff;
    border: 3px solid rgb(12, 49, 99);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 1px 5px #000;
}

.balanceInfos {
    margin-top: 5px;
}

.containerFactionCenter:hover {
    border: none !important;
    cursor: auto !important;
}

.mintContainer {
    position: relative;
    display: block;
    text-align: center;
}

.mintQuantity {
    font-size: 24px;
    padding-left: 10px;
    padding-right: 10px;
}

.nftCardsContainer {
    position: relative;
    display: block;
}

@media only screen and (max-width: 767px) {
    .nftCardsContainer {
        text-align: center;
    }
}

.arenaCardsContainer {
    position: relative;
    display: block;
    text-align: center;
}

.arenaResultContainer {
    position: relative;
    display: block;
}

.arenaResultCardsContainerLeft {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.arenaResultVSImgContainer {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100px;
    margin-left: 30px;
    margin-right: 30px;
}

.versusImage {
    width: 100px;
}

.arenaResultCardsContainerRight {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.margin10 {
    margin-top: 10px;
}

.margin20 {
    margin-top: 20px;
}

.margin30 {
    margin-top: 30px;
}

.marginBottom20 {
    margin-bottom: 20px;
}

.marginBoth10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.factionImgContainerLeft {
    margin-right: 15px;
}

.factionImgContainerRight {
    margin-left: 15px;
}

.badgeContainer {
    margin-left: 15px;
}

.arenaResultInfos {
    position: relative;
    display: block;
    margin: 10px;
}

.arenaResultItemsBlock {
    position: relative;
    display: block;
    margin-bottom: 10px;
}

.arenaResultItems {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}

.arenaResultFaction {
    margin-bottom: 10px;
}

.arenaResultPseudo {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.arenaResultWinner {
    font-size: 24px;
    color: rgb(19, 203, 19) !important;
}

.arenaResultLoser {
    font-size: 24px;
    color: rgb(214, 21, 21) !important;
}

.textRowLeft {
    text-align: left;
}

.bonusLabel {
    position: relative;
    font-size: 20px;
    top: 1.5px;
    padding-left: 1px;
}

.iconBonusSize {
    font-size: 16px;
}

.noBonus {
    display: inline-block;
    color: #6c757d !important;
}

.bonusUp {
    display: inline-block;
    color: #00FFA3 !important;
}

.bonusDown {
    display: inline-block;
    color: #FF003D !important;
}

.containerFaction {
    width: 150px;
    margin: 5px auto;
    clip-path: polygon(0 0, calc(100% - 1.5em) 0, 100% 1.5em, 100% 100%, 0 100%, 1.5em 100%, 0% calc(100% - 1.5em), 0 0%);
}

.containerInventoryFaction {
    width: 75px;
    margin: 5px auto;
    clip-path: polygon(0 0, calc(100% - 1em) 0, 100% 1em, 100% 100%, 0 100%, 1em 100%, 0% calc(100% - 1em), 0 0%);
}

.imgFaction {
    width: 100%;
    border-radius: 3px;
}

.imgFactionArena {
    width: 40px;
    border-radius: 3px;
    clip-path: polygon(0 0, calc(100% - 0.5em) 0, 100% 0.5em, 100% 100%, 0 100%, 0.5em 100%, 0% calc(100% - 0.5em), 0 0%);
}

.imgFactionProfile {
    margin-left: 10px;
    width: 40px;
    border-radius: 3px;
    clip-path: polygon(0 0, calc(100% - 0.5em) 0, 100% 0.5em, 100% 100%, 0 100%, 0.5em 100%, 0% calc(100% - 0.5em), 0 0%);
}

.leadMenu {
    display: flex;
    justify-content: center;
}

.ProfileSlider {
    margin-left: 20px;
    width: 30%;
}

@media only screen and (max-width: 767px) {
    .ProfileSlider {
        text-align: center;
        width: 70%;
    }
}

.contentTable {
    margin-top: -20px;
}

.iconCol {
    width: 5%;
}

.col15w {
    width: 15%;
}

.col20w {
    width: 20%;
}

.col30w {
    width: 30%;
}

.col40w {
    width: 40%;
}

.col50w {
    width: 50%;
}

.containerIconUsage {
    position: relative;
    display: block;
}

.iconAttack {
    position: relative;
    display: inline-block;
    margin: 5px;
    color: rgb(99, 12, 12);
    font-size: 20px;
}

.iconDef {
    position: relative;
    display: inline-block;
    margin: 5px;
    color: rgb(12, 49, 99);
    font-size: 20px;
}

.iconTitleLeft {
    color: #fff;
    margin-right: 10px;
}

.iconTitleRight {
    color: #fff;
    margin-left: 10px;
}

.icons {
    margin-right: 10px;
}

.iconsBig {
    margin-right: 10px;
    font-size: 1.2em;
    vertical-align: sub;
}

.iconInfo {
    font-size: 16px;
    color: #adb5bd;
    margin-left: 10px;
}

.iconInfoConnect {
    font-size: 30px;
    color: #adb5bd;
    margin-left: 20px;
    vertical-align: baseline;
}

.iconsConnect {
    margin-right: 10px;
}

.iconsConnectRight {
    color: #fff;
}

.iconCheck {
    color: rgb(10, 112, 10);
    margin-left: 5px;
}


.iconsRight {
    position: absolute;
    top: 8px;
    right: 10px;
}

.link a {
    color: red;
    text-decoration: none;
}

.link a:hover {
    color: #000;
}

#footernav {
    color: red;
}

#footernav a {
    color: #fff;
}

#footernav a:hover {
    color: red;
}

#footernav .nav-item {
    margin-right: 40px;
    font-weight: bold;
}

#footernav .active {
    color: #fff;
    padding-bottom: 6px;
    border-bottom: solid red;
}

.main-timeline {
    position: relative
}

@media (min-width: 1200px) {
    .main-timeline:before {
        content: "";
        display: block;
        width: 2px;
        height: 100%;
        background: #c6c6c6;
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0
    }
}

.main-timeline .timeline {
    margin-bottom: 40px;
    position: relative
}

.main-timeline .timeline:after {
    content: "";
    display: block;
    clear: both
}

@media (min-width: 1200px) {
    .main-timeline .icon {
        width: 18px;
        height: 18px;
        line-height: 18px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0
    }

    .main-timeline .icon:before,
    .main-timeline .icon:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.33s ease-out 0s
    }

    .main-timeline .icon:before {
        background: #fff;
        border: 2px solid #232323;
        left: -3px
    }

    .main-timeline .icon:after {
        border: 2px solid #c6c6c6;
        left: 3px
    }

    .main-timeline .timeline:hover .icon:before {
        left: 3px
    }

    .main-timeline .timeline:hover .icon:after {
        left: -3px
    }
}

.main-timeline .date-content {
    width: 50%;
    float: left;
    margin-top: 32px;
    /* hauteur du trait */
    position: relative
}

.main-timeline .date-content:before {
    content: "";
    width: 36.5%;
    height: 2px;
    background: #c6c6c6;
    margin: auto 0;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0
}

.main-timeline .date-outer {
    width: 125px;
    height: 125px;
    font-size: 16px;
    text-align: center;
    margin: auto;
    z-index: 1
}

.main-timeline .date-outer:before,
.main-timeline .date-outer:after {
    content: "";
    width: 125px;
    height: 125px;
    margin: 0 auto;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.33s ease-out 0s
}

.main-timeline .date-outer:before {
    background: #fff;
    border: 2px solid #232323;
    left: -6px
}

.main-timeline .date-outer:after {
    border: 2px solid #c6c6c6;
    left: 6px
}

.main-timeline .timeline:hover .date-outer:before {
    left: 6px
}

.main-timeline .timeline:hover .date-outer:after {
    left: -6px
}

.main-timeline .date {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 27%;
    left: 0
}

.main-timeline .month {
    font-size: 18px;
    font-weight: 700
}

.main-timeline .year {
    display: block;
    font-size: 30px;
    font-weight: 700;
    color: #232323;
    line-height: 36px
}

.main-timeline .timeline-content {
    width: 50%;
    padding: 20px 0 0px 50px;
    float: right
}

.main-timeline .title {
    font-size: 22px;
    line-height: 24px;
    margin: 0 0 15px 0;
    text-transform: uppercase;
    font-family: CustomFont;
    color: red;
    text-align: justify;
}

.main-timeline .description {
    margin-bottom: 0;
    font-size: 18px;
    font-family: CustomFont;
    color: #A6A6A6;
    text-align: justify;
    list-style-type: circle;
}

.main-timeline .timeline:nth-child(2n) .date-content {
    float: right
}

.main-timeline .timeline:nth-child(2n) .date-content:before {
    left: 10px
}

.main-timeline .timeline:nth-child(2n) .timeline-content {
    padding: 20px 50px 20px 0;
    text-align: right
}

@media only screen and (max-width: 991px) {
    .main-timeline .date-content {
        margin-top: 35px
    }

    .main-timeline .date-content:before {
        width: 22.5%
    }

    .main-timeline .timeline-content {
        padding: 10px 0 10px 30px
    }

    .main-timeline .title {
        font-size: 17px
    }

    .main-timeline .timeline:nth-child(2n) .timeline-content {
        padding: 10px 30px 10px 0
    }
}

@media only screen and (max-width: 767px) {
    .main-timeline:before {
        margin: 0;
        left: 7px
    }

    .main-timeline .timeline {
        margin-bottom: 20px
    }

    .main-timeline .timeline:last-child {
        margin-bottom: 0
    }

    .main-timeline .icon {
        margin: auto 0
    }

    .main-timeline .date-content {
        width: 95%;
        float: right;
        margin-top: 0
    }

    .main-timeline .date-content:before {
        display: none
    }

    .main-timeline .date-outer {
        width: 110px;
        height: 110px
    }

    .main-timeline .date-outer:before,
    .main-timeline .date-outer:after {
        width: 110px;
        height: 110px
    }

    .main-timeline .date {
        top: 30%
    }

    .main-timeline .year {
        font-size: 24px
    }

    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(2n) .timeline-content {
        width: 95%;
        text-align: center;
        padding: 10px 0
    }

    .main-timeline .title {
        margin-top: 20px;
        margin-bottom: 20px
    }
}

@media only screen and (max-width: 767px) {
    .menuPhone {
        padding-left: 50px;
    }
}

#timer {
    font-family: sans-serif;
    font-weight: 100;
    text-align: center;
    font-size: 20px;
}

#timer>div {
    color: #fff;
    padding: 10px;
    border-radius: 3px;
    background: #3A3A3A;
    display: inline-block;
}

#timer div>span {
    padding: 15px;
    border-radius: 3px;
    background: #A6A6A6;
    display: inline-block;
}

#timerPresale {
    font-family: sans-serif;
    font-weight: 100;
    text-align: center;
    font-size: 20px;
}

#timerPresale>div {
    color: #fff;
    padding: 10px;
    border-radius: 3px;
    background: #3A3A3A;
    display: inline-block;
}

#timerPresale div>span {
    padding: 15px;
    border-radius: 3px;
    background: #A6A6A6;
    display: inline-block;
}

.smalltext {
    padding-top: 5px;
    font-size: 16px;
}

#saleBar {
    text-align: center;
    font-size: 20px;
}

#saleBarContainer {
    height: 50px;
}

#saleBarStatus {
    font-size: 20px;
    font-weight: bold;
}